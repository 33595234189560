import { RoleTypes } from "../../../types/types";

// export interface StaffRes {
//   id: string;
//   cases: CaseData[];
//   createdBy: string;
//   email: string;
//   isAdmin: boolean;
//   isStaff: boolean;
//   job_title: string;
//   location: string;
//   name: string;
//   role: Exclude<RoleTypes, undefined>;
// }

export enum CreateStaffSeatType {
  paid = "paid",
  default = "default",
}

export interface CreateStaffPayload {
  name: string;
  email: string;
  role: string;
  job_title: string;
  location: string;
  seatType: CreateStaffSeatType;
  isArchived: boolean;
}

export interface UpdateStaffPayload {
  staffId: string;
  data: CreateStaffPayload;
}

export interface StaffRes {
  createdAt: string;
  email: string;
  // id: string;
  job_title: string;
  location: string;
  name: string;
  role: RoleTypes;
  staffId: string;
  seatType: CreateStaffSeatType;
  isArchived: boolean;
}

export interface ToggleStaffPayload {
  seatType: CreateStaffSeatType;
  isArchived: boolean;
}

export interface ToggleStaffStatusPayload {
  staffId: string;
  data: ToggleStaffPayload;
}

export interface DeleteStaffPayload {
  staffId: string;
  data: {
    seatType: CreateStaffSeatType;
  };
}
