import React from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { FieldArray, Form, Formik } from "formik";
import InputField from "../../components/formik fields/InputField";
import {
  useGetN565ApplicantInfo,
  useSaveN565ApplicantInfo,
} from "../../../../hooks/n565/useN565";
import { CurrentInfoAboutYouValidation } from "./validation";
import FormBtn from "../../components/FormBtn";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import useToNextSection from "../../../../hooks/useToNextSection";
import { FormWrapper, RadioWrapper } from "../../components/styles";
import SelectField from "../../components/formik fields/SelectField";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  GenericYesNoOptionsValue,
  initInput,
  initSelect,
  isZipCode,
} from "../../components/data";
import useCountries from "../../components/useCountries";
import { n565CurrentInfoAboutYouObj } from "../../../../api/n565/types";
import { Modify, SingleOption } from "../../../../types/types";
// import AddAnotherBtn from "../../components/AddAnotherBtn";
import RadioComp from "../../components/RadioComp";
import GroupedSection from "../../components/GroupedSection";
// import { toast } from "react-toastify";
import { N565MaritalStatusData } from "./data";
import useStatesByCountry from "../../components/useStatesByCountry";
import { SubTitle } from "../../form ds 160/components/styles";

export interface otherNamesArrayPropsPayload {
  otherNamesFirstName: string;
  otherNamesMiddleName: string;
  otherNamesLastName: string;
}

const initialOtherNamesArray: otherNamesArrayPropsPayload = {
  otherNamesFirstName: "",
  otherNamesMiddleName: "",
  otherNamesLastName: "",
};

export interface N565CurrentInfoAboutYouFormData
  extends Modify<
    Omit<
      n565CurrentInfoAboutYouObj,
      | "otherNamesFirstName"
      | "otherNamesMiddleName"
      | "otherNamesLastName"
      | "zipCode"
      | "postalCode"
    >,
    {
      otherNamesArray: otherNamesArrayPropsPayload[];
      stateOrTerritory: SingleOption | null;
      apartmentSuiteOrFloor: SingleOption | null;
      country: SingleOption | null;
      maritalStatus: SingleOption | null;
      addressCode: string;
    }
  > {}

const CurrentInfoAboutYou = () => {
  const { data, isFetching, isError } = useGetN565ApplicantInfo();
  const { mutate, isLoading: isSaving } = useSaveN565ApplicantInfo();
  const { toNextSection } = useToNextSection();
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(data?.country);

  const savedOtherNamesArray = () => {
    if (data?.otherNamesFirstName) {
      const otherNamesFirstNameData = data?.otherNamesFirstName
        .split(",")
        .map((ev: any) => ({ otherNamesFirstName: ev }));
      const otherNamesMiddleNameData = data?.otherNamesMiddleName
        .split(",")
        .map((ev: any) => ({ otherNamesMiddleName: ev }));

      const otherNamesLastNameData = data?.otherNamesLastName
        .split(",")
        .map((ev: any) => ({ otherNamesLastName: ev }));

      return otherNamesFirstNameData.map((ev: any, i: any) => ({
        ...ev,
        ...otherNamesMiddleNameData[i],
        ...otherNamesLastNameData[i],
      }));
    } else return [initialOtherNamesArray];
  };

  const onSubmit = (values: N565CurrentInfoAboutYouFormData) => {
    const {
      otherNamesArray,
      maritalStatus,
      stateOrTerritory,
      apartmentSuiteOrFloor,
      country,
      addressCode,
      ...rest
    } = values;

    if (!stateOrTerritory || !country) return;

    const payload = {
      ...rest,
      otherNamesFirstName: otherNamesArray
        .map((ev) => ev.otherNamesFirstName)
        .join(","),
      otherNamesMiddleName: otherNamesArray
        .map((ev) => ev.otherNamesMiddleName)
        .join(","),
      otherNamesLastName: otherNamesArray
        .map((ev) => ev.otherNamesLastName)
        .join(","),
      apartmentSuiteOrFloor: apartmentSuiteOrFloor?.value || "",
      country: country.value,
      stateOrTerritory: stateOrTerritory.value,
      postalCode: !isZipCode(country?.value) ? addressCode : "",
      zipCode: isZipCode(country?.value) ? addressCode : "",
      maritalStatus: maritalStatus?.value || "",
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N565CurrentInfoAboutYouFormData = {
    firstName: initInput(data?.firstName),
    middleName: initInput(data?.middleName),
    lastName: initInput(data?.lastName),
    otherNames: initInput(data?.otherNames),
    otherNamesArray: savedOtherNamesArray(),
    inCareOfName: initInput(data?.inCareOfName),
    apartmentSuiteOrFloor: initSelect(
      ApartmentSuiteFloorData,
      data?.apartmentSuiteOrFloor
    ),
    addressNumber: initInput(data?.addressNumber),
    country: initSelect(formattedCountryData, data?.country),
    addressCode: isZipCode(data?.country)
      ? initInput(data?.zipCode)
      : initInput(data?.postalCode),
    streetNumberAndName: initInput(data?.streetNumberAndName),
    cityOrTown: initInput(data?.cityOrTown),
    province: initInput(data?.province),
    stateOrTerritory: initSelect(formattedStateData, data?.stateOrTerritory),
    didMaritalStatusChange: initInput(data?.didMaritalStatusChange),
    maritalStatus: initSelect(N565MaritalStatusData, data?.maritalStatus),
    lostOrRenCitizenship: initInput(data?.lostOrRenCitizenship),
    reasonForLoss: initInput(data?.reasonForLoss),

    applicantsDaytimeTelephoneNumber: initInput(
      data?.applicantsDaytimeTelephoneNumber
    ),
    applicantsMobileTelephoneNumber: initInput(
      data?.applicantsMobileTelephoneNumber
    ),
    applicantsEmailAddress: initInput(data?.applicantsEmailAddress),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Title>Your Full Legal Name (Do not provide a nickname)</Title>
        <Formik
          initialValues={initialValues}
          validationSchema={CurrentInfoAboutYouValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <InputField
                  label="Family Name"
                  coloredLabel="(Last Name)"
                  placeholder=""
                  name="lastName"
                />
                <InputField
                  label="Given Name"
                  coloredLabel="(First Name)"
                  placeholder=""
                  name="firstName"
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  label="Middle Name(s)"
                  coloredLabel="(optional)"
                  placeholder=""
                  name="middleName"
                />
                <div></div>
              </FormWrapper>

              <SubTitle>Applicant Contact Info</SubTitle>

              <FormWrapper>
                <InputField
                  name="applicantsDaytimeTelephoneNumber"
                  label="Applicant's Daytime Telephone Number"
                  placeholder=""
                />
                <InputField
                  name="applicantsMobileTelephoneNumber"
                  label="Applicant's Mobile Telephone Number"
                  coloredLabel="(if any)"
                  placeholder=""
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  name="applicantsEmailAddress"
                  label="Applicant's Email Address"
                  coloredLabel="(if any)"
                  placeholder=""
                />
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  title="Have you used any other names since birth?"
                  name="otherNames"
                  options={GenericYesNo}
                  onChange={() => {
                    formik.setFieldTouched("otherNamesFirstName", false);
                    formik.setFieldTouched("otherNamesMiddleName", false);
                    formik.setFieldTouched("otherNamesLastName", false);
                  }}
                />
                <div></div>
              </FormWrapper>

              {formik.values.otherNames === GenericYesNoOptionsValue.yes && (
                <>
                  <Note>Other Names You Have Used Since Birth</Note>

                  <FieldArray
                    name="otherNamesArray"
                    render={(arrayHelpers) => (
                      <div className="px-2 py-3 mb-8 border rounded">
                        {formik.values.otherNamesArray.map((_, index) => (
                          <GroupedSection
                            key={index}
                            index={index}
                            onCancel={() => arrayHelpers.remove(index)}
                          >
                            <div className={`text-[${Colors.Blue00}]`}>
                              Entry {index + 1}:
                            </div>

                            <div className="mb-8">
                              <FormWrapper>
                                <InputField
                                  label="Family Name"
                                  coloredLabel="(Last Name)"
                                  placeholder=""
                                  name={`otherNamesArray[${index}].otherNamesLastName`}
                                />
                                <InputField
                                  label="Given Name"
                                  coloredLabel="(First Name)"
                                  placeholder=""
                                  name={`otherNamesArray[${index}].otherNamesFirstName`}
                                />
                              </FormWrapper>

                              <FormWrapper>
                                <InputField
                                  label="Middle Name"
                                  coloredLabel="(if applicable)"
                                  placeholder=""
                                  name={`otherNamesArray[${index}].otherNamesMiddleName`}
                                />
                                <div></div>
                              </FormWrapper>
                            </div>
                          </GroupedSection>
                        ))}
                        {/* <AddAnotherBtn
                          onClick={() => {
                            if (formik.values.otherNamesArray.length === 8) {
                              toast.error("Limit Reached");
                              return;
                            }

                            arrayHelpers.push(initialOtherNamesArray);
                          }}
                        /> */}
                      </div>
                    )}
                  />
                </>
              )}

              <FormWrapper>
                <InputField
                  label="In Care of Name"
                  placeholder=""
                  name="inCareOfName"
                />
                <InputField
                  label="Street number and name"
                  placeholder=""
                  name="streetNumberAndName"
                />
              </FormWrapper>

              <FormWrapper>
                <SelectField
                  name="apartmentSuiteOrFloor"
                  label="In there an apartment, suite or floor"
                  placeholder="-Select-"
                  options={ApartmentSuiteFloorData}
                />

                <div>
                  {formik.values.apartmentSuiteOrFloor?.value && (
                    <InputField
                      label={`${formik.values.apartmentSuiteOrFloor?.value} Address number`}
                      placeholder=""
                      name={`addressNumber`}
                    />
                  )}
                </div>
              </FormWrapper>

              <FormWrapper>
                <SelectField
                  name="country"
                  label="Country"
                  placeholder="-Select-"
                  options={formattedCountryData}
                  isLoading={countryLoading}
                  onChange={(value) => {
                    setCountry(value.label);
                    formik.setFieldValue("stateOrTerritory", null);
                  }}
                />

                <SelectField
                  name="stateOrTerritory"
                  label="State or Territory"
                  placeholder="-Select-"
                  options={formattedStateData}
                  isLoading={stateLoading}
                  isDisabled={!selectedCountry}
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  label="City or Town"
                  placeholder=""
                  name="cityOrTown"
                />

                <InputField
                  label={`${
                    isZipCode(formik.values.country?.value) ? "Zip" : "Postal"
                  } Code`}
                  placeholder=""
                  name={`addressCode`}
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  label="Province"
                  coloredLabel="(If any)"
                  placeholder=""
                  name={`province`}
                />

                <div></div>
              </FormWrapper>

              <FormWrapper>
                <RadioWrapper>
                  <RadioComp
                    title="Has your marital status changed since your last document was issued?"
                    name="didMaritalStatusChange"
                    options={GenericYesNo}
                  />
                </RadioWrapper>

                {formik.values.didMaritalStatusChange ===
                  GenericYesNoOptionsValue.yes && (
                  <SelectField
                    name="maritalStatus"
                    label="Your Current Marital Status"
                    placeholder="-Select-"
                    options={N565MaritalStatusData}
                  />
                )}
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  title="Since becoming a U.S. citizen, have you lost or renounced your U.S. citizenship in any manner?"
                  name="lostOrRenCitizenship"
                  options={GenericYesNo}
                />

                <div></div>
              </FormWrapper>

              <FormWrapper>
                {formik.values.lostOrRenCitizenship ===
                  GenericYesNoOptionsValue.yes && (
                  <InputField
                    label="If yes, when and how did this happen?"
                    placeholder=""
                    name="reasonForLoss"
                    inputType="text-area"
                  />
                )}
                <div></div>
              </FormWrapper>

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default CurrentInfoAboutYou;

const Wrapper = styled.div``;

const Title = styled.div`
  color: ${Colors.Black31};
  font-weight: bold;
  margin-bottom: 2rem;
`;

const Note = styled.div`
  color: ${Colors.Black31};
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 0.875rem;
`;
