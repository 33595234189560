import React, { useEffect, useMemo, useRef } from "react";
import {
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  PerspectiveCamera,
  Points,
  PointsMaterial,
  Scene,
  WebGLRenderer,
} from "three";

interface TTSAudioVisualizerProps {
  width?: number;
  height?: number;
  mediaSource: MediaElementAudioSourceNode | null;
  audioContextRef: React.MutableRefObject<AudioContext | null>;
  analyserRef: React.MutableRefObject<AnalyserNode | null>;
}

const TTSAudioVisualizer: React.FC<TTSAudioVisualizerProps> = ({
  width = 500,
  height = 500,
  mediaSource,
  audioContextRef,
  analyserRef,
}) => {
  const mountRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<Scene | null>(null);
  const rendererRef = useRef<WebGLRenderer | null>(null);
  const cameraRef = useRef<PerspectiveCamera | null>(null);
  const pointsRef = useRef<Points | null>(null);
  // const analyserRef = useRef<AnalyserNode | null>(null);
  // const audioContextRef = useRef<AudioContext | null>(null);
  // const audioSourceRef = useRef<MediaElementAudioSourceNode | null>(null);
  const originalPositionsRef = useRef<Float32Array | null>(null);

  // Array of your desired colors
  const colors = useMemo(
    () => ["#0276E9", "#9C2BF0", "#EAC5FC", "#00C1A3"],
    []
  );

  // Initialize Three.js scene
  useEffect(() => {
    if (!mountRef.current) return;

    // Scene setup
    const scene = new Scene();
    sceneRef.current = scene;
    // Set transparent background
    scene.background = new Color("#E8F4FF");

    // Camera
    const camera = new PerspectiveCamera(75, width / height, 0.1, 1000);
    cameraRef.current = camera;
    camera.position.z = 5;

    // Renderer with explicit dimensions
    const renderer = new WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearColor(0x000000, 0); // optional, but good practice.
    rendererRef.current = renderer;
    renderer.setSize(width, height);

    // Clear existing children if any
    while (mountRef.current.firstChild) {
      mountRef.current.removeChild(mountRef.current.firstChild);
    }

    mountRef.current.appendChild(renderer.domElement);

    // Apply explicit styles to the canvas
    const canvas = renderer.domElement;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    // canvas.style.width = width ? `${width}px` : `100%`;
    // canvas.style.height = height ? `${height}px` : `100%`;
    canvas.style.display = "block"; // Ensure it's visible
    canvas.style.backgroundColor = "transparent"; // Visual indicator

    // Create spherical grid
    const gridSize = 32;
    const radius = 2;
    const positions: number[] = [];
    const colorValues: number[] = [];

    // Convert hex colors to THREE.Color objects
    const threeColors = colors.map((color) => new Color(color));

    for (let lat = 0; lat <= gridSize; lat++) {
      for (let lon = 0; lon <= gridSize; lon++) {
        const phi = (lat / gridSize) * Math.PI;
        const theta = (lon / gridSize) * Math.PI * 2;

        const x = radius * Math.sin(phi) * Math.cos(theta);
        const y = radius * Math.sin(phi) * Math.sin(theta);
        const z = radius * Math.cos(phi);

        positions.push(x, y, z);

        // Select a color from the colors array based on position
        // This gives bands of colors around the sphere
        const colorIndex = Math.floor((lat / gridSize) * colors.length);
        const selectedColor = threeColors[colorIndex % threeColors.length];

        // Add slight variation to the color
        const variationAmount = 0.1;
        colorValues.push(
          selectedColor.r +
            (Math.random() * variationAmount - variationAmount / 2),
          selectedColor.g +
            (Math.random() * variationAmount - variationAmount / 2),
          selectedColor.b +
            (Math.random() * variationAmount - variationAmount / 2)
        );
      }
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new Float32BufferAttribute(positions, 3));
    geometry.setAttribute("color", new Float32BufferAttribute(colorValues, 3));

    // Store the original positions for restoration
    originalPositionsRef.current = new Float32Array(positions);

    const material = new PointsMaterial({
      size: 0.05,
      vertexColors: true,
      transparent: true,
      opacity: 0.8,
    });

    const points = new Points(geometry, material);
    pointsRef.current = points;
    scene.add(points);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Rotate the sphere
      if (points) {
        points.rotation.y += 0.002;
        points.rotation.x += 0.001;
      }

      // Update point positions based on audio frequencies
      if (
        analyserRef.current &&
        pointsRef.current &&
        originalPositionsRef.current
      ) {
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
        analyserRef.current.getByteFrequencyData(dataArray);

        const positions = pointsRef.current.geometry.getAttribute("position");
        const colors = pointsRef.current.geometry.getAttribute("color");
        const originalPositions = originalPositionsRef.current;

        // Calculate overall volume (average of all frequencies)
        let totalVolume = 0;
        for (let i = 0; i < dataArray.length; i++) {
          totalVolume += dataArray[i];
        }

        const averageVolume = totalVolume / dataArray.length / 255; // Normalize to 0-1

        // Modify points based on audio
        for (let i = 0; i < positions.count; i++) {
          const frequencyIndex = Math.min(
            Math.floor((i / positions.count) * (dataArray.length / 4)),
            dataArray.length - 1
          );

          // Get frequency value and normalize it (0-1)
          const frequencyValue = dataArray[frequencyIndex] / 255;

          // Get original position for this point
          const origX = originalPositions[i * 3];
          const origY = originalPositions[i * 3 + 1];
          const origZ = originalPositions[i * 3 + 2];

          // Calculate displacement based on frequency
          const freqDisplacement = frequencyValue * 1.5;

          // Calculate final position
          const newX = origX * (1 + freqDisplacement * 0.4);
          const newY = origY * (1 + freqDisplacement * 0.4);
          const newZ = origZ * (1 + freqDisplacement * 0.4);

          // Set new position
          positions.setXYZ(i, newX, newY, newZ);

          // Determine the base color index from the position in the sphere
          const latIndex = Math.floor(
            (i / positions.count) * threeColors.length
          );
          const baseColor = threeColors[latIndex % threeColors.length];

          // Modify color intensity based on frequency
          const intensifiedColor = new Color(
            Math.min(baseColor.r + frequencyValue * 0.3, 1.0),
            Math.min(baseColor.g + frequencyValue * 0.3, 1.0),
            Math.min(baseColor.b + frequencyValue * 0.3, 1.0)
          );

          colors.setXYZ(
            i,
            intensifiedColor.r,
            intensifiedColor.g,
            intensifiedColor.b
          );
        }

        positions.needsUpdate = true;
        colors.needsUpdate = true;

        // Scale the point size based on overall volume
        if (pointsRef.current.material instanceof PointsMaterial) {
          pointsRef.current.material.size = 0.05 + averageVolume * 0.05;
        }
      }

      // Render
      renderer.render(scene, camera);
    };

    animate();

    // Capture current mount node in a variable
    const mountNode = mountRef.current;
    const currentRenderer = rendererRef.current;

    // Cleanup
    return () => {
      if (mountNode && currentRenderer) {
        mountNode.removeChild(currentRenderer.domElement);
      }
      if (currentRenderer) {
        currentRenderer.dispose();
      }
    };
  }, [width, height, colors, analyserRef]);

  // Connect to audio element whenever it changes
  // useEffect(() => {
  //   // Disconnect previous audio source if exists
  //   if (audioSourceRef.current && analyserRef.current) {
  //     try {
  //       audioSourceRef.current.disconnect(analyserRef.current);
  //       audioSourceRef.current.disconnect();
  //     } catch (e) {
  //       // Ignore disconnection errors
  //     }
  //     // setIsConnected(false);
  //   }

  //   // Close previous audio context
  //   if (audioContextRef.current && audioContextRef.current.state !== "closed") {
  //     try {
  //       audioContextRef.current.close();
  //     } catch (e) {
  //       // Ignore close errors
  //     }
  //   }

  //   // If we have a valid audio element, connect to it
  //   if (audioElement && mediaSource) {
  //     try {
  //       // Create new audio context
  //       const audioContext = new (window.AudioContext ||
  //         (window as any).webkitAudioContext)();
  //       audioContextRef.current = audioContext;

  //       // Create analyzer
  //       const analyser = audioContext.createAnalyser();
  //       analyser.fftSize = 2048;
  //       analyserRef.current = analyser;

  //       // Create source from audio element and connect to analyzer
  //       // const source = audioContext.createMediaElementSource(audioElement);
  //       // audioSourceRef.current = source;

  //       audioSourceRef.current = mediaSource;

  //       // Connect source -> analyzer -> destination
  //       mediaSource.connect(analyser);
  //       mediaSource.connect(audioContext.destination);
  //     } catch (error) {
  //       console.error("Error connecting to audio element:", error);
  //     }
  //   }

  //   // Cleanup
  //   return () => {
  //     if (audioSourceRef.current && analyserRef.current) {
  //       try {
  //         audioSourceRef.current.disconnect(analyserRef.current);
  //         audioSourceRef.current.disconnect();
  //       } catch (e) {
  //         // Ignore disconnection errors
  //       }
  //     }
  //   };
  // }, [analyserRef, audioContextRef, audioElement, mediaSource]);

  useEffect(() => {
    // Capture the current AudioContext once
    const currentAudioContext = audioContextRef.current;

    // (Your effect logic here, e.g., connecting nodes)
    if (mediaSource && currentAudioContext) {
      try {
        const analyser = currentAudioContext.createAnalyser();
        analyser.fftSize = 2048;
        analyserRef.current = analyser;
        mediaSource.connect(analyser);
        mediaSource.connect(currentAudioContext.destination);
      } catch (error) {
        console.error("Error connecting to audio element:", error);
      }
    }

    // Cleanup using the captured value
    return () => {
      if (mediaSource && analyserRef.current && currentAudioContext) {
        try {
          mediaSource.disconnect(analyserRef.current);
          mediaSource.disconnect(currentAudioContext.destination);
        } catch (e) {
          // Ignore disconnection errors
        }
      }
    };
    // Do NOT include audioContextRef in the dependency array,
    // as its .current property is being captured here.
  }, [mediaSource, analyserRef, audioContextRef]);

  // Apply explicit styling to container
  return (
    <div
      ref={mountRef}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        // width: width ? `${width}px` : "100%",
        // height: height ? `${height}px` : "100%",
        backgroundColor: "transparent", // Visual indicator
        position: "relative",
        overflow: "hidden",
      }}
    />
  );
};

export default TTSAudioVisualizer;
