import React from "react";
interface Props {
  onClose: () => void;
}
const Disclaimer: React.FC<Props> = ({ onClose }) => {
  return (
    <div className="w-full max-w-[750px]">
      <h1 className="text-lg font-bold">
        Welcome to FiBi Interview Preparation!
      </h1>
      <p className="mt-2 text-gray-500">
        FiBi is here to help you prepare for your interview by simulating the
        interview experience.
      </p>

      <ul className="my-6 ml-4 text-gray-500 list-disc">
        <li>FiBi will ask you interview questions</li>
        <li>You can practice answering these questions</li>
        <li>If you're stuck you can ask FiBi for help or suggestions</li>
      </ul>

      <footer>
        <p className="text-sm italic text-gray-500">
          <strong>Disclaimer:</strong> Our AI offers broad insights but isn't a
          substitute for legal advice. Immigration laws are complex and vary, so
          always consult a qualified immigration attorney for personalized
          guidance.
        </p>
      </footer>

      <div className="flex justify-end mt-4">
        <button
          onClick={onClose}
          className="bg-[#0076E9] text-white py-2 px-4 rounded-md font-medium"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;
