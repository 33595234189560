import React from "react";
import styled from "styled-components";
import CongratsImage from "../../../../images/web/congrats.svg";
import Colors from "../../../../styles/Colors";

const AnonFormComplete = () => {
  return (
    <div className="flex flex-col items-center justify-center ">
      <MainIcon>
        <img src={CongratsImage} alt="" />
      </MainIcon>
      <ContentWrapper>
        <TextBold>Your Form Filling is Complete!</TextBold>
      </ContentWrapper>
    </div>
  );
};

export default AnonFormComplete;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;
