import styled from "styled-components";
import Colors from "../../../../styles/Colors";

interface Props {
  checked?: boolean;
}

interface BoxProps extends Props {}

export const CheckboxContainer = styled.div<BoxProps>`
  width: 40px;
  height: 24px;
  position: relative;
  background: ${({ checked }) => (checked ? Colors.Blue00 : `white`)};
  display: flex;
  align-items: center;
  border-radius: 32px;
  border: ${({ checked }) => (!checked ? `1.5px solid ${Colors.Blue00}` : ``)};
  padding: 1px 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  user-select: none;
  color: ${Colors.Blue00};
`;

export const BallIcon = styled.div<BoxProps>`
  width: 100%;
  height: 100%;
  background: ${({ checked }) => (!checked ? Colors.Blue00 : `white`)};
  border-radius: 32px;
  transition: all 0.3s ease-in-out;
`;

export const IconBox = styled.div<Props>`
  background: white;
  box-shadow: 0px 0.651587px 1.30317px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  left: ${({ checked }) => (checked ? `calc(100% - 18px)` : `2.5px`)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
`;
