import { proxy } from "valtio";
import storage from "../utils/storage";

export const seamlessIsAnonClient = "seamlessIsAnonClient";
const isAnonClientData = storage.get(seamlessIsAnonClient);

export const isAnonClientDataStore = proxy<{
  isAnonClientData?: string;
}>({
  isAnonClientData: isAnonClientData,
});
