import React, { FC } from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik, FormikProps } from "formik";
import InputField from "../../../components/formik fields/InputField";
import FormBtn from "../../../components/FormBtn";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import SelectField from "../../../components/formik fields/SelectField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import {
  ExplicitNGDateFormat,
  ToSaveDate,
  resetDynamicFields,
} from "../../../components/data";
import { Info, InfoBold, SubTitle, TermsWrapper, Wrapper } from "../styles";
import RadioComp from "../../../components/RadioComp";
import {
  DS160MaritalStatusData,
  FormattedVisaLocationsData,
  PersonalWhereApplyingVisaFromCondition,
  PIPersonal1Gender,
  processReviewData,
} from "../data";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initCheck,
  initDate,
  initInput,
  initSelect,
} from "../../../components/data";
import { Modify, SingleOption } from "../../../../../types/types";
import {
  DS160EligibilityPayload,
  saveDS160PersonalInfoObj,
} from "../../../../../api/ds160/types";
import { Dayjs } from "dayjs";
import {
  useGetDS160PersonalInfo,
  useSaveDS160PersonalInfo,
} from "../../../../../hooks/ds160/usDS160";
import { PIPersonal1Validation } from "../validation";
import useCountries from "../../../components/useCountries";
import useStatesByCountry from "../../../components/useStatesByCountry";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { ReviewPersonalSectionA } from "../Review/components/data";
import useAiReview from "../useAiReview";
import { mediaObj } from "../../../../../styles/Media";
import { BusinessQueryKey } from "../../../../../api/utils/types";
import { useSearchParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { isAnonClientDataStore } from "../../../../../store/isAnonClientData";
import { TooltipProps, TooltipType } from "../../../components/Tooltip";

export interface PIPersonal1FormData
  extends Modify<
    saveDS160PersonalInfoObj,
    {
      maritalStatus: SingleOption | null;
      stateAndProvince: SingleOption | null;
      countryOrRegion: SingleOption | null;
      location: SingleOption | null;
      dateOfBirth: Dayjs | null;
      location_risky_checkbox: boolean;
    }
  > {}

interface Props {
  formEligibility: DS160EligibilityPayload;
}

const PIPersonal1: FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160PersonalInfo({
    applicationId: getApplicationId,
    buid: isAnonClientData,
  });
  const { mutate, isLoading: isSaving } = useSaveDS160PersonalInfo();
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(data?.countryOrRegion);
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const initialValues: PIPersonal1FormData = {
    lastName: initInput(data?.lastName),
    givenNames: initInput(data?.givenNames),
    fullName: initInput(data?.fullName),
    doesNotApply: initCheck(data?.doesNotApply),
    anyOtherNames: initInput(data?.anyOtherNames),
    otherFirstName: initInput(data?.otherFirstName),
    otherMiddleName: initInput(data?.otherMiddleName),
    otherLastName: initInput(data?.otherLastName),
    teleCode: initInput(data?.teleCode),
    teleCodeSurname: initInput(data?.teleCodeSurname),
    teleCodeGivenNames: initInput(data?.teleCodeGivenNames),
    sex: initInput(data?.sex),
    maritalStatus: initSelect(DS160MaritalStatusData, data?.maritalStatus),
    stateAndProvince: initSelect(formattedStateData, data?.stateAndProvince),
    doesNotApplyStateAndProvince: initCheck(data?.doesNotApplyStateAndProvince),
    dateOfBirth: initDate(data?.dateOfBirth, ExplicitNGDateFormat),
    city: initInput(data?.city),
    countryOrRegion: initSelect(formattedCountryData, data?.countryOrRegion),
    location: initSelect(FormattedVisaLocationsData, data?.location),
    location_risky_checkbox: false,
  };

  const onSubmit = (values: PIPersonal1FormData) => {
    const {
      maritalStatus,
      stateAndProvince,
      countryOrRegion,
      dateOfBirth,
      location,
      ...rest
    } = values;

    if (
      !maritalStatus ||
      // !stateAndProvince ||
      !countryOrRegion ||
      !dateOfBirth ||
      !location
    )
      return;

    const payload = {
      ...rest,
      maritalStatus: maritalStatus.value,
      stateAndProvince: stateAndProvince?.value || "",
      countryOrRegion: countryOrRegion.value,
      location: location.value,
      dateOfBirth: ToSaveDate(dateOfBirth, ExplicitNGDateFormat),
    };

    const sectionA = ReviewPersonalSectionA(payload);

    const result = processReviewData(sectionA);

    const riskPayload = {
      ...riskData,
      personal_Info: {
        ...riskData?.personal_Info,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId, buid: isAnonClientData },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const getEligibilityValidation = (
    formik: FormikProps<PIPersonal1FormData>,
    name: string
  ): TooltipProps | undefined => {
    if (name === "location") {
      if (
        PersonalWhereApplyingVisaFromCondition(formik.values, formEligibility)
      )
        return {
          isReversed: true,
          type: TooltipType.risky,
        };
    }
  };

  return (
    <>
      <FormSectionWrapper
        isLoading={isFetching || (!isAnonClientData && riskLoading)}
        isError={isError || (!isAnonClientData && riskError)}
      >
        <Wrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={PIPersonal1Validation(formEligibility)}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <FormWrapper>
                  <SelectField
                    name="location"
                    label="Select a location where you will be applying for this visa"
                    placeholder="-Select-"
                    options={FormattedVisaLocationsData}
                    toolTipProps={getEligibilityValidation(formik, "location")}
                  />
                  <div></div>
                </FormWrapper>

                <Info>
                  <InfoBold>
                    NOTE: Data on this page must match information as written on
                    your passport.
                  </InfoBold>
                </Info>

                <FormWrapper>
                  <InputField
                    label="Family Name"
                    coloredLabel="(Last Name)"
                    placeholder=""
                    name="lastName"
                  />
                  <InputField
                    label="Given Names"
                    placeholder=""
                    name="givenNames"
                  />
                </FormWrapper>

                <FormWrapper>
                  <InputField
                    label="Full Name in Native Alphabet"
                    placeholder=""
                    name="fullName"
                    isDisabled={!!formik.values.doesNotApply}
                  />
                  <div></div>
                </FormWrapper>
                <Terms>
                  <CheckBoxField
                    label={`Does not apply`}
                    name="doesNotApply"
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "fullName", value: "" },
                      ]);
                    }}
                  />
                </Terms>

                <FormWrapper>
                  <RadioComp
                    title="Have you used any other names since birth?"
                    name="anyOtherNames"
                    options={GenericYesNo}
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "otherFirstName", value: "" },
                        { name: "otherMiddleName", value: "" },
                        { name: "otherLastName", value: "" },
                      ]);
                    }}
                  />
                </FormWrapper>

                {formik.values.anyOtherNames ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <FormWrapper>
                      <InputField
                        label="Given Name"
                        coloredLabel="(First Name)"
                        placeholder=""
                        name="otherFirstName"
                      />
                      <InputField
                        label="Middle Name"
                        coloredLabel="(s)"
                        placeholder=""
                        name="otherMiddleName"
                      />
                    </FormWrapper>
                    <FormWrapper>
                      <InputField
                        label="Family Name"
                        coloredLabel="(Last Name)"
                        placeholder=""
                        name="otherLastName"
                      />
                      <div></div>
                    </FormWrapper>
                  </>
                )}

                <FormWrapper>
                  <RadioComp
                    title="Do you have a telecode that represents your name?"
                    name="teleCode"
                    options={GenericYesNo}
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "teleCodeSurname", value: "" },
                        { name: "teleCodeGivenNames", value: "" },
                      ]);
                    }}
                  />
                </FormWrapper>

                {formik.values.teleCode === GenericYesNoOptionsValue.yes && (
                  <>
                    <FormWrapper>
                      <InputField
                        label="Telecode Surname"
                        placeholder=""
                        name="teleCodeSurname"
                      />
                      <div></div>
                    </FormWrapper>
                    <FormWrapper>
                      <InputField
                        label="Telecode Given Names"
                        placeholder=""
                        name="teleCodeGivenNames"
                      />
                      <div></div>
                    </FormWrapper>
                  </>
                )}

                <FormWrapper>
                  <RadioComp
                    title="Sex"
                    name="sex"
                    options={PIPersonal1Gender}
                  />
                </FormWrapper>

                <FormWrapper>
                  <SelectField
                    name="maritalStatus"
                    label="Marital status"
                    placeholder="-Select-"
                    options={DS160MaritalStatusData}
                    isDisabled={!!data?.maritalStatus}
                  />
                  <div></div>
                </FormWrapper>

                <SubTitle>Birth Information</SubTitle>
                <FormWrapper>
                  <DatePickerField
                    name="dateOfBirth"
                    label="Date of birth"
                    placeholder={ExplicitNGDateFormat}
                    format={ExplicitNGDateFormat}
                    disableFuture={true}
                  />
                  <InputField name="city" label="City" placeholder="" />
                </FormWrapper>

                <FormWrapper>
                  <SelectField
                    name="countryOrRegion"
                    label="Country/Region"
                    placeholder="-Select-"
                    options={formattedCountryData}
                    isLoading={countryLoading}
                    onChange={(value) => {
                      setCountry(value.label);
                      formik.setFieldValue("stateAndProvince", null);
                    }}
                  />

                  <div className="flex flex-col gap-2">
                    <SelectField
                      name="stateAndProvince"
                      label="State/Province"
                      placeholder="-Select-"
                      options={formattedStateData}
                      isLoading={stateLoading}
                      isDisabled={
                        !selectedCountry ||
                        !!formik.values.doesNotApplyStateAndProvince
                      }
                    />
                    <CheckBoxField
                      name="doesNotApplyStateAndProvince"
                      label="Does not apply"
                      onChange={() => {
                        resetDynamicFields(formik, [
                          { name: "stateAndProvince", value: null },
                        ]);
                      }}
                    />
                  </div>
                </FormWrapper>
                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && formik.dirty && !formik.isValid}
                />
              </Form>
            )}
          </Formik>
        </Wrapper>
      </FormSectionWrapper>
      {/* 
      {open && (
        <SectionAssessment
          onClose={onCloseModal}
          onSave={onSave}
          payload={open}
        />
      )} */}
    </>
  );
};

export default PIPersonal1;

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 38px;
  margin-bottom: 30px;

  & > div {
    flex: 1;
  }

  ${mediaObj.bigMobile} {
    flex-direction: column;
  }
`;

const Terms = styled(TermsWrapper)`
  margin-bottom: 30px;
  margin-top: -18px;
`;
