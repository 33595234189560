import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { parseError } from "../../utils";
import { businessAuthQueryKeys, StaffQueryKeys } from "./types";
import {
  createStaff,
  deleteStaff,
  getAllStaff,
  getSingleStaff,
  staffSignIn,
  toggleStaffStatus,
  updateStaff,
} from "../../api/business/staff";

export const useGetAllStaff = () => {
  return useQuery({
    queryKey: [StaffQueryKeys.getAllStaff],
    queryFn: getAllStaff,
    select: (data) => data.data.data,
  });
};

export const useGetSingleStaff = (staffId: string) => {
  return useQuery({
    enabled: !!staffId,
    queryKey: [StaffQueryKeys.getSingleStaff, staffId],
    queryFn: () => getSingleStaff(staffId),
    select: (data) => data.data.data[0],
  });
};

export const useCreateStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createStaff,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeys.getAllStaff],
      });
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeys.getSingleStaff],
      });
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]);
    },
    onError: (error) => parseError(error),
  });
};

export const useUpdateStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateStaff,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeys.getAllStaff],
      });
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeys.getSingleStaff],
      });
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]);
    },
    onError: (error) => parseError(error),
  });
};

export const useDeleteStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteStaff,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeys.getAllStaff],
      });
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeys.getSingleStaff],
      });
    },
    onError: (error) => parseError(error),
  });
};

export const useToggleStaffStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: toggleStaffStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeys.getAllStaff],
      });
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeys.getSingleStaff],
      });
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]);
    },
    onError: (error) => parseError(error),
  });
};

export const useStaffSignIn = () => {
  return useMutation({
    mutationFn: staffSignIn,
    onSuccess: () => {
      // queryClient
      //   .invalidateQueries({
      //     queryKey: [StaffQueryKeys.getAllStaff, StaffQueryKeys.getSingleStaff],
      //   })
      //   .then((r) => console.log(r));
    },
    onError: (error) => parseError(error),
  });
};
