import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AnonClientParam } from "../components/types";
import { loginFailed } from "../../../../utils";
import {
  accessTokenStore,
  seamlessAccessToken,
} from "../../../../store/accessToken";
import storage from "../../../../utils/storage";
import {
  isAnonClientDataStore,
  seamlessIsAnonClient,
} from "../../../../store/isAnonClientData";

const useAnonClient = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getBuid = searchParams.get(AnonClientParam.buid) || "";
  const getAccess = searchParams.get(AnonClientParam.access) || "";

  useEffect(() => {
    if (getBuid && getAccess) {
      loginFailed();
      accessTokenStore.accessToken = getAccess;
      isAnonClientDataStore.isAnonClientData = getBuid;
      storage.set(seamlessAccessToken, getAccess);
      storage.set(seamlessIsAnonClient, getBuid);

      searchParams.delete(AnonClientParam.buid);
      searchParams.delete(AnonClientParam.access);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, getAccess, getBuid]);

  return {};
};

export default useAnonClient;
