import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../../store/userData";
import { businessFibiClientStore } from "../../../../../../store/businessFibiClient";
import Sidebar from "./FiBiChatSidebar";
import BusinessStarter from "./BusinessStarter";
import BusinessMain from "./BusinessMain";
import { SessionQuery } from "./types";

const BusinessFibiVoice = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionTemp = searchParams.get(SessionQuery.sessionCount);
  const { userData } = useSnapshot(userDataStore);
  const { fibiClientToken } = useSnapshot(businessFibiClientStore);
  const [sidebarItems, setSidebarItems] = useState<
    { label: string; count: number }[]
  >([]);

  const [showInterviewModal, setShowInterviewModal] = useState<boolean>(false);
  const [sessionCompleted, setSessionCompleted] = useState<boolean>(false);

  const changeSessionCompleted = useCallback((second: boolean) => {
    setSessionCompleted(second);
  }, []);

  useEffect(() => {
    if (!userData) return;

    const count = userData.interviewCount ?? 1;

    if (sessionTemp) {
      if (+sessionTemp <= count) return;
      const temp = new Array(+sessionTemp).fill(1).map((ev, index) => ({
        label: `Session ${index + 1}`,
        count: index + 1,
      }));

      setSidebarItems(temp);
    } else {
      searchParams.set(SessionQuery.sessionCount, `${count}`);
      setSearchParams(searchParams);

      const temp = new Array(count).fill(1).map((ev, index) => ({
        label: `Session ${index + 1}`,
        count: index + 1,
      }));

      setSidebarItems(temp);
    }
  }, [searchParams, sessionTemp, setSearchParams, userData]);

  useEffect(() => {
    if (!userData) return;

    const count = userData.interviewCount ?? 1;

    if (count > sidebarItems.length) {
      const temp = new Array(count).fill(1).map((ev, index) => ({
        label: `Session ${index + 1}`,
        count: index + 1,
      }));

      setSidebarItems(temp);
    }
  }, [sidebarItems.length, userData]);

  const userName = userData?.userInfo?.name.split(" ")[0];

  const onStart = () => {
    setShowInterviewModal(true);
  };

  const onInterviewOff = () => {
    setShowInterviewModal(false);
  };

  return (
    <div className="relative flex gap-4">
      {fibiClientToken && (
        <Sidebar
          items={sidebarItems}
          maxWidth={300}
          collapsedWidth={50}
          sessionCompleted={sessionCompleted}
          onInterviewOff={onInterviewOff}
        />
      )}

      <div className="flex flex-col items-center justify-start flex-1 w-auto p-2 pt-8 border lg:p-6 rounded-2xl gap-y-4 lg:w-full">
        <h1 className="text-center text-lg lg:text-[40px] font-bold text-primaryColor">
          FiBi Interview with {userName || "--"}
        </h1>
        {fibiClientToken && !showInterviewModal ? (
          <BusinessStarter onStart={onStart} />
        ) : (
          <BusinessMain changeSessionCompleted={changeSessionCompleted} />
        )}
      </div>
    </div>
  );
};

export default BusinessFibiVoice;
