import React from "react";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import {
  ArrayWrapper,
  GroupWrapper,
  Info,
  InfoBold,
  VerticalFormWrapper,
  Wrapper,
} from "./styles";
import { FieldArray, Form, Formik } from "formik";
import styled from "styled-components";
import FormBtn from "../../components/FormBtn";
import RadioComp from "../../components/RadioComp";
import InputField from "../../components/formik fields/InputField";
import DatePickerField from "../../components/formik fields/DatePickerField";
import {
  ExplicitNGDateFormat,
  ToSaveDate,
  getSelectedOption,
  initCheck,
  initDate,
  initInput,
} from "../../components/data";
import { GenericYesNo, GenericYesNoOptionsValue } from "../../components/data";
import { Dayjs } from "dayjs";
import { Modify, SingleOption } from "../../../../types/types";
import { saveDS160PreviousUSTravelObj } from "../../../../api/ds160/types";
import {
  useGetDS160PreviousUSTravel,
  useSaveDS160PreviousUSTravel,
} from "../../../../hooks/ds160/usDS160";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import { PrevUSTravelSectionValidation } from "./validation";
import GroupedSection from "./GroupedSection";
import AddAnotherBtn from "./AddAnotherBtn";
import { resetDynamicFields } from "../../components/data";
import useToNextSection from "../../../../hooks/useToNextSection";
import { IntendedLengthOfStayDropDown, processReviewData } from "./data";
import SelectField from "../../components/formik fields/SelectField";
import { Note } from "../../form 400/components/styles";
import useAiReview from "./useAiReview";
import { ReviewPreviousUSSection } from "./Review/components/data";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../api/utils/types";
import { isAnonClientDataStore } from "../../../../store/isAnonClientData";
import { useSnapshot } from "valtio";

const initialBeenToTheUs: BeenToTheUsPropsPayload = {
  dateArrived: null,
  lengthOfStayDuration: "",
  lengthOfStayDropdown: null,
};

interface BeenToTheUsPropsPayload {
  dateArrived: Dayjs | null;
  lengthOfStayDuration: string;
  lengthOfStayDropdown: SingleOption | null;
}

export interface PrevUSTravelSectionFormData
  extends Modify<
    Omit<
      saveDS160PreviousUSTravelObj,
      "dateArrived" | "lengthOfStayDuration" | "lengthOfStayDropdown"
    >,
    {
      BeenToTheUsArray: BeenToTheUsPropsPayload[];
      lastVisaDate: Dayjs | null;
      visaStolenYear: Dayjs | null;
      visaRevokeYear: Dayjs | null;
    }
  > {}

const PrevUSTravelSection = () => {
  const { toNextSection } = useToNextSection();
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160PreviousUSTravel({
    applicationId: getApplicationId,
    buid: isAnonClientData,
  });
  const { mutate, isLoading: isSaving } = useSaveDS160PreviousUSTravel();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const savedBeenToTheUsArray = () => {
    if (
      data?.dateArrived ||
      data?.lengthOfStayDuration ||
      data?.lengthOfStayDropdown
    ) {
      const dateArrivedData = data?.dateArrived
        .split(",")
        .map((ev) => ({ dateArrived: initDate(ev, ExplicitNGDateFormat) }));

      const lengthOfStayDurationData = data?.lengthOfStayDuration
        .split(",")
        .map((ev) => ({ lengthOfStayDuration: ev }));
      const lengthOfStayDropdownData = data?.lengthOfStayDropdown
        .split(",")
        .map((ev) => ({
          lengthOfStayDropdown: getSelectedOption(
            IntendedLengthOfStayDropDown,
            ev
          ),
        }));

      return dateArrivedData.map((ev, i) => ({
        ...ev,
        ...lengthOfStayDurationData[i],
        ...lengthOfStayDropdownData[i],
      }));
    } else return [initialBeenToTheUs];
  };

  const initialValues: PrevUSTravelSectionFormData = {
    everBeenToTheUs: initInput(data?.everBeenToTheUs),
    BeenToTheUsArray: savedBeenToTheUsArray(),
    everIssuedUsVisa: initInput(data?.everIssuedUsVisa),
    haveDriverLicense: initInput(data?.haveDriverLicense),
    driverLicenseNumber: initInput(data?.driverLicenseNumber),
    stateOfDriverLicense: initInput(data?.stateOfDriverLicense),
    lastVisaDate: initDate(data?.lastVisaDate, ExplicitNGDateFormat),
    visaNumber: initInput(data?.visaNumber),
    doNotKnowVisaNumber: initCheck(data?.doNotKnowVisaNumber),
    applyingForSameTypeOfVisa: initInput(data?.applyingForSameTypeOfVisa),
    applyingInSameLocation: initInput(data?.applyingInSameLocation),
    visaEverStolen: initInput(data?.visaEverStolen),
    visaStolenYear: initDate(data?.visaStolenYear),
    visaStolenExplanation: initInput(data?.visaStolenExplanation),
    visaEverRevoked: initInput(data?.visaEverRevoked),
    visaRevokeYear: initDate(data?.visaRevokeYear),
    visaRevokeExplanation: initInput(data?.visaRevokeExplanation),
    everBeenRefusedUsVisa: initInput(data?.everBeenRefusedUsVisa),
    anyoneFiledPetition: initInput(data?.anyoneFiledPetition),
    //
    beenTenPrinted: initInput(data?.beenTenPrinted),
    beenLPR: initInput(data?.beenLPR),
    everBeenRefusedUsVisaExplanation: initInput(
      data?.everBeenRefusedUsVisaExplanation
    ),
    beenLPRExplanation: initInput(data?.beenLPRExplanation),
    anyoneFiledPetitionExplanation: initInput(
      data?.anyoneFiledPetitionExplanation
    ),
  };

  const onSubmit = (values: PrevUSTravelSectionFormData) => {
    const {
      lastVisaDate,
      BeenToTheUsArray,
      visaRevokeYear,
      visaStolenYear,
      ...rest
    } = values;

    const payload = {
      ...rest,
      lastVisaDate: ToSaveDate(lastVisaDate, ExplicitNGDateFormat),
      dateArrived: BeenToTheUsArray.map((ev) =>
        ToSaveDate(ev.dateArrived, ExplicitNGDateFormat)
      ).join(","),
      lengthOfStayDuration: BeenToTheUsArray.map(
        (ev) => ev.lengthOfStayDuration
      ).join(","),
      lengthOfStayDropdown: BeenToTheUsArray.map(
        (ev) => ev.lengthOfStayDropdown?.value || ""
      ).join(","),
      visaStolenYear: visaStolenYear ? visaStolenYear.format("YYYY") : "",
      visaRevokeYear: visaRevokeYear ? visaRevokeYear.format("YYYY") : "",
    };

    const section = ReviewPreviousUSSection(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      travel: {
        ...riskData?.travel,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId, buid: isAnonClientData },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || (!isAnonClientData && riskLoading)}
      isError={isError || (!isAnonClientData && riskError)}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your previous US
            travel.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={PrevUSTravelSectionValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  title="Have you ever been to the US?"
                  name="everBeenToTheUs"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "BeenToTheUsArray", value: [initialBeenToTheUs] },
                      { name: "haveDriverLicense", value: "" },
                    ]);
                  }}
                />

                {formik.values.everBeenToTheUs ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <GroupWrapper>
                      <FieldArray
                        name="BeenToTheUsArray"
                        render={(arrayHelpers) => (
                          <ArrayWrapper>
                            {formik.values.BeenToTheUsArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <DatePickerField
                                  name={`BeenToTheUsArray[${index}].dateArrived`}
                                  label="Date Arrived"
                                  placeholder={ExplicitNGDateFormat}
                                  disableFuture={true}
                                />
                                <InputField
                                  name={`BeenToTheUsArray[${index}].lengthOfStayDuration`}
                                  label={`Length of Stay`}
                                  placeholder=""
                                />
                                <SelectField
                                  name={`BeenToTheUsArray[${index}].lengthOfStayDropdown`}
                                  label={`Length of Stay`}
                                  placeholder="-Select-"
                                  options={IntendedLengthOfStayDropDown}
                                />
                              </GroupedSection>
                            ))}

                            <AddAnotherBtn
                              onClick={() =>
                                arrayHelpers.push(initialBeenToTheUs)
                              }
                            />
                          </ArrayWrapper>
                        )}
                      />
                    </GroupWrapper>

                    <RadioComp
                      title="Do you or did you have a driver’s license?"
                      name="haveDriverLicense"
                      options={GenericYesNo}
                      onChange={() => {
                        resetDynamicFields(formik, [
                          { name: "driverLicenseNumber", value: "" },
                          { name: "stateOfDriverLicense", value: "" },
                        ]);
                      }}
                    />

                    {formik.values.haveDriverLicense ===
                      GenericYesNoOptionsValue.yes && (
                      <>
                        <InputField
                          label="Driver’s License Number"
                          placeholder=""
                          name="driverLicenseNumber"
                        />
                        <InputField
                          label="State of Driver’s License"
                          placeholder=""
                          name="stateOfDriverLicense"
                        />
                      </>
                    )}
                  </>
                )}

                <RadioComp
                  title="Have you ever been issued a US visa?"
                  name="everIssuedUsVisa"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "lastVisaDate", value: null },
                      { name: "visaNumber", value: "" },
                      { name: "doNotKnowVisaNumber", value: false },
                      { name: "applyingForSameTypeOfVisa", value: "" },
                      { name: "applyingInSameLocation", value: "" },
                      { name: "visaEverStolen", value: "" },
                      { name: "visaEverRevoked", value: "" },
                      // { name: "everBeenRefusedUsVisa", value: "" },
                      // { name: "anyoneFiledPetition", value: "" },
                    ]);
                  }}
                />

                {formik.values.everIssuedUsVisa ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <DatePickerField
                      name={`lastVisaDate`}
                      label="Date last visa was issued"
                      placeholder={ExplicitNGDateFormat}
                      disableFuture={true}
                    />
                    <VisaWrapper>
                      <InputField
                        label="Visa Number"
                        placeholder=""
                        name="visaNumber"
                        isDisabled={!!formik.values.doNotKnowVisaNumber}
                      />
                      <CheckWrapper>
                        <CheckBoxField
                          label={`Do not know`}
                          name="doNotKnowVisaNumber"
                          onChange={() => {
                            resetDynamicFields(formik, [
                              { name: "visaNumber", value: "" },
                            ]);
                          }}
                        />
                      </CheckWrapper>
                    </VisaWrapper>

                    <RadioComp
                      title="Are you applying for the same type of visa?"
                      name="applyingForSameTypeOfVisa"
                      options={GenericYesNo}
                    />

                    <RadioComp
                      title="Are you applying in the same country or location where the visa above was issued, and is this country or location your place of principal residence?"
                      name="applyingInSameLocation"
                      options={GenericYesNo}
                    />

                    <RadioComp
                      title="Have you been ten-printed?"
                      name="beenTenPrinted"
                      options={GenericYesNo}
                    />
                    <Note>
                      Ten-printed means that you have provided fingerprints for
                      all your fingers, as opposed to having provided only two
                      fingerprints.
                    </Note>

                    <RadioComp
                      title="Has your US visa ever been lost or stolen?"
                      name="visaEverStolen"
                      options={GenericYesNo}
                      onChange={() => {
                        resetDynamicFields(formik, [
                          { name: "visaStolenYear", value: null },
                          { name: "visaStolenExplanation", value: "" },
                        ]);
                      }}
                    />

                    {formik.values.visaEverStolen ===
                      GenericYesNoOptionsValue.yes && (
                      <>
                        <DatePickerField
                          label="Year"
                          placeholder=""
                          name="visaStolenYear"
                          views={["year"]}
                          disableFuture={true}
                        />
                        <InputField
                          label="Explanation"
                          placeholder=""
                          name="visaStolenExplanation"
                          inputType="text-area"
                        />
                      </>
                    )}

                    <RadioComp
                      title="Has your US visa ever been cancelled or revoked?"
                      name="visaEverRevoked"
                      options={GenericYesNo}
                      onChange={() => {
                        resetDynamicFields(formik, [
                          { name: "visaRevokeYear", value: null },
                          { name: "visaRevokeExplanation", value: "" },
                        ]);
                      }}
                    />

                    {formik.values.visaEverRevoked ===
                      GenericYesNoOptionsValue.yes && (
                      <>
                        <DatePickerField
                          label="Year"
                          placeholder=""
                          name="visaRevokeYear"
                          views={["year"]}
                          disableFuture={true}
                        />
                        <InputField
                          label="Explanation"
                          placeholder=""
                          name="visaRevokeExplanation"
                          inputType="text-area"
                        />
                      </>
                    )}
                  </>
                )}

                <RadioComp
                  title="Have you ever been refused a US visa or been refused admission to United States or withdrawn your application for admission at the port of entry?"
                  name="everBeenRefusedUsVisa"
                  options={GenericYesNo}
                />

                {formik.values.everBeenRefusedUsVisa ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explanation"
                      placeholder=""
                      name="everBeenRefusedUsVisaExplanation"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Are you or have you ever been a U.S. legal permanent resident?"
                  name="beenLPR"
                  options={GenericYesNo}
                />

                {formik.values.beenLPR === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explanation"
                      placeholder=""
                      name="beenLPRExplanation"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Has anyone ever filed an immigration petition on your behalf with the United States Citizenship and Immigration Services?"
                  name="anyoneFiledPetition"
                  options={GenericYesNo}
                />

                {formik.values.anyoneFiledPetition ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explanation"
                      placeholder=""
                      name="anyoneFiledPetitionExplanation"
                      inputType="text-area"
                    />
                  </>
                )}
              </FormWrapper>
              <FormBtn
                isLoading={isSaving || riskSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default PrevUSTravelSection;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    /* width: 50%; */
  }
`;

const VisaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;

const CheckWrapper = styled.div`
  flex-shrink: 0;
`;
