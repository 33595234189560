import axios from "axios";
import { useCallback, useState } from "react";

interface UseOpenAiTTSResult {
  isLoading: boolean;
  error: Error | null;
  audioUrl: string | null;
  synthesize: (text: string) => Promise<void>;
}

const apiKey =
  "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";
// const ttsUrl: string = `https://api.openai.com/v1/audio/speech`;

// const api = axios.create({
//   baseURL: "https://api.openai.com/v1/", // Replace with your base URL if applicable
//   headers: {
//     Authorization: `Bearer ${apiKey}`,
//   },
// });

const useOpenAiTTS = (): UseOpenAiTTSResult => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const synthesize = useCallback(
    async (text: string) => {
      // First, ensure any previous request has completely finished
      if (isLoading) {
        console.log("Already processing a request, skipping");
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/audio/speech",
          {
            model: "tts-1",
            input: text,
            voice: "echo",
            response_format: "mp3",
            speed: 1.0,
          },
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
              "Content-Type": "application/json",
            },
            responseType: "blob",
          }
        );

        // Convert the binary data (Blob) into an object URL
        const blob = new Blob([response.data], { type: "audio/mpeg" });
        const url = URL.createObjectURL(blob);

        setAudioUrl(url);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading]
  );

  return { isLoading, error, audioUrl, synthesize };
};

export default useOpenAiTTS;
