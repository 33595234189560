import { FC } from "react";
import {
  FaCircleNotch,
  FaCirclePlay,
  FaCircleStop,
  FaMicrophone,
} from "react-icons/fa6";
import CountdownTimer from "./CountdownTimer";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import Spinner from "../../../../../../utils/Spinner";
import { VscDebugRestart } from "react-icons/vsc";
import { Message } from "./types";
import useFiBiVoiceBottomBar from "./useFiBiVoiceBottomBar";
import { AxiosResponse } from "axios";
import { ApiResponse } from "../../../../../../types/types";
import useOpener from "../../../../../../hooks/useOpener";
import TerminateModal from "../../../../components/modal/TerminateModal";

export interface FiBiVoiceBottomBarProps {
  onAddToChatHistory: (value: Message) => void;
  allMessages: Message[];
  businessProps?: {
    onEnd: () => Promise<any>;
    currentSession: string;
  };
  normalProps?: {
    onRestart: () => void;
    deleteLoading: boolean;
  };
  audioRef: React.MutableRefObject<HTMLAudioElement>;
  audioContextRef: React.MutableRefObject<AudioContext | null>;
  requestFunc: (
    prompt: string
  ) => Promise<AxiosResponse<ApiResponse<any, any>, any> | undefined>;
}

const FiBiVoiceBottomBar: FC<FiBiVoiceBottomBarProps> = (props) => {
  const {
    startStopMic,
    loading,
    loadingAudio,
    isListening,
    isSpeaking,
    userName,
    stopListening,
    startListening,
  } = useFiBiVoiceBottomBar(props);

  const isBusinessProps = !!props.businessProps;
  const { open, onOpen, onClose } = useOpener();

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between w-full p-4 mx-auto bg-blue-100 md:w-[85%] rounded-3xl gap-y-4 md:gap-x-6">
        {/* Mobile layout */}
        <div className="flex flex-row items-center justify-between w-full md:hidden gap-x-4">
          {/* START STOP MIC BUTTON */}
          <button
            onClick={startStopMic}
            disabled={loadingAudio || isListening || loading}
          >
            {loadingAudio ? (
              <div className="flex items-center justify-center p-2 text-blue-500 rounded-lg bg-gray-50">
                <FaCircleNotch size={24} className="animate-spin" />
              </div>
            ) : isSpeaking ? (
              <div className="flex items-center justify-center p-2 text-red-500 bg-red-200 rounded-lg">
                <FaCircleStop size={24} />
              </div>
            ) : (
              <div className="flex items-center justify-center p-2 text-blue-500 bg-white rounded-lg">
                <FaCirclePlay size={24} />
              </div>
            )}
          </button>

          {/* Middle content - timer/restart button */}
          {props.normalProps ? (
            <Btn
              bg={Colors.RedE3}
              onClick={props.normalProps.onRestart}
              disabled={props.normalProps.deleteLoading}
            >
              {props.normalProps.deleteLoading ? (
                <Spinner size={24} color="white" />
              ) : (
                <VscDebugRestart size={24} color="white" />
              )}
            </Btn>
          ) : (
            <div className="font-bold text-gray-800">
              {isBusinessProps && props.businessProps && (
                <CountdownTimer
                  duration={600}
                  onEnd={props.businessProps.onEnd}
                />
              )}
            </div>
          )}

          {/* User mic button */}
          <button
            onClick={isListening ? stopListening : startListening}
            disabled={isSpeaking || loading || loadingAudio}
          >
            {loading ? (
              <div className="flex items-center justify-center p-2 text-blue-500 rounded-lg bg-gray-50">
                <FaCircleNotch size={24} className="animate-spin" />
              </div>
            ) : isListening ? (
              <div className="flex items-center justify-center p-2 text-red-500 rounded-lg bg-red-50">
                <FaCircleStop size={24} />
              </div>
            ) : (
              <div className="flex items-center justify-center p-2 text-blue-500 bg-white rounded-lg">
                <FaMicrophone size={24} />
              </div>
            )}
          </button>
        </div>

        {/* Mobile second row - business session info */}
        {isBusinessProps && props.businessProps && !props.normalProps && (
          <div className="flex justify-between w-full px-2 md:hidden">
            <button className="text-red-500 underline" onClick={onOpen}>
              End session
            </button>
            <div className="text-gray-800">
              <span className="text-sm">
                {props.businessProps.currentSession}
              </span>
            </div>
          </div>
        )}

        {/* Desktop layout */}
        <div className="items-center justify-between hidden w-full md:flex">
          {/* Original desktop content */}
          <button
            onClick={startStopMic}
            disabled={loadingAudio || isListening || loading}
          >
            {loadingAudio ? (
              <div className="flex items-center justify-center p-2 text-blue-500 rounded-lg bg-gray-50">
                <FaCircleNotch size={24} className="animate-spin" />
              </div>
            ) : isSpeaking ? (
              <div className="flex items-center justify-center p-2 text-red-500 bg-red-200 rounded-lg">
                <FaCircleStop size={24} />
              </div>
            ) : (
              <div className="flex items-center justify-center p-2 text-blue-500 bg-white rounded-lg">
                <FaCirclePlay size={24} />
              </div>
            )}
          </button>

          <div className="hidden md:block h-4/5 w-[1px] bg-gray-400" />

          {props.normalProps ? (
            <Btn
              bg={Colors.RedE3}
              onClick={props.normalProps.onRestart}
              disabled={props.normalProps.deleteLoading}
            >
              {props.normalProps.deleteLoading ? (
                <Spinner size={24} color="white" />
              ) : (
                <VscDebugRestart size={24} color="white" />
              )}
            </Btn>
          ) : (
            <div className="flex flex-col items-center md:flex-row gap-y-4 md:gap-x-10">
              <div className="font-bold text-gray-800">
                <span className="tracking-widest">
                  {isBusinessProps && props.businessProps && (
                    <CountdownTimer
                      duration={600}
                      onEnd={props.businessProps.onEnd}
                    />
                  )}
                </span>
              </div>

              {isBusinessProps && props.businessProps && (
                <button className="text-red-500 underline" onClick={onOpen}>
                  End session
                </button>
              )}

              {isBusinessProps && props.businessProps && (
                <div className="text-gray-800">
                  <span className="text-lg">
                    {props.businessProps.currentSession}
                  </span>
                </div>
              )}
            </div>
          )}

          <div className="hidden md:block h-4/5 w-[1px] bg-gray-400" />

          <div className="flex items-center gap-x-4">
            <div className="hidden font-bold text-gray-800 md:flex">
              <span className="text-lg">{userName}</span>
            </div>
            <button
              onClick={isListening ? stopListening : startListening}
              disabled={isSpeaking || loading || loadingAudio}
            >
              {loading ? (
                <div className="flex items-center justify-center p-2 text-blue-500 rounded-lg bg-gray-50">
                  <FaCircleNotch size={24} className="animate-spin" />
                </div>
              ) : isListening ? (
                <div className="flex items-center justify-center p-2 text-red-500 rounded-lg bg-red-50">
                  <FaCircleStop size={24} />
                </div>
              ) : (
                <div className="flex items-center justify-center p-2 text-blue-500 bg-white rounded-lg">
                  <FaMicrophone size={24} />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>

      {open && props.businessProps && (
        <TerminateModal
          title="Are you sure you want to end this session?"
          subText="This session will be ended immediately. This action is irreversible."
          onClose={onClose}
          onConfirm={props.businessProps.onEnd}
          isLoading={false}
        />
      )}
    </>
  );
};

export default FiBiVoiceBottomBar;

interface BtnProps {
  bg?: string;
}

const Btn = styled.button<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) => (bg ? bg : Colors.Blue00)};
  width: 48px;
  height: 48px;
  border-radius: 4px;
  flex-shrink: 0;

  &:disabled {
    background-color: #b3bac1;
  }
`;
