import React, { useCallback, useEffect, useState } from "react";
import UseTTSVisualizer from "./UseTTSVisualizer";
import ChatInterface from "./FiBiVoiceMessagesDisplay";
import FiBiVoiceBottomBar from "./FiBiVoiceBottomBar";
import {
  useDeleteInterviewHistory,
  useGetChatGptInterviewVoiceHistory,
} from "../../../../../../hooks/utils/useUtils";
import useAudio from "./useAudio";
import { userDataStore } from "../../../../../../store/userData";
import { useSnapshot } from "valtio";
import { saveInterviewChatGpt } from "../../../../../../api/ai";
import useAllMessages from "./useAllMessages";
import RequestWrapper from "../../../../components/RequestWrapper";

const NormalFiBiVoice: React.FC = () => {
  const { userData } = useSnapshot(userDataStore);
  const {
    data: historyData,
    isFetching: historyLoading,
    isError: historyIsError,
  } = useGetChatGptInterviewVoiceHistory();
  const { mutate: deleteMutate, isLoading: deleteLoading } =
    useDeleteInterviewHistory();
  const { audioRef, analyserRef, audioContextRef, mediaSourceRef } = useAudio();
  const { allMessages, onAddToChatHistory, onReset } = useAllMessages({
    historyLoading,
    historyData,
  });

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [isSmaller, setIsSmaller] = useState<boolean>(false);

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 500) setIsSmaller(true);
  }, []);

  const onRestart = () => {
    deleteMutate(undefined, {
      onSuccess: () => {
        onReset();
      },
    });
  };

  const requestFunc = useCallback(
    async (prompt: string) => {
      if (!userData?.userInfo.userId) return;

      return await saveInterviewChatGpt({
        id: userData?.userInfo.userId,
        payload: {
          prompt,
        },
      });
    },
    [userData?.userInfo.userId]
  );

  return (
    <RequestWrapper
      isError={historyIsError}
      isLoading={historyLoading}
      data={true}
    >
      <>
        {allMessages !== undefined && (
          <div className="flex flex-col gap-4">
            <div className="flex w-full gap-x-6">
              <div className=" grid grid-cols-1 lg:grid-cols-[450px_1fr] gap-4 mt-10 w-full">
                {/* FIBI */}
                <div className="hidden w-full overflow-hidden lg:block">
                  <div
                    className={`relative border rounded-2xl p-6 md:py-10 md:px-20 flex items-start justify-center gap-x-6 bg-[#E8F4FF]`}
                  >
                    <UseTTSVisualizer
                      width={isSmaller ? 200 : isMobile ? 300 : 500}
                      height={isSmaller ? 200 : isMobile ? 300 : 400}
                      // width={isMobile ? undefined : 500}
                      // height={isMobile ? undefined : 400}
                      mediaSource={mediaSourceRef.current}
                      audioContextRef={audioContextRef}
                      analyserRef={analyserRef}
                    />
                  </div>
                </div>

                {/* USER */}
                <div className="w-full">
                  <ChatInterface
                    messages={allMessages}
                    isLoading={historyLoading}
                    isError={historyIsError}
                  />
                </div>
              </div>
            </div>
            <>
              <FiBiVoiceBottomBar
                normalProps={{
                  onRestart,
                  deleteLoading,
                }}
                onAddToChatHistory={onAddToChatHistory}
                audioRef={audioRef}
                allMessages={allMessages}
                audioContextRef={audioContextRef}
                requestFunc={requestFunc}
              />
            </>
          </div>
        )}
      </>
    </RequestWrapper>
  );
};

export default NormalFiBiVoice;
