import React, { useState } from "react";
import styled from "styled-components";
import Constaint from "../../../layout/components/Constaint";
import Colors from "../../../../../styles/Colors";
import CalendlyPopUp from "./CalendlyPopUp";
//
const Cta = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper className="bg-blue-100">
      <Constaint>
        <Content>
          <Header>
            <h2>Request a free demo today</h2>
            <p>Get a free demo to see how we can help you</p>
          </Header>

          <CTA>
            <CTAText>
              <p>Have any questions? We're here to help.</p>
              <h2>sales@seamlessvisa.com</h2>
            </CTAText>
            <Button type="button" onClick={() => setOpen(true)}>
              Request a free demo
            </Button>
          </CTA>
          <CalendlyPopUp open={open} onClose={onClose} />
        </Content>
      </Constaint>
    </Wrapper>
  );
};

export default Cta;

const Wrapper = styled.div`
  margin: 8rem 0;
`;

const Content = styled.div`
  padding: 6rem 0;
`;

const Header = styled.div`
  width: 70%;
  margin-inline: auto;
  text-align: center;

  & > h2 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  & > p {
    font-size: 1rem;
    margin-bottom: 3rem;
  }
`;
const CTA = styled.div`
  border: 1px solid #0076e9;
  border-radius: 8px;
  padding: 20px 48px;
  /* background: #f9fcff; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  width: 100%;
  max-width: 850px;
  margin: auto;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const CTAText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  color: ${Colors.Black1A};
  flex: 1;

  & > p {
    margin-bottom: 1rem;
  }

  & > h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @media only screen and (min-width: 768px) {
      font-size: 2.2rem;
      line-height: 1.2;
      line-height: 2.1875rem;
    }
  }
`;

const Button = styled.button`
  width: 222px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  background: #0076e9;
  border-radius: 8px;
  color: #f9fcff;

  &:hover {
    background: #022e58;
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }

  margin: auto;

  @media only screen and (min-width: 768px) {
    margin: 40px 0;
  }
`;
