import { PlusIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";

interface Props {
  text: string;
  icon?: string;
  onClick: () => void;
  isDisabled?: boolean;
}

const CreateBtn: FC<Props> = ({ text, icon, onClick, isDisabled }) => {
  return (
    <button
      disabled={isDisabled}
      className="flex items-center gap-2 px-6 py-2 font-medium capitalize transition-all rounded-lg disabled:text-black enabled:text-white enabled:border enabled:bg-primaryColor border-primaryColor enabled:hover:bg-transparent enabled:hover:text-primaryColor disabled:bg-[#adadad]"
      onClick={onClick}
    >
      {icon || <PlusIcon width={16} height={16} />} {text}
    </button>
  );
};

export default CreateBtn;
