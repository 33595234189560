import React, { useState } from "react";
import EligibleConfirmation from "./components/EligibleConfirmation";
import { useParams } from "react-router-dom";
import CheckEligibilityQuestions from "./components/CheckEligibilityQuestions";
import { checkFormQuestions } from "./components/data";
import SEOMeta from "../components/SEOMeta";
import { WebPaths } from "../../../routes/data";
import { SVLogoSquare } from "../../../images";

const CheckEligibility = () => {
  const [first, setfirst] = useState(true);
  const { formType } = useParams();

  const onChangeState = (value: boolean) => {
    setfirst(value);
  };

  return (
    <>
      <SEOMeta
        title="Check Your Eligibility for a U.S. Visa"
        description="Use SeamlessVisa to quickly check your eligibility for U.S. visa options like the green card or fiancé visa."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.checkEligibility}`}
        url={`https://www.seamlessvisa.com${WebPaths.checkEligibility}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="Check Your Eligibility for a U.S. Visa"
        twitterDescription="Use SeamlessVisa to quickly check your eligibility for U.S. visa options like the green card or fiancé visa."
        twitterSite="@SeamlessVisa_"
      />
      {first ? (
        <EligibleConfirmation onChangeState={onChangeState} />
      ) : (
        formType && (
          <CheckEligibilityQuestions
            onChangeState={onChangeState}
            questions={checkFormQuestions[formType]}
          />
        )
      )}
    </>
  );
};

export default CheckEligibility;
