import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { userDataStore } from "../store/userData";
import { useSnapshot } from "valtio";
import { FormCategories } from "../routes/type";
import { EnumX } from "../types/types";
import { FormN565Progress } from "../views/web/form 565/components/types";
import { FormN400Progress } from "../views/web/form 400/components/types";
import { FormDS160Progress } from "../views/web/form ds 160/components/types";
import { getEnumIndex } from "../views/web/components/data";
import useBasedOnFormPaid from "./useBasedOnFormPaid";
import { FormN600Progress } from "../views/web/form 600/components/types";
import { BusinessQueryKey } from "../api/utils/types";
import { isBusinessDataStore } from "../store/isBusinessData";
import { applicationDataStore } from "../store/applicationData";
import { isAnonClientDataStore } from "../store/isAnonClientData";

const useToNextSection = () => {
  const navigate = useNavigate();
  const { basedOnFormPaid } = useBasedOnFormPaid();
  const { formCategory: urlFormCategory } = useParams();
  const { userData } = useSnapshot(userDataStore);
  const { applicationData } = useSnapshot(applicationDataStore);
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("type") as any;
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const lastSavedProgress = userData?.currentFormInfo.formProgress;

  const getNextSection = (formProgressEnum: any) => {
    const nextSection = EnumX.of(formProgressEnum).next(getType); //possible value is undefined and a form progress value

    if (nextSection && isAnonClientData) {
      const nextProgress = getEnumIndex(formProgressEnum, nextSection);
      const savedProgress = getEnumIndex(formProgressEnum, lastSavedProgress);
      navigate(
        `?type=${nextSection}${
          getApplicationId
            ? `&${BusinessQueryKey.appId}=${getApplicationId}`
            : ""
        }`
      );
      if (savedProgress > nextProgress) return;
      if (basedOnFormPaid) return;

      return;
    }

    if (
      (isBusinessData ? !applicationData : !userDataStore.userData) ||
      !urlFormCategory
    )
      return;

    if (nextSection) {
      const nextProgress = getEnumIndex(formProgressEnum, nextSection);
      const savedProgress = getEnumIndex(formProgressEnum, lastSavedProgress);
      navigate(
        `?type=${nextSection}${
          getApplicationId
            ? `&${BusinessQueryKey.appId}=${getApplicationId}`
            : ""
        }`
      );
      if (savedProgress > nextProgress) return;
      if (basedOnFormPaid) return;
      if (!applicationData?.applicationInfo) return; //should always be there
      //update store
      if (
        isBusinessData
          ? !applicationData?.applicationInfo.isFormCompleted
          : !userData?.currentFormInfo.isFormCompleted
      ) {
        if (isBusinessData) {
          if (
            !applicationDataStore.applicationData ||
            !applicationDataStore.applicationData.applicationInfo
          )
            return;
          applicationDataStore.applicationData.applicationInfo.formCategory =
            urlFormCategory;
          applicationDataStore.applicationData.applicationInfo.formProgress =
            nextSection;
        } else {
          if (!userDataStore.userData) return;
          userDataStore.userData.currentFormInfo.formCategory = urlFormCategory;
          userDataStore.userData.currentFormInfo.formProgress = nextSection;
        }

        if (
          isBusinessData
            ? nextSection ===
              `${applicationData?.applicationInfo.formCategory}-review`
            : nextSection === `${userData?.currentFormInfo.formCategory}-review`
        ) {
          if (isBusinessData) {
            if (
              !applicationDataStore.applicationData ||
              !applicationDataStore.applicationData.applicationInfo
            )
              return;
            applicationDataStore.applicationData.applicationInfo.isFormCompleted =
              true;
          } else {
            if (!userDataStore.userData) return;
            userDataStore.userData.currentFormInfo.isFormCompleted = true;
          }
        }
      }
    }
  };

  const getPrevSection = (formProgressEnum: any) => {
    const nextSection = EnumX.of(formProgressEnum).prev(getType); //possible value is undefined and a form progress value

    if (
      isAnonClientData
        ? false
        : (isBusinessData ? !applicationData : !userDataStore.userData) ||
          !urlFormCategory
    )
      return;

    if (nextSection) {
      navigate(
        `?type=${nextSection}${
          getApplicationId
            ? `&${BusinessQueryKey.appId}=${getApplicationId}`
            : ""
        }`
      );
    }
  };

  const toSection = (params: (formProgressEnum: any) => void) => {
    if (urlFormCategory === FormCategories.n565) {
      params(FormN565Progress);
    }

    if (urlFormCategory === FormCategories.n400) {
      params(FormN400Progress);
    }

    if (urlFormCategory === FormCategories.ds160) {
      params(FormDS160Progress);
    }

    if (urlFormCategory === FormCategories.n600) {
      params(FormN600Progress);
    }
  };

  const toNextSection = () => {
    toSection(getNextSection);
  };

  const toPrevSection = () => {
    toSection(getPrevSection);
  };

  return { toNextSection, toPrevSection };
};

export default useToNextSection;
