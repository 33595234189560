import { ApiResponse } from "../../types/types";
import { saveDS160MaritalStatusObj } from "../../views/web/form ds 160/components/FamilyRelatives/MaritalStatus";
import { GetFileDetailObj } from "../n400/types";
import request from "../request";
import {
  saveDS160AddressAndPhoneObj,
  saveDS160FamilyObj,
  saveDS160PassportObj,
  saveDS160PersonalInfo2Obj,
  saveDS160PersonalInfoObj,
  saveDS160PreviousUSTravelObj,
  saveDS160TravelCompanionsObj,
  saveDS160TravelObj,
  saveDS160USContactObj,
  saveDS160WETPresentObj,
  saveDS160WETPreviousObj,
  saveDS160WETAdditionalObj,
  saveDS160SEVAdditionalContactObj,
  saveDS160SivesObj,
  saveDS160SecurityBackgroundP1Obj,
  saveDS160SecurityBackgroundP2Obj,
  saveDS160SecurityBackgroundP3Obj,
  saveDS160SecurityBackgroundP4Obj,
  saveDS160SecurityBackgroundP5Obj,
  GetDs160Obj,
  GetDS160GetStartedRes,
  DS160EligibilityPayload,
  payDS160Data,
  UpdateDS160FilesPayload,
  SaveDS160VistorEmailPayload,
  UploadDS160FilesPayload,
  Ds160GetQuery,
} from "./types";

export const saveDS160GetStarted = async (payload: FormData) => {
  return await request.post<ApiResponse>(`/user/ds160-get-started`, payload);
};

export const getDS160GetStarted = async () => {
  return await request.get<ApiResponse<GetDS160GetStartedRes>>(
    `/user/get-ds160-get-started`
  );
};

export const saveDS160PersonalInfo = async (
  payload: saveDS160PersonalInfoObj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/personal-info${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160PersonalInfo = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160PersonalInfoObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-personal-info${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160PersonalInfo2 = async (
  payload: saveDS160PersonalInfo2Obj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/personal-info-2${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160PersonalInfo2 = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160PersonalInfo2Obj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-personal-info-2${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160Travel = async (payload: saveDS160TravelObj) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/travel${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160Travel = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160TravelObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-travel${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`
  );
};

export const saveDS160TravelCompanions = async (
  payload: saveDS160TravelCompanionsObj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/travel-companions${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`,
    rest
  );
};

export const getDS160TravelCompanions = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160TravelCompanionsObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-travel-companions${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160PreviousUSTravel = async (
  payload: saveDS160PreviousUSTravelObj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/previous-us-travel${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`,
    rest
  );
};

export const getDS160PreviousUSTravel = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160PreviousUSTravelObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-previous-us-travel${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160AddressAndPhone = async (
  payload: saveDS160AddressAndPhoneObj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/address-and-phone${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`,
    rest
  );
};

export const getDS160AddressAndPhone = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160AddressAndPhoneObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-address-and-phone${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160Passport = async (payload: saveDS160PassportObj) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/passport${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160Passport = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160PassportObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-passport${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`
  );
};

export const saveDS160USContact = async (payload: saveDS160USContactObj) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/us-contact${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160USContact = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160USContactObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-us-contact${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`
  );
};

export const saveDS160Family = async (payload: saveDS160FamilyObj) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/family${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160Family = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160FamilyObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-family${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`
  );
};

export const saveDS160WETPresent = async (payload: saveDS160WETPresentObj) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/wet-present${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160WETPresent = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160WETPresentObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-wet-present${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`
  );
};

export const saveDS160WETPrevious = async (
  payload: saveDS160WETPreviousObj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/wet-previous${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160WETPrevious = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160WETPreviousObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-wet-previous${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160WETAdditional = async (
  payload: saveDS160WETAdditionalObj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/wet-additional${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160WETAdditional = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160WETAdditionalObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-wet-additional${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160SEVAdditionalContact = async (
  payload: saveDS160SEVAdditionalContactObj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/sev-additional-contact${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`,
    rest
  );
};

export const getDS160SEVAdditionalContact = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160SEVAdditionalContactObj>>(
    `/${
      applicationId || buid ? "business" : "user"
    }/get-sev-additional-contact${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160Sives = async (payload: saveDS160SivesObj) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/sevis${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getDS160Sives = async ({ applicationId, buid }: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160SivesObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-sevis${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`
  );
};

export const getDS160MaritalStatus = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160MaritalStatusObj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-ds160-marital-status${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160MaritalStatus = async (
  payload: saveDS160MaritalStatusObj
) => {
  const { applicationId, buid, ...rest } = payload;

  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/ds160-marital-status${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`,
    rest
  );
};

export const saveDS160SecurityBackgroundP1 = async (
  payload: saveDS160SecurityBackgroundP1Obj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/security-part-1${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP1 = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP1Obj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-security-part-1${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160SecurityBackgroundP2 = async (
  payload: saveDS160SecurityBackgroundP2Obj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/security-part-2${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP2 = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP2Obj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-security-part-2${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160SecurityBackgroundP3 = async (
  payload: saveDS160SecurityBackgroundP3Obj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/security-part-3${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP3 = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP3Obj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-security-part-3${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160SecurityBackgroundP4 = async (
  payload: saveDS160SecurityBackgroundP4Obj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/security-part-4${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP4 = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP4Obj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-security-part-4${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const saveDS160SecurityBackgroundP5 = async (
  payload: saveDS160SecurityBackgroundP5Obj
) => {
  const { applicationId, buid, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/security-part-5${applicationId ? `?applicationId=${applicationId}` : ""}${
      buid ? `?buid=${buid}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP5 = async ({
  applicationId,
  buid,
}: Ds160GetQuery) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP5Obj>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-security-part-5${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const getDS160Info = async (applicationId?: string) => {
  return await request.get<ApiResponse<GetDs160Obj>>(
    `${applicationId ? "business" : "user"}/get-ds-160-info${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160Eligibility = async (
  payload: DS160EligibilityPayload
) => {
  const { applicationId, ...rest } = payload;

  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds-160-eligibility${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160Eligibility = async (
  applicationId?: string,
  buid?: string
) => {
  return await request.get<ApiResponse<DS160EligibilityPayload>>(
    `/${applicationId || buid ? "business" : "user"}${
      buid ? `/anon` : ""
    }/get-ds-160-eligibility${
      applicationId ? `?applicationId=${applicationId}` : ""
    }${buid ? `?buid=${buid}` : ""}`
  );
};

export const payDS160 = async (payload: payDS160Data) => {
  const { applicationId, ...rest } = payload;

  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds160-payment${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160Form = async (applicationId?: string) => {
  return await request.get<ApiResponse>(
    `/${applicationId ? "business" : "user"}/get-ds-160-application${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const getDS160PaySuccess = async (
  payId: string,
  applicationId?: string
) => {
  return await request.get<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds160-payment-success/${payId}${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const getDS160PayFail = async (applicationId?: string) => {
  return await request.get<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds160-payment-cancel${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const uploadDS160Files = async (payload: UploadDS160FilesPayload) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/upload-ds160-files${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest.data
  );
};

export const getDS160Files = async (applicationId?: string) => {
  return await request.get<ApiResponse<GetFileDetailObj[]>>(
    `/${applicationId ? "business" : "user"}/get-upload-ds160-files${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const updateDS160Files = async (payload: UpdateDS160FilesPayload) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/update-ds160-files/${payload.id}${
      applicationId ? `` : `/${payload.formCategory}`
    }${applicationId ? `?applicationId=${applicationId}` : ""}`,
    rest.data
  );
};

export const saveDS160VistorEmail = async (
  payload: SaveDS160VistorEmailPayload
) => {
  return await request.post<ApiResponse>(`user/visitor-email`, payload);
};

export const getDS160Trigger = async (applicationId?: string) => {
  return await request.get(
    `/${applicationId ? "business" : "user"}/download-ds160-application${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};
