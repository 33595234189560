import Colors from "../../../styles/Colors";
import Constaint from "../layout/components/Constaint";
import styled from "styled-components";
import SEOMeta from "../components/SEOMeta";
import { WebPaths } from "../../../routes/data";
import { SVLogoSquare } from "../../../images";

const AiPolicy = () => {
  return (
    <>
      <SEOMeta
        title="AI Policy | SeamlessVisa"
        description="Explore SeamlessVisa's AI policy, which outlines how we use artificial intelligence to help improve your visa application process while ensuring privacy and security."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.AiPolicy}`}
        url={`https://www.seamlessvisa.com${WebPaths.AiPolicy}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="AI Policy | SeamlessVisa"
        twitterDescription="Explore SeamlessVisa's AI policy, which outlines how we use artificial intelligence to help improve your visa application process while ensuring privacy and security."
        twitterSite="@SeamlessVisa_"
      />

      <Wrapper>
        <Constaint>
          <div>
            <h1>Use of AI Tools Policy</h1>
            <h4>Last Updated August 18, 2024</h4>
            <p>
              This Use of Artificial Intelligence Tools Policy or Use of AI
              Tools Policy ('Policy') for SeamlessVisa Inc. (‘we’, ‘us’, or
              ‘our’) is part of our Terms of Use ('Legal Terms') and should
              therefore be read alongside our main{" "}
              <a
                href="https://app.termly.io/document/terms-of-service/52cfeaf3-bc98-4aee-8e68-ae9526870a50"
                target="_blank"
                rel="noreferrer"
                className="text-[#0076E9]"
              >
                Legal Terms
              </a>{" "}
              including our{" "}
              <a
                href="https://app.termly.io/document/acceptable-use/f015bdfa-e9e6-48b2-838c-f5f672992aa9"
                target="_blank"
                rel="noreferrer"
                className="text-[#0076E9]"
              >
                Acceptable Use Policy
              </a>
              . If you do not agree with these Legal Terms, please refrain from
              using our Services. Your continued use of our Services implies
              acceptance of these Legal Terms.
            </p>
            {/* <ul>
            <li>
              Refund requests must be submitted (a) within thirty (30) days of
              the date that the denial notice (USCIS Form I-797, Notice of
              Action) was issued by the USCIS, and (b) must include a copy of
              the USCIS denial notice stating the reason for the denial.
            </li>
            <li>
              Refunds will not be issued if your petition was denied because of
              your failure to attend your scheduled immigration interview or
              failure to provide a timely response to an RFE from USCIS. USCIS
              filing fees and other fees or taxes paid to government entities,
              or to other third parties with a role in processing your
              application order, are not refundable.
            </li>
            <li>
              Our Guarantee does not cover petitions denied because of your
              failure to follow SeamlessVisa’s directions; your provision of
              false, inaccurate, or incomplete information to SeamlessVisa; your
              failure to file or delay in filing a required form; or your
              failure to correct any errors in the forms generated by
              SeamlessVisa.
            </li>
          </ul> */}
            <p>
              We use Artificial Intelligence technologies (“AI”) to enhance
              certain services and improve user experience. Our use of AI may
              include, without limitation, customer service chatbots and other
              informational chat features or services (including, without
              limitation, Ask FiBi), personalized recommendations (including,
              without limitation, FiBi AI), automated data analysis, and
              predictive modeling. These tools are designed to enhance user
              experiences and to improve the efficiency of our Services.
              However, due to the constantly evolving nature of technology and
              AI, all AI features, and any AI-based or assisted services, are
              provided to user on an as-is basis without warranties of any kind,
              including, without limitation, any implied warranty of fitness for
              a particular purpose, non-infringement, or accuracy. User
              acknowledges that AI-generated information or services may contain
              errors and may not produce accurate output, and user understands
              and agrees that such content or services should be used solely as
              supplementary information and not as the sole basis for
              decision-making. We are not liable for any decisions made based on
              AI-generated content, AI features, or AI-based services and
              features, and we expressly disclaim all liability for all such
              content, including without limitation, for errors, inaccuracies,
              or misrepresentations produced by AI.
            </p>

            <p>
              We reserve the right to change, modify, remove or limit the AI
              features, tools and services at any time or for any reason at our
              sole discretion without notice. We disclaim liability to users or
              any third party for any modification, suspension, or
              discontinuance of the AI features, tools and services.{" "}
            </p>
          </div>
        </Constaint>
      </Wrapper>
    </>
  );
};

export default AiPolicy;

const Wrapper = styled.div`
  background: #d7e1f5;
  padding: 4rem 0;
  font-size: 26px;

  h1 {
    font-size: 32px;
    margin-top: 1.24rem;
    font-weight: bold;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    color: ${Colors.Black31};
    margin-bottom: 1.12rem;
  }

  p {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: ${Colors.Black31};
  }

  ul {
    list-style: disc;
    margin-bottom: 1.24rem;
  }
  li {
    margin-left: 1.54rem;
    font-size: 1rem;
    color: ${Colors.Black31};
  }
`;
