import React, { useEffect, useState } from "react";
import {
  TbLayoutSidebarRightCollapseFilled,
  TbLayoutSidebarRightExpandFilled,
} from "react-icons/tb";
import { useSearchParams } from "react-router-dom";
import { RiChatNewFill } from "react-icons/ri";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../../store/userData";
import { toast } from "react-toastify";
import { SessionQuery } from "./types";

// Define a union type for items that are either links or buttons.
interface SidebarItem {
  label: string;
  count: number;
}

interface SidebarProps {
  items: SidebarItem[];
  maxWidth?: number;
  collapsedWidth?: number;
  sessionCompleted: boolean;
  onInterviewOff: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  items,
  collapsedWidth = 50,
  maxWidth = 300,
  sessionCompleted,
  onInterviewOff,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionTemp = searchParams.get(SessionQuery.sessionCount);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { userData } = useSnapshot(userDataStore);
  const [maxSessionCount, setMaxSessionCount] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (userData) setMaxSessionCount(userData.maxSessionCount as number);
  }, [userData]);

  // Check if the device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Ensure sidebar is collapsed by default on mobile
  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [isMobile]);

  const toggleSidebar = () => setCollapsed((prev) => !prev);

  const onStartNewSession = () => {
    if (Number(items.length) >= maxSessionCount || !sessionCompleted) {
      toast.error(
        "You either have an active session or have reached the maximum number of allowed sessions"
      );
      return;
    }

    searchParams.set(SessionQuery.sessionCount, `${items.length + 1}`);
    setSearchParams(searchParams);
    onInterviewOff();

    // Collapse sidebar after starting a new session on mobile
    if (isMobile) {
      setCollapsed(true);
    }
  };

  return (
    <div
      className={`flex flex-col overflow-hidden transition-all duration-300 bg-blue-50 rounded-xl ${
        isMobile ? "fixed top-24 left-0 z-50" : ""
      }`}
      style={{ width: collapsed ? collapsedWidth : maxWidth }}
    >
      <div
        className={`p-2 text-white duration-150 focus:outline-none bg-primaryColor flex items-center justify-between w-full ${
          collapsed ? "flex-col gap-y-4" : "flex-row"
        }`}
      >
        <button
          onClick={toggleSidebar}
          aria-label={collapsed ? "Expand sidebar" : "Collapse sidebar"}
          className="rounded-lg cursor-pointer"
        >
          {collapsed ? (
            <TbLayoutSidebarRightCollapseFilled className="w-8 h-8 cursor-pointer" />
          ) : (
            <TbLayoutSidebarRightExpandFilled className="w-8 h-8" />
          )}
        </button>

        {!collapsed && (
          <button onClick={onStartNewSession} aria-label="Start new session">
            <RiChatNewFill className="w-8 h-8" />
          </button>
        )}
      </div>

      {!collapsed && (
        <div className="relative w-full h-full">
          <ul className="flex-grow px-2 py-10 overflow-y-auto max-h-screen">
            {items.length === 0 ? (
              <div className="text-sm text-center">
                You have no session history yet.
              </div>
            ) : (
              items.map((item, i) => {
                const { label } = item;
                const isActive = sessionTemp
                  ? item.count === +sessionTemp
                  : false;

                return (
                  <button
                    key={i}
                    onClick={() => {
                      searchParams.set(
                        SessionQuery.sessionCount,
                        `${item.count}`
                      );
                      setSearchParams(searchParams);

                      // Collapse sidebar after selecting a session on mobile
                      if (isMobile) {
                        setCollapsed(true);
                      }
                    }}
                    className={`block w-full text-left focus:outline-none py-2 px-2 rounded-xl ${
                      isActive ? "bg-gray-200" : "hover:bg-gray-200"
                    }`}
                  >
                    {label}
                  </button>
                );
              })
            )}
          </ul>

          {isMobile && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 -z-10"
              onClick={toggleSidebar}
              aria-hidden="true"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
