export enum MessageSender {
  user = "user",
  bot = "bot",
}

export interface Message {
  message: string;
  sender: MessageSender;
}

export enum SessionQuery {
  sessionCount = "sessionCount",
}
