import React, { FC, useCallback, useEffect, useState } from "react";
import UseTTSVisualizer from "./UseTTSVisualizer";
import ChatInterface from "./FiBiVoiceMessagesDisplay";
import SessionEnded from "./SessionEnded";
import FiBiVoiceBottomBar from "./FiBiVoiceBottomBar";
import { saveBusinessVoiceInterviewChatGpt } from "../../../../../../api/ai";
import { formatTime } from "./data";
import { aiQueryKeys } from "../../../../../../hooks/ai/types";
import useAllMessages from "./useAllMessages";
import {
  useGetBusinessChatGptInterviewVoiceHistory,
  useSaveBusinessInterviewTimeout,
} from "../../../../../../hooks/ai/useAI";
import useAudio from "./useAudio";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../../store/userData";
import { businessFibiClientStore } from "../../../../../../store/businessFibiClient";
import RequestWrapper from "../../../../components/RequestWrapper";
import { SessionQuery } from "./types";

interface Props {
  changeSessionCompleted: (second: boolean) => void;
}

const BusinessMain: FC<Props> = ({ changeSessionCompleted }) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionTemp = searchParams.get(SessionQuery.sessionCount);
  const { userData } = useSnapshot(userDataStore);
  const { fibiClientToken } = useSnapshot(businessFibiClientStore);
  const [sidebarItems, setSidebarItems] = useState<
    { label: string; count: number }[]
  >([]);

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isSmaller, setIsSmaller] = useState<boolean>(false);

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 500) setIsSmaller(true);
  }, []);

  const {
    data: historyData,
    isFetching: historyLoading,
    isError: historyIsError,
  } = useGetBusinessChatGptInterviewVoiceHistory(
    sessionTemp || "",
    fibiClientToken || ""
  );
  const { mutateAsync } = useSaveBusinessInterviewTimeout();
  const { audioRef, analyserRef, audioContextRef, mediaSourceRef } = useAudio();
  const { allMessages, onAddToChatHistory, onReset } = useAllMessages({
    historyLoading,
    historyData: historyData?.result,
  });

  const sessionCompleted = !!historyData?.sessionCompleted;

  useEffect(() => {
    changeSessionCompleted(sessionCompleted);
  }, [sessionTemp, changeSessionCompleted, sessionCompleted]);

  useEffect(() => {
    onReset();
  }, [onReset, sessionTemp]);

  useEffect(() => {
    if (!userData) return;

    const count = userData.interviewCount ?? 1;

    if (sessionTemp) {
      if (+sessionTemp <= count) return;
      const temp = new Array(+sessionTemp).fill(1).map((ev, index) => ({
        label: `Session ${index + 1}`,
        count: index + 1,
      }));

      setSidebarItems(temp);
    } else {
      searchParams.set(SessionQuery.sessionCount, `${count}`);
      setSearchParams(searchParams);

      const temp = new Array(count).fill(1).map((ev, index) => ({
        label: `Session ${index + 1}`,
        count: index + 1,
      }));

      setSidebarItems(temp);
    }
  }, [searchParams, sessionTemp, setSearchParams, userData]);

  useEffect(() => {
    if (!userData) return;

    const count = userData.interviewCount ?? 1;

    if (count > sidebarItems.length) {
      const temp = new Array(count).fill(1).map((ev, index) => ({
        label: `Session ${index + 1}`,
        count: index + 1,
      }));

      setSidebarItems(temp);
    }
  }, [sidebarItems.length, userData]);

  const onEnd = useCallback(async () => {
    if (!sessionTemp || !fibiClientToken) return;

    return await mutateAsync(
      {
        sessionCount: sessionTemp,
        businessId: fibiClientToken,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            aiQueryKeys.getBusinessChatGptInterviewVoiceHistory,
          ]);
        },
      }
    );
  }, [mutateAsync, sessionTemp, fibiClientToken, queryClient]);

  const requestFunc = useCallback(
    async (prompt: string) => {
      const storedEndTime = localStorage.getItem("countdownEndTime");

      if (!storedEndTime) return;

      const endTime: number = parseInt(storedEndTime, 10);

      if (!fibiClientToken || !sessionTemp) return;

      const timeLeft = Math.max(0, Math.floor((endTime - Date.now()) / 1000));

      return await saveBusinessVoiceInterviewChatGpt({
        id: fibiClientToken,
        sessionCount: +sessionTemp || 1,
        payload: {
          prompt,
          countDown: formatTime(timeLeft),
        },
      });
    },
    [fibiClientToken, sessionTemp]
  );

  return (
    <RequestWrapper
      isLoading={historyLoading}
      isError={historyIsError}
      data={true}
    >
      <>
        {allMessages && (
          <>
            <div className="flex flex-1 gap-x-6">
              <div className="lg:grid grid-cols-1 lg:grid-cols-[450px_1fr] gap-4 mt-5 w-full">
                {/* FIBI */}
                <div className="hidden w-full mb-4 overflow-hidden lg:mb-0 lg:block">
                  <div
                    className={`relative border rounded-2xl p-6 md:py-10 md:px-20 flex items-start justify-center gap-x-6 bg-[#E8F4FF]`}
                  >
                    <UseTTSVisualizer
                      width={isSmaller ? 200 : isMobile ? 300 : 500}
                      height={isSmaller ? 200 : isMobile ? 300 : 400}
                      // width={isMobile ? undefined : 500}
                      // height={isMobile ? undefined : 400}
                      mediaSource={mediaSourceRef.current}
                      audioContextRef={audioContextRef}
                      analyserRef={analyserRef}
                    />
                  </div>
                </div>

                {/* USER */}
                <div className="w-full h-full">
                  <ChatInterface
                    messages={allMessages}
                    isLoading={historyLoading}
                    isError={historyIsError}
                  />
                </div>
              </div>
            </div>
            <>
              {sessionCompleted && sessionTemp && fibiClientToken ? (
                <SessionEnded
                  messages={allMessages}
                  sessionTemp={sessionTemp}
                  fibiClientToken={fibiClientToken}
                />
              ) : (
                <FiBiVoiceBottomBar
                  businessProps={{
                    currentSession: `${sessionTemp}/${userData?.maxSessionCount} Session`,
                    onEnd,
                  }}
                  onAddToChatHistory={onAddToChatHistory}
                  allMessages={allMessages}
                  audioRef={audioRef}
                  audioContextRef={audioContextRef}
                  requestFunc={requestFunc}
                />
              )}
            </>
          </>
        )}
      </>
    </RequestWrapper>
  );
};

export default BusinessMain;
