import React, { useState } from "react";
import { useSaveFiBiInterviewRatings } from "../../../../../hooks/ai/useAI";
import storage from "../../../../../utils/storage";

// Define types for our props and state
type FeedbackRating = 1 | 2 | 3 | 4 | 5;
type RatingDisplayType = "emoji" | "stars";

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  // onSubmit: (rating: FeedbackRating, comment: string) => void;
  title?: string;
  description?: string;
  ratingType?: RatingDisplayType;
  submitButtonText?: string;
  sessionCount: number;
  businessId: string;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
  // onSubmit,
  title = "How are you feeling?",
  description = "Your input is valuable in helping us better understand your needs and tailor our service accordingly.",
  ratingType = "emoji",
  submitButtonText = "Submit Now",
  sessionCount,
  businessId,
}) => {
  const [rating, setRating] = useState<FeedbackRating | null>(null);
  const [comment, setComment] = useState<string>("");

  const { mutateAsync: saveFiBiInterviewRatings, isLoading } =
    useSaveFiBiInterviewRatings();

  if (!isOpen) return null;

  const handleSubmit = () => {
    if (rating) {
      saveFiBiInterviewRatings(
        { rating, comment, sessionCount, businessId },
        {
          onSuccess: () => {
            onClose();
            // Reset state after submission
            setRating(null);
            setComment("");
            storage.set(`@session_${sessionCount}_feedback`, true);
          },
        }
      );
    }
  };

  // Different emoji options based on rating
  const emojis = {
    1: "😣", // Very dissatisfied
    2: "😔", // Dissatisfied
    3: "😐", // Neutral
    4: "🙂", // Satisfied
    5: "🥰", // Very satisfied
  };

  // Get label for current rating
  const getRatingLabel = (value: FeedbackRating) => {
    const labels = {
      1: "Very Dissatisfied",
      2: "Dissatisfied",
      3: "Medium",
      4: "Satisfied",
      5: "Very Satisfied",
    };
    return labels[value];
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md p-6 mx-4 bg-white shadow-lg rounded-2xl">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center">
            <div className="p-2 mr-3 bg-gray-200 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <h2 className="text-xl font-bold">Feedback</h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="mb-8 text-center">
          <h3 className="mb-2 text-2xl font-bold">{title}</h3>
          <p className="text-gray-600">{description}</p>
        </div>

        <div className="flex justify-center mb-8">
          {[1, 2, 3, 4, 5].map((value) => (
            <button
              key={value}
              onClick={() => setRating(value as FeedbackRating)}
              className={`mx-2 h-12 w-12 flex items-center justify-center p-4 rounded-full transition-all ${
                rating === value
                  ? "bg-primaryColor/20 scale-110 transform ring-4 ring-primaryColor"
                  : "bg-gray-100 hover:bg-gray-200"
              }`}
            >
              {ratingType === "emoji" ? (
                <span className="text-2xl">
                  {emojis[value as FeedbackRating]}
                </span>
              ) : (
                <div className="flex">
                  <svg
                    className="w-6 h-6 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </div>
              )}
            </button>
          ))}
        </div>

        {rating && (
          <div className="mb-4 text-center">
            <span className="px-4 py-3 text-sm font-medium text-black rounded-full bg-primaryColor/40">
              {getRatingLabel(rating)}
            </span>
          </div>
        )}

        <div className="mb-6">
          <textarea
            placeholder="Add a Comment..."
            className="w-full p-3 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-primaryColor focus:border-transparent"
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>

        <button
          onClick={handleSubmit}
          disabled={!rating}
          className={`w-full py-3 px-4 rounded-lg text-white font-medium transition-colors ${
            rating
              ? " bg-primaryColor hover:bg-primaryColor/80"
              : "bg-gray-300 cursor-not-allowed"
          }`}
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <svg
                className="w-5 h-5 mr-3 -ml-1 animate-spin text-primaryColor"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            <span>{submitButtonText}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default FeedbackModal;
