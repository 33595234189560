import axios from "axios";
import { ApiResponse } from "../../types/types";
import request from "../request";
import { userDataResponse, countriesObj, GetGeoLocationObj } from "./types";

export const getCountries = async () => {
  return await request.get<ApiResponse<countriesObj[]>>(
    `/user/get-country-and-states`
  );
};

export const getStates = async (country?: string) => {
  return await request.get<ApiResponse<countriesObj>>(
    `/user/single-country-and-states/${country}`
  );
};

export const getUserData = async (businessId?: string) => {
  return await request.get<userDataResponse>(`/user/get-user-data`, {
    params: {
      id: businessId,
    },
  });
};

export const deleteForm = async (formCategory: string) => {
  return await request.delete<ApiResponse<string>>(
    `user/delete/${formCategory}`
  );
};

export const deleteAccount = async () => {
  return await request.delete<ApiResponse<string>>(`user/delete-account`);
};

export const getGeoLocation = async () => {
  return await axios.get<GetGeoLocationObj>(
    `https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location`
  );
};
