import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  businessBillingDowngrade,
  businessBillingUpgrade,
  businessCancelSubscription,
  businessSubscriptionFailure,
  businessSubscriptionStaffSeatFailure,
  businessSubscriptionStaffSeatSuccess,
  businessSubscriptionSuccess,
  choosePaymentMode,
  subscribeAdditionalStaffSeat,
  subscriptionPayment,
} from "../../api/business/billing";
import { parseError } from "../../utils";
import { businessAuthQueryKeys, businessBillingQueryKeys } from "./types";

export const useChoosePaymentMode = () => {
  const queryClient = useQueryClient();

  return useMutation(choosePaymentMode, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]),
  });
};

export const useSubscriptionPayment = () => {
  const queryClient = useQueryClient();

  return useMutation(subscriptionPayment, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]);
    },
  });
};

export const useBusinessSubscriptionSuccess = (
  session_id: string,
  isEnabled: boolean
) => {
  return useQuery(
    [businessBillingQueryKeys.businessSubscriptionSuccess, session_id],
    () => businessSubscriptionSuccess(session_id),
    {
      enabled: !!session_id && isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export const useBusinessSubscriptionFailure = (session_id: string) => {
  return useQuery(
    [businessBillingQueryKeys.businessSubscriptionFailure, session_id],
    () => businessSubscriptionFailure(session_id),
    {
      enabled: !!session_id,
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export const useSubscribeAdditionalStaffSeat = () => {
  const queryClient = useQueryClient();

  return useMutation(subscribeAdditionalStaffSeat, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]);
    },
  });
};

export const useBusinessSubscriptionStaffSeatSuccess = (
  session_id: string,
  isEnabled: boolean
) => {
  return useQuery(
    [businessBillingQueryKeys.businessSubscriptionStaffSeatSuccess, session_id],
    () => businessSubscriptionStaffSeatSuccess(session_id),
    {
      enabled: !!session_id && isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export const useBusinessSubscriptionStaffSeatFailure = (session_id: string) => {
  return useQuery(
    [businessBillingQueryKeys.businessSubscriptionStaffSeatFailure, session_id],
    () => businessSubscriptionStaffSeatFailure(session_id),
    {
      enabled: !!session_id,
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export const useBusinessBillingUpgrade = () => {
  const queryClient = useQueryClient();

  return useMutation(businessBillingUpgrade, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]);
    },
  });
};

export const useBusinessBillingDowngrade = () => {
  const queryClient = useQueryClient();

  return useMutation(businessBillingDowngrade, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]);
    },
  });
};

export const useBusinessCancelSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(businessCancelSubscription, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]);
    },
  });
};
