import React from "react";
import Hero from "./components/Hero";
import Steps from "./components/Steps";
import FAQ from "./components/FAQ";
import Contact from "./components/Contact";
import SEOMeta from "../components/SEOMeta";
import { WebPaths } from "../../../routes/data";
import { SVLogoSquare } from "../../../images";

const Eligibility = () => {
  return (
    <>
      <SEOMeta
        title="Check Your U.S. Visa Eligibility"
        description="Determine your eligibility for a U.S. visa using our easy-to-use tools and expert advice at SeamlessVisa."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.Eligibility}`}
        url={`https://www.seamlessvisa.com${WebPaths.Eligibility}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="Check Your U.S. Visa Eligibility"
        twitterDescription="Determine your eligibility for a U.S. visa using our easy-to-use tools and expert advice at SeamlessVisa."
        twitterSite="@SeamlessVisa_"
      />

      <Hero />
      <Steps />
      <FAQ />
      <Contact />
    </>
  );
};

export default Eligibility;
