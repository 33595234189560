import { useSnapshot } from "valtio";
import { eligibilityDataStore } from "../store/eligibilityData";
import { useSaveN565Eligibility } from "./n565/useN565";
import { useSaveN400Eligibility } from "./n400/useN400";
import { useSaveN600Eligibility } from "./n600/useN600";
import { useSaveDS160Eligibility } from "./ds160/usDS160";
import { FormCategories } from "../routes/type";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../api/utils/types";

const useFormsEligibility = () => {
  const { eligibilityData } = useSnapshot(eligibilityDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId);

  const {
    mutate: n565Mutate,
    isLoading: n565Loading,
    isError: n565Error,
  } = useSaveN565Eligibility();
  const {
    mutate: n400Mutate,
    isLoading: n400Loading,
    isError: n400Error,
  } = useSaveN400Eligibility();
  const {
    mutate: n600Mutate,
    isLoading: n600Loading,
    isError: n600Error,
  } = useSaveN600Eligibility();
  const {
    mutate: ds160Mutate,
    isLoading: ds160Loading,
    isError: ds160Error,
  } = useSaveDS160Eligibility();

  const formEligiblityLoading =
    n565Loading || n400Loading || n600Loading || ds160Loading;
  const formEligiblityError = n565Error || n400Error || n600Error || ds160Error;

  const onSaveFormEligibility = useCallback(
    (formName?: string) => {
      if (!formName || !eligibilityData.formData) return;

      if (formName === FormCategories.n565) {
        n565Mutate(eligibilityData.formData as any);
      }
      if (formName === FormCategories.n400) {
        n400Mutate(eligibilityData.formData as any);
      }
      if (formName === FormCategories.n600) {
        n600Mutate(eligibilityData.formData as any);
      }
      if (formName === FormCategories.ds160) {
        const tempload = {
          ...(eligibilityData.formData as any),
          applicationId: getApplicationId,
        };
        ds160Mutate(tempload);
      }
    },
    [
      ds160Mutate,
      n600Mutate,
      n400Mutate,
      n565Mutate,
      eligibilityData.formData,
      getApplicationId,
    ]
  );

  return {
    onSaveFormEligibility,
    formEligiblityLoading,
    formEligiblityError,
  };
};

export default useFormsEligibility;
