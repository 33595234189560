import { ApiResponse } from "../../../types/types";
import request from "../../request";
import {
  CreateBusinessUserPayload,
  EditBusinessUserPayload,
  GetBusinessUsersQueries,
  GetBusinessUsersRes,
  GetSingleBusinessUsersRes,
  ShareApplicationPayload,
} from "./types";

export const createBusinessUser = async (
  payload: CreateBusinessUserPayload
) => {
  return await request.post<ApiResponse>(`/business/create-user`, payload);
};

export const editBusinessUser = async (payload: EditBusinessUserPayload) => {
  const { userId, ...rest } = payload;

  return await request.post<ApiResponse>(`/business/edit-user/${userId}`, rest);
};

export const getBusinessUsers = async (queries: GetBusinessUsersQueries) => {
  const { limit, page, email } = queries;
  let payload = {};

  if (limit) {
    payload = {
      ...payload,
      limit,
    };
  }

  if (page) {
    payload = {
      ...payload,
      page,
    };
  }

  if (email) {
    payload = {
      ...payload,
      email,
    };
  }

  return await request.get<GetBusinessUsersRes>(`/business/get-users`, {
    params: {
      ...payload,
    },
  });
};

export const getSingleBusinessUsers = async (userId: string) => {
  return await request.get<ApiResponse<GetSingleBusinessUsersRes>>(
    `/business/get-user/${userId}`
  );
};

export const shareApplication = async (payload: ShareApplicationPayload) => {
  return await request.post<ApiResponse>(
    `/business/generate-anon-access`,
    payload
  );
};

export const shareFibiInvite = async (businessUserID: string) => {
  return await request.get<ApiResponse>(
    `/business/share-fibi-url/${businessUserID}`
  );
};
