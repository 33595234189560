import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SignUpType } from "./SignUp";
import { AxiosResponse } from "axios";
import { SignInRes } from "../../../../api/auth/types";
import { BusinessSignInRes } from "../../../../api/business/auth/types";
import { businessUserDataStore } from "../../../../store/businessUserData";
import storage from "../../../../utils/storage";
import {
  isBusinessDataStore,
  seamlessIsBusiness,
} from "../../../../store/isBusinessData";
import { userDataStore } from "../../../../store/userData";
import {
  accessTokenStore,
  seamlessAccessToken,
  seamlessRefreshToken,
} from "../../../../store/accessToken";
import Modal from "../../components/Modal";

const useLoginRes = () => {
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("type") as SignUpType;
  const [isVerified, setIsVerified] = useState<boolean>(true);
  const isBusinessType = getType === SignUpType.business;

  const onLoginSuccess = useCallback(
    (data: AxiosResponse<SignInRes | BusinessSignInRes, any>) => {
      if (data.status === 206) {
        setIsVerified(false);
        return;
      }
      //store in store
      if (isBusinessType) {
        businessUserDataStore.businessUserData = (
          data.data as BusinessSignInRes
        ).businessData;
        storage.set(seamlessIsBusiness, true);
        isBusinessDataStore.isBusinessData = true;
      } else {
        storage.set(seamlessIsBusiness, false);
        isBusinessDataStore.isBusinessData = false;
        userDataStore.userData = (data.data as SignInRes).userData;
      }

      const accessToken = data.data.accessToken;
      const refreshToken = data.data.refreshToken;
      //store in store
      accessTokenStore.accessToken = accessToken;
      accessTokenStore.refreshToken = refreshToken;
      //store in local storage
      storage.set(seamlessAccessToken, accessToken);
      storage.set(seamlessRefreshToken, refreshToken);
    },
    [isBusinessType]
  );

  let notVerifiedModal = <></>;

  if (!isVerified) {
    notVerifiedModal = <Modal onClose={() => setIsVerified(!isVerified)} />;
  }

  return { onLoginSuccess, notVerifiedModal };
};

export default useLoginRes;
