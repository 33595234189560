import React from "react";
import { ArrayWrapper, GroupWrapper, SubTitle, Wrapper } from "../styles";
import { FieldArray, Form, Formik } from "formik";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Modify, SingleOption } from "../../../../../types/types";
import { Dayjs } from "dayjs";
import { FormWrapper } from "../../../components/styles";
import SelectField from "../../../components/formik fields/SelectField";
import {
  ExplicitNGDateFormat,
  SpecialSeparator,
  ToSaveDate,
  formatMultiStates,
  initDate,
  initSelect,
  resetDynamicFields,
  saveDynamicCheck,
} from "../../../components/data";
import {
  DS160MaritalStatusData,
  DS160MaritalStatusValues,
  PersonAddressOptions,
  PersonAddressOptionsValues,
  processReviewData,
} from "../data";
import InputField from "../../../components/formik fields/InputField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import useCountries from "../../../components/useCountries";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { MaritalStatusValidation } from "../validation";
import useToNextSection from "../../../../../hooks/useToNextSection";
import {
  useGetDS160MaritalStatus,
  useSaveDS160MaritalStatus,
} from "../../../../../hooks/ds160/usDS160";
import FormBtn from "../../../components/FormBtn";
import { Title } from "../../../form 400/components/styles";
import useAiReview from "../useAiReview";
import { ReviewFamilySectionB } from "../Review/components/data";
import {
  BusinessApplicationId,
  BusinessQueryKey,
  ClentAnonId,
} from "../../../../../api/utils/types";
import { useSearchParams } from "react-router-dom";
import { isAnonClientDataStore } from "../../../../../store/isAnonClientData";
import { useSnapshot } from "valtio";
import GroupedSection from "../GroupedSection";
import AddAnotherBtn from "../AddAnotherBtn";
import useMultiStatesByCountry from "../../../form 400/components/useMultiStatesByCountry";

export interface saveDS160MaritalStatusObj
  extends BusinessApplicationId,
    ClentAnonId {
  maritalStatus: string;
  personSurname: string;
  personGivenName: string;
  personDateOfBirth: string;
  personCountry: string;
  personCity: string;
  personCityDoNotKnow: string; // add to change from boolean to string because it is an array now
  personBirthCountry: string;
  personAddress: string;
  personAddressStreetAddressLine1: string;
  personAddressStreetAddressLine2: string;
  personAddressCity: string;
  personAddressState: string;
  personAddressZipCode: string;
  personAddressCountry: string;
  doesNotApplyPersonAddressState: string; // add to change from boolean to string because it is an array now
  doesNotApplyPersonAddressZipCode: string; // add to change from boolean to string because it is an array now
  //
  divorcedDateOfMarriage: string;
  divorcedDateOfMarriageEnded: string;
  divorcedHowMarriageEnded: string;
  divorcedCountry: string;
}

const initialMaritalStatusArray = {
  personDateOfBirth: null,
  personCountry: null,
  personAddress: null,
  personBirthCountry: null,
  personAddressState: null,
  personAddressCountry: null,
  //
  personSurname: "",
  personGivenName: "",
  personCity: "",
  personCityDoNotKnow: false,
  personAddressStreetAddressLine1: "",
  personAddressStreetAddressLine2: "",
  personAddressCity: "",
  personAddressZipCode: "",
  doesNotApplyPersonAddressState: false,
  doesNotApplyPersonAddressZipCode: false,
  //
  divorcedDateOfMarriage: null,
  divorcedDateOfMarriageEnded: null,
  divorcedHowMarriageEnded: "",
  divorcedCountry: null,
};

export interface maritalStatusArrayPropsPayload {
  personDateOfBirth: Dayjs | null;
  personCountry: SingleOption | null;
  personAddress: SingleOption | null;
  personBirthCountry: SingleOption | null;
  personAddressState: SingleOption | null;
  personAddressCountry: SingleOption | null;
  //
  personSurname: string;
  personGivenName: string;
  personCity: string;
  personCityDoNotKnow: boolean;
  personAddressStreetAddressLine1: string;
  personAddressStreetAddressLine2: string;
  personAddressCity: string;
  personAddressZipCode: string;
  doesNotApplyPersonAddressState: boolean;
  doesNotApplyPersonAddressZipCode: boolean;
  //
  divorcedDateOfMarriage: Dayjs | null;
  divorcedDateOfMarriageEnded: Dayjs | null;
  divorcedHowMarriageEnded: string;
  divorcedCountry: SingleOption | null;
}

export interface MaritalStatusFormData
  extends Modify<
    Omit<
      saveDS160MaritalStatusObj,
      | "personDateOfBirth"
      | "personCountry"
      | "personAddress"
      | "personBirthCountry"
      | "personAddressState"
      | "personAddressCountry"
      | "personSurname"
      | "personGivenName"
      | "personCity"
      | "personCityDoNotKnow"
      | "personAddressStreetAddressLine1"
      | "personAddressStreetAddressLine2"
      | "personAddressCity"
      | "personAddressZipCode"
      | "doesNotApplyPersonAddressState"
      | "doesNotApplyPersonAddressZipCode"
      | "divorcedDateOfMarriage"
      | "divorcedDateOfMarriageEnded"
      | "divorcedHowMarriageEnded"
      | "divorcedCountry"
    >,
    {
      maritalStatus: SingleOption | null;
      maritalStatusArray: maritalStatusArrayPropsPayload[];
    }
  > {}

const MaritalStatus = () => {
  const { toNextSection } = useToNextSection();
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160MaritalStatus({
    applicationId: getApplicationId,
    buid: isAnonClientData,
  });
  const { statesArray: personAddressStateArray } = useMultiStatesByCountry(
    data?.personAddressCountry
  );
  const { mutate, isLoading: isSaving } = useSaveDS160MaritalStatus();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const { formattedCountryData, countryLoading } = useCountries();

  // const {
  //   formattedCountryData: personPayingCountry,
  //   countryLoading: personCountryLoading,
  // } = useCountries();
  const {
    formattedStateData: personAddressState,
    stateLoading: personAddressStateLoading,
    selectedCountry: personAddressSelectedCountry,
    setCountry: personAddressSetCountry,
  } = useStatesByCountry(data?.personAddressCountry);

  const savedMaritalStatusArray = () => {
    if (data?.maritalStatus) {
      const personSurnameData = data?.personSurname
        .split(SpecialSeparator)
        .map((ev) => ({ personSurname: ev }));

      const personGivenNameData = data?.personGivenName
        .split(SpecialSeparator)
        .map((ev) => ({ personGivenName: ev }));

      const personDateOfBirthData = data?.personDateOfBirth
        .split(SpecialSeparator)
        .map((ev) => ({
          personDateOfBirth: initDate(ev, ExplicitNGDateFormat),
        }));

      // permanent patch for now
      const personCountryData = data?.personCountry
        .split(SpecialSeparator)
        .map((ev) => ({
          personCountry: { value: ev, label: ev },
        }));

      const personCityData = data?.personCity
        .split(SpecialSeparator)
        .map((ev) => ({ personCity: ev }));

      const personCityDoNotKnowData = data?.personCityDoNotKnow
        .split(SpecialSeparator)
        .map((ev) => ({
          personCityDoNotKnow: saveDynamicCheck(ev),
        }));

      const personBirthCountryData = data?.personBirthCountry
        .split(SpecialSeparator)
        .map((ev) => ({
          personBirthCountry: { value: ev, label: ev },
        }));

      const personAddressData = data?.personAddress
        .split(SpecialSeparator)
        .map((ev) => ({
          personAddress: { value: ev, label: ev },
        }));

      const personAddressStreetAddressLine1Data =
        data?.personAddressStreetAddressLine1
          .split(SpecialSeparator)
          .map((ev) => ({ personAddressStreetAddressLine1: ev }));

      const personAddressStreetAddressLine2Data =
        data?.personAddressStreetAddressLine2
          .split(SpecialSeparator)
          .map((ev) => ({ personAddressStreetAddressLine2: ev }));

      const personAddressCityData = data?.personAddressCity
        .split(SpecialSeparator)
        .map((ev) => ({ personAddressCity: ev }));

      // permanent patch for now
      const personAddressStateData = formatMultiStates({
        dataKey: data?.personAddressState,
        fieldKey: "personAddressState",
        dataArray: personAddressStateArray,
      }) as { personAddressState: SingleOption }[];

      const personAddressZipCodeData = data?.personAddressZipCode
        .split(SpecialSeparator)
        .map((ev) => ({ personAddressZipCode: ev }));

      const personAddressCountryData = data?.personAddressCountry
        .split(SpecialSeparator)
        .map((ev) => ({
          personAddressCountry: { value: ev, label: ev },
        }));

      const doesNotApplyPersonAddressStateData =
        data?.doesNotApplyPersonAddressState
          .split(SpecialSeparator)
          .map((ev) => ({
            doesNotApplyPersonAddressState: saveDynamicCheck(ev),
          }));

      const doesNotApplyPersonAddressZipCodeData =
        data?.doesNotApplyPersonAddressZipCode
          .split(SpecialSeparator)
          .map((ev) => ({
            doesNotApplyPersonAddressZipCode: saveDynamicCheck(ev),
          }));

      //
      const divorcedDateOfMarriageData = data?.divorcedDateOfMarriage
        .split(SpecialSeparator)
        .map((ev) => ({
          divorcedDateOfMarriage: initDate(ev, ExplicitNGDateFormat),
        }));

      const divorcedDateOfMarriageEndedData = data?.divorcedDateOfMarriageEnded
        .split(SpecialSeparator)
        .map((ev) => ({
          divorcedDateOfMarriageEnded: initDate(ev, ExplicitNGDateFormat),
        }));

      const divorcedHowMarriageEndedData = data?.divorcedHowMarriageEnded
        .split(SpecialSeparator)
        .map((ev) => ({ divorcedHowMarriageEnded: ev }));

      const divorcedCountryData = data?.divorcedCountry
        .split(SpecialSeparator)
        .map((ev) => ({
          divorcedCountry: { value: ev, label: ev },
        }));

      return personSurnameData.map((ev, i) => ({
        ...ev,
        ...personGivenNameData[i],
        ...personDateOfBirthData[i],
        ...personCountryData[i],
        ...personCityData[i],
        ...personCityDoNotKnowData[i],
        ...personBirthCountryData[i],
        ...personAddressData[i],
        ...personAddressStreetAddressLine1Data[i],
        ...personAddressStreetAddressLine2Data[i],
        ...personAddressCityData[i],
        ...personAddressStateData[i],
        ...personAddressZipCodeData[i],
        ...personAddressCountryData[i],
        ...doesNotApplyPersonAddressStateData[i],
        ...doesNotApplyPersonAddressZipCodeData[i],
        ...divorcedDateOfMarriageData[i],
        ...divorcedDateOfMarriageEndedData[i],
        ...divorcedHowMarriageEndedData[i],
        ...divorcedCountryData[i],
      }));
    } else return [initialMaritalStatusArray];
  };

  const initialValues: MaritalStatusFormData = {
    maritalStatus: initSelect(DS160MaritalStatusData, data?.maritalStatus),
    maritalStatusArray: savedMaritalStatusArray(),
  };

  const onSubmit = (values: MaritalStatusFormData) => {
    const { maritalStatusArray, maritalStatus } = values;

    const transformedData = maritalStatusArray.map((ev) => {
      const isNotOtherAddress =
        ev.personAddress?.value !== PersonAddressOptionsValues.other;

      const toBeClear =
        maritalStatus?.value === DS160MaritalStatusValues.Widowed ||
        maritalStatus?.value === DS160MaritalStatusValues.Divorced;

      return {
        personAddress: toBeClear ? "" : ev.personAddress?.value || "",
        personAddressStreetAddressLine1:
          toBeClear || isNotOtherAddress
            ? ""
            : ev.personAddressStreetAddressLine1,
        personAddressStreetAddressLine2:
          toBeClear || isNotOtherAddress
            ? ""
            : ev.personAddressStreetAddressLine2,
        personAddressCity:
          toBeClear || isNotOtherAddress ? "" : ev.personAddressCity,
        personAddressState:
          toBeClear || isNotOtherAddress
            ? ""
            : ev.personAddressState?.value || "",
        personAddressZipCode:
          toBeClear || isNotOtherAddress ? "" : ev.personAddressZipCode,
        personAddressCountry:
          toBeClear || isNotOtherAddress
            ? ""
            : ev.personAddressCountry?.value || "",
        doesNotApplyPersonAddressState:
          toBeClear || isNotOtherAddress
            ? ""
            : ev.doesNotApplyPersonAddressState,
        doesNotApplyPersonAddressZipCode:
          toBeClear || isNotOtherAddress
            ? ""
            : ev.doesNotApplyPersonAddressZipCode,
      };
    });

    let payload = {
      maritalStatus: maritalStatus?.value || "",
      personSurname: maritalStatusArray
        .map((ev) => ev.personSurname)
        .join(SpecialSeparator),

      personGivenName: maritalStatusArray
        .map((ev) => ev.personGivenName)
        .join(SpecialSeparator),

      personDateOfBirth: maritalStatusArray
        .map((ev) => ToSaveDate(ev.personDateOfBirth, ExplicitNGDateFormat))
        .join(SpecialSeparator),

      personCountry: maritalStatusArray
        .map((ev) => ev.personCountry?.value || "")
        .join(SpecialSeparator),

      personCity: maritalStatusArray
        .map((ev) => ev.personCity)
        .join(SpecialSeparator),

      personCityDoNotKnow: maritalStatusArray
        .map((ev) => ev.personCityDoNotKnow)
        .join(SpecialSeparator),

      personBirthCountry: maritalStatusArray
        .map((ev) => ev.personBirthCountry?.value || "")
        .join(SpecialSeparator),

      personAddress: transformedData
        .map((ev) => ev.personAddress)
        .join(SpecialSeparator),
      personAddressStreetAddressLine1: transformedData
        .map((ev) => ev.personAddressStreetAddressLine1)
        .join(SpecialSeparator),
      personAddressStreetAddressLine2: transformedData
        .map((ev) => ev.personAddressStreetAddressLine2)
        .join(SpecialSeparator),
      personAddressCity: transformedData
        .map((ev) => ev.personAddressCity)
        .join(SpecialSeparator),
      personAddressState: transformedData
        .map((ev) => ev.personAddressState)
        .join(SpecialSeparator),
      personAddressZipCode: transformedData
        .map((ev) => ev.personAddressZipCode)
        .join(SpecialSeparator),
      personAddressCountry: transformedData
        .map((ev) => ev.personAddressCountry)
        .join(SpecialSeparator),
      doesNotApplyPersonAddressState: transformedData
        .map((ev) => ev.doesNotApplyPersonAddressState)
        .join(SpecialSeparator),
      doesNotApplyPersonAddressZipCode: transformedData
        .map((ev) => ev.doesNotApplyPersonAddressZipCode)
        .join(SpecialSeparator),

      //
      divorcedDateOfMarriage: maritalStatusArray
        .map((ev) =>
          ToSaveDate(ev.divorcedDateOfMarriage, ExplicitNGDateFormat)
        )
        .join(SpecialSeparator),

      divorcedDateOfMarriageEnded: maritalStatusArray
        .map((ev) =>
          ToSaveDate(ev.divorcedDateOfMarriageEnded, ExplicitNGDateFormat)
        )
        .join(SpecialSeparator),

      divorcedHowMarriageEnded: maritalStatusArray
        .map((ev) => ev.divorcedHowMarriageEnded)
        .join(SpecialSeparator),

      divorcedCountry: maritalStatusArray
        .map((ev) => ev.divorcedCountry?.value || "")
        .join(SpecialSeparator),
    };

    if (maritalStatus?.value !== DS160MaritalStatusValues.Divorced) {
      payload = {
        ...payload,
        divorcedDateOfMarriage: "",
        divorcedDateOfMarriageEnded: "",
        divorcedHowMarriageEnded: "",
        divorcedCountry: "",
      };
    }

    if (maritalStatus?.value === DS160MaritalStatusValues.Single) {
      payload = {
        ...payload,
        personBirthCountry: "",
        personCity: "",
        personCityDoNotKnow: "",
        personCountry: "",
        personDateOfBirth: "",
        personGivenName: "",
        personSurname: "",
      };
    }

    const section = ReviewFamilySectionB(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      family: {
        ...riskData?.family,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId, buid: isAnonClientData },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || (!isAnonClientData && riskLoading)}
      isError={isError || (!isAnonClientData && riskError)}
    >
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={MaritalStatusValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <SelectField
                  name="maritalStatus"
                  label="Marital status"
                  placeholder="-Select-"
                  options={DS160MaritalStatusData}
                />
                <div></div>
              </FormWrapper>
              {formik.values.maritalStatus?.value &&
                formik.values.maritalStatus?.value !==
                  DS160MaritalStatusValues.Single && (
                  <GroupWrapper>
                    <FieldArray
                      name="maritalStatusArray"
                      render={(arrayHelpers) => (
                        <ArrayWrapper>
                          {formik.values.maritalStatusArray.map((_, index) => (
                            <GroupedSection
                              key={index}
                              index={index}
                              onCancel={() => arrayHelpers.remove(index)}
                            >
                              <>
                                <Title>
                                  Provide the following information on partner
                                </Title>
                                <FormWrapper>
                                  <InputField
                                    label="Surnames"
                                    placeholder=""
                                    name={`maritalStatusArray[${index}].personSurname`}
                                  />
                                  <InputField
                                    label="Given Names"
                                    placeholder=""
                                    name={`maritalStatusArray[${index}].personGivenName`}
                                  />
                                </FormWrapper>

                                <FormWrapper>
                                  <DatePickerField
                                    label="Date of birth"
                                    placeholder={ExplicitNGDateFormat}
                                    disableFuture={true}
                                    name={`maritalStatusArray[${index}].personDateOfBirth`}
                                  />
                                  <SelectField
                                    name={`maritalStatusArray[${index}].personCountry`}
                                    label="Country/Region of Origin (Nationality)"
                                    placeholder="-Select-"
                                    options={formattedCountryData}
                                    isLoading={countryLoading}
                                  />
                                </FormWrapper>

                                <SubTitle>Place of Birth</SubTitle>

                                <FormWrapper>
                                  <div>
                                    <InputField
                                      label="City"
                                      placeholder=""
                                      name={`maritalStatusArray[${index}].personCity`}
                                      isDisabled={
                                        !!formik.values.maritalStatusArray[
                                          index
                                        ].personCityDoNotKnow
                                      }
                                    />

                                    <CheckBoxField
                                      label={`Does not know`}
                                      name={`maritalStatusArray[${index}].personCityDoNotKnow`}
                                      onChange={() => {
                                        resetDynamicFields(formik, [
                                          {
                                            name: `maritalStatusArray[${index}].personCity`,
                                            value: "",
                                          },
                                        ]);
                                      }}
                                    />
                                  </div>

                                  <SelectField
                                    name={`maritalStatusArray[${index}].personBirthCountry`}
                                    label="Birth Country"
                                    placeholder="-Select-"
                                    options={formattedCountryData}
                                    isLoading={countryLoading}
                                  />
                                </FormWrapper>

                                {formik.values.maritalStatus?.value &&
                                  formik.values.maritalStatus?.value ===
                                    DS160MaritalStatusValues.Divorced && (
                                    <>
                                      <FormWrapper>
                                        <DatePickerField
                                          name={`maritalStatusArray[${index}].divorcedDateOfMarriage`}
                                          label="Date of Marriage"
                                          placeholder={ExplicitNGDateFormat}
                                          disableFuture={true}
                                        />
                                        <DatePickerField
                                          name={`maritalStatusArray[${index}].divorcedDateOfMarriageEnded`}
                                          label="Date of Marriage Ended"
                                          placeholder={ExplicitNGDateFormat}
                                          disableFuture={true}
                                        />
                                      </FormWrapper>

                                      <FormWrapper>
                                        <InputField
                                          name={`maritalStatusArray[${index}].divorcedHowMarriageEnded`}
                                          label="How Marriage Ended"
                                          placeholder=""
                                          inputType="text-area"
                                        />
                                        <SelectField
                                          name={`maritalStatusArray[${index}].divorcedCountry`}
                                          label="Country/Region Marriage was Terminated"
                                          placeholder="-Select-"
                                          options={formattedCountryData}
                                          isLoading={countryLoading}
                                        />
                                      </FormWrapper>
                                    </>
                                  )}

                                {formik.values.maritalStatus?.value &&
                                  formik.values.maritalStatus?.value !==
                                    DS160MaritalStatusValues.Widowed &&
                                  formik.values.maritalStatus?.value !==
                                    DS160MaritalStatusValues.Divorced && (
                                    <>
                                      <FormWrapper>
                                        <SelectField
                                          name={`maritalStatusArray[${index}].personAddress`}
                                          label="Address"
                                          placeholder="-Select-"
                                          options={PersonAddressOptions}
                                        />

                                        <div></div>
                                      </FormWrapper>
                                      {/*
                                       */}

                                      {formik.values.maritalStatusArray[index]
                                        .personAddress?.value ===
                                        PersonAddressOptionsValues.other && (
                                        <>
                                          <FormWrapper>
                                            <InputField
                                              label="Street Address (Line 1)"
                                              placeholder=""
                                              name={`maritalStatusArray[${index}].personAddressStreetAddressLine1`}
                                            />
                                            <InputField
                                              label="Street Address (Line 2)"
                                              coloredLabel="*Optional"
                                              placeholder=""
                                              name={`maritalStatusArray[${index}].personAddressStreetAddressLine2`}
                                            />
                                          </FormWrapper>

                                          <FormWrapper>
                                            <InputField
                                              label="City"
                                              placeholder=""
                                              name={`maritalStatusArray[${index}].personAddressCity`}
                                            />

                                            <div></div>
                                          </FormWrapper>

                                          <FormWrapper>
                                            <SelectField
                                              name={`maritalStatusArray[${index}].personAddressCountry`}
                                              label="Country"
                                              options={formattedCountryData}
                                              isLoading={countryLoading}
                                              onChange={(value) => {
                                                personAddressSetCountry(
                                                  value.label
                                                );
                                                formik.setFieldValue(
                                                  `maritalStatusArray[${index}].personAddressState`,
                                                  null
                                                );
                                              }}
                                            />

                                            <div className="flex flex-col gap-2 ">
                                              <SelectField
                                                name={`maritalStatusArray[${index}].personAddressState`}
                                                label="State"
                                                placeholder="-Select-"
                                                options={personAddressState}
                                                isLoading={
                                                  personAddressStateLoading
                                                }
                                                isDisabled={
                                                  !personAddressSelectedCountry ||
                                                  !!formik.values
                                                    .maritalStatusArray[index]
                                                    .doesNotApplyPersonAddressState
                                                }
                                              />

                                              <CheckBoxField
                                                name={`maritalStatusArray[${index}].doesNotApplyPersonAddressState`}
                                                label="Does not apply"
                                                onChange={() => {
                                                  resetDynamicFields(formik, [
                                                    {
                                                      name: `maritalStatusArray[${index}].personAddressState`,
                                                      value: null,
                                                    },
                                                  ]);
                                                }}
                                              />
                                            </div>
                                          </FormWrapper>

                                          <FormWrapper>
                                            <div className="flex flex-col gap-2 ">
                                              <InputField
                                                label="Postal Zone/ZIP Code"
                                                placeholder=""
                                                name={`maritalStatusArray[${index}].personAddressZipCode`}
                                                isDisabled={
                                                  !!formik.values
                                                    .maritalStatusArray[index]
                                                    .doesNotApplyPersonAddressZipCode
                                                }
                                              />

                                              <CheckBoxField
                                                name={`maritalStatusArray[${index}].doesNotApplyPersonAddressZipCode`}
                                                label="Does not apply"
                                                onChange={() => {
                                                  resetDynamicFields(formik, [
                                                    {
                                                      name: `maritalStatusArray[${index}].personAddressZipCode`,
                                                      value: "",
                                                    },
                                                  ]);
                                                }}
                                              />
                                            </div>
                                            <div></div>
                                          </FormWrapper>
                                        </>
                                      )}

                                      {/*  */}
                                    </>
                                  )}
                              </>
                            </GroupedSection>
                          ))}

                          {formik.values.maritalStatus?.value &&
                            formik.values.maritalStatus?.value ===
                              DS160MaritalStatusValues.Divorced && (
                              <AddAnotherBtn
                                onClick={() =>
                                  arrayHelpers.push(initialMaritalStatusArray)
                                }
                              />
                            )}
                        </ArrayWrapper>
                      )}
                    />
                  </GroupWrapper>
                )}

              <FormBtn
                isLoading={isSaving || riskSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default MaritalStatus;
