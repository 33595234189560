import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import {
  filterPagesByRole,
  ProfileLeftSideBarDataProps,
  leftSideBarData as sideBarData,
} from "./data";
import Colors from "../../../../styles/Colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userDataStore } from "../../../../store/userData";
import { useSnapshot } from "valtio";
import { isBusinessDataStore } from "../../../../store/isBusinessData";
import { businessUserDataStore } from "../../../../store/businessUserData";
import { ProfileSections } from "./types";
import { mediaObj } from "../../../../styles/Media";
import { IoChevronForward, IoClose } from "react-icons/io5";

interface Props {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const LeftSideBar: FC<Props> = ({ open, onClose, onOpen }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("active");
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { businessUserData } = useSnapshot(businessUserDataStore);
  const { userData } = useSnapshot(userDataStore);
  const { role } = useSnapshot(isBusinessDataStore);
  const [leftSideBarData, setLeftSideBarData] = useState<
    ProfileLeftSideBarDataProps[]
  >([]);

  useEffect(() => {
    if (!isBusinessData) {
      setLeftSideBarData(sideBarData(isBusinessData));
      return;
    }
    setLeftSideBarData(
      filterPagesByRole(
        sideBarData(
          isBusinessData
          // !!businessUserData?.paymentInfo?.isSubscriptionMode
        ),
        role
      )
    );
  }, [businessUserData?.paymentInfo?.isSubscriptionMode, isBusinessData, role]);

  return (
    <Container open={open}>
      <Toggle isOpen={open} onClick={open ? onClose : onOpen}>
        {open ? <IoClose /> : <IoChevronForward />}
      </Toggle>
      <Top>
        Hello,{" "}
        {isBusinessData
          ? businessUserData?.businessInfo.name
          : userData?.userInfo?.name.split(" ")[0]}
        .
      </Top>
      <Bottom>
        {leftSideBarData.map((ev, i) => {
          const urlSplit = ev.url?.split("=")[1];

          // for the business side if document is active, make case management active
          const isActive =
            isBusinessData &&
            getType === ProfileSections.documents &&
            urlSplit === ProfileSections.caseManagement
              ? true
              : getType === urlSplit;

          return (
            <React.Fragment key={i}>
              <Nav
                key={i}
                onClick={() => {
                  ev.url && navigate(ev.url);
                  open && onClose();
                }}
                isActive={isActive}
              >
                <Circle isActive={isActive}>{ev.icon}</Circle>
                <Label isActive={isActive}>{ev.label}</Label>
              </Nav>
            </React.Fragment>
          );
        })}
      </Bottom>
    </Container>
  );
};

export default LeftSideBar;

interface ContainerProps {
  open: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: #f9fcff;

  ${mediaObj.smallDesktop} {
    width: 270px;
    height: 100%;
    position: absolute;
    left: 0;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    transition: all 0.3s ease-in-out;
    z-index: 10;
  }
`;

const Top = styled.div`
  padding: 29px 11px;
  background-color: #0076e9;
  margin-bottom: 24px;
  border-radius: 12px 12px 0 0;
  color: ${Colors.White};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  text-transform: capitalize;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-bottom: 2rem;
`;

interface NavProps {
  isActive: boolean;
}

const Nav = styled.div<NavProps>`
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? "#0076E914" : "transparent"};
  border-left: ${({ isActive }) =>
    isActive ? `4px solid ${Colors.Blue00} ` : ""};
  padding: 11px 17px;
`;

interface CircleProps {
  isActive: boolean;
}

const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 20%;
  width: 20px;
  height: 20px;
  color: ${({ isActive }) => (isActive ? Colors.Blue00 : Colors.GreyA7)};

  &:hover {
    transition: 0.2s ease-out;
  }
`;

interface LabelProps {
  isActive: boolean;
}

const Label = styled.div<LabelProps>`
  color: ${({ isActive }) => (isActive ? Colors.Blue00 : Colors.GreyA7)};
`;

const Toggle = styled.button<{ isOpen: boolean }>`
  width: 64px;
  border-radius: 0 8px 8px 0;

  padding: 0.6rem;

  background-color: ${Colors.Blue00};

  display: grid;
  place-content: center;

  position: fixed !important;
  left: 100%;
  top: 24px;

  opacity: ${({ isOpen }) => (isOpen ? "1" : "0.5")};

  transition: 300ms all ease;

  &:hover {
    opacity: 1;
  }

  & > svg {
    height: 32px;
    width: 32px;

    transition: 300ms all ease;

    color: white;
  }
`;
