import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./contexts/Auth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient();

// ccol

const app = (
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={
        "301290535971-nrvbt9qppk1sb7gd4lahembo2cfolh5i.apps.googleusercontent.com"
      }
    >
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
