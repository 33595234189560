import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Constaint from "../layout/components/Constaint";
import helpBG from "../../../images/web/helpBG.png";
import Colors from "../../../styles/Colors";
import { TbMessageQuestion } from "react-icons/tb";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RiCustomerService2Fill } from "react-icons/ri";
import Contact from "../eligibility/components/Contact";
import FAQ from "../eligibility/components/FAQ";
import { useSearchParams } from "react-router-dom";
import SEOMeta from "../components/SEOMeta";
import { WebPaths } from "../../../routes/data";
import { SVLogoSquare } from "../../../images";

const Help: React.FC = () => {
  const contactRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const isContact = searchParams.get("contact") === "true";

    if (isContact && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchParams]);

  return (
    <>
      <SEOMeta
        title="SeamlessVisa Help Center"
        description="Find answers to your questions and get support for your U.S. visa application with SeamlessVisa’s help center."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.Help}`}
        url={`https://www.seamlessvisa.com${WebPaths.Help}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="SeamlessVisa Help Center"
        twitterDescription="Find answers to your questions and get support for your U.S. visa application with SeamlessVisa’s help center."
        twitterSite="@SeamlessVisa_"
      />

      <Wrapper>
        <HelpBanner>
          <Constaint>
            <HelpTopContents>
              <h1>How can we help?</h1>
              <p>Get in touch with our support team! Always happy to help!</p>
            </HelpTopContents>
          </Constaint>
        </HelpBanner>
        <Constaint>
          <>
            <CardWrapper>
              <Card>
                <div>
                  <TbMessageQuestion />
                </div>
                <h1>general questions</h1>
                <p>connect@seamlessvisa.com</p>
              </Card>

              <Card>
                <div>
                  <HiOutlineLocationMarker />
                </div>
                <h1>mailing address</h1>
                <p>8 The Green # 19607, Dover, DE 19901.</p>
              </Card>

              <Card>
                <div>
                  <RiCustomerService2Fill />
                </div>
                <h1>support</h1>
                <p>support@seamlessvisa.com</p>
              </Card>
            </CardWrapper>
          </>
        </Constaint>
        <FAQ />
        <div ref={contactRef}>
          <Contact />
        </div>
      </Wrapper>
    </>
  );
};

export default Help;

const Wrapper = styled.div`
  background: #f4f5f5;
`;

const HelpBanner = styled.div`
  background-image: url(${helpBG});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 5rem 1rem;
`;

const HelpTopContents = styled.div`
  padding: 4rem 2rem;
  text-align: center;
  color: white;

  & > h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem auto;
  }

  & > p {
    font-size: 1rem;
  }

  @media screen and (min-width: 768px) {
    padding: 4rem;

    & > h1 {
      font-size: 3.5rem;
    }

    & > p {
      font-size: 1.25rem;
    }
  }
`;

// const FindHelp = styled.div`
//   width: 100%;
//   max-width: 550px;
//   margin: 1rem auto;
//   padding: 0.5rem 1rem;

//   display: flex;
//   align-items: center;
//   justify-content: start;

//   background: #fff;
//   border-radius: 8px;

//   overflow: hidden;

//   & > svg {
//     color: ${Colors.GreyA7};
//     width: 24px;
//     height: 24px;
//   }

//   & > input {
//     flex: 1;

//     font-size: 1rem;
//     color: black;

//     padding: 0.5rem;
//     background: transparent;

//     outline: none;
//     border: none;
//   }
// `;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin-top: -5rem;
  }

  padding: 0 1rem 8rem;

  margin-top: 5rem;
`;

const Card = styled.div`
  flex: 1;

  width: 100%;
  max-width: 350px;

  display: inline-block;

  background: #fff;
  padding: 3rem;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-shadow: 5px 6px 16px #dff0fc;

  &:nth-child(2) {
    @media screen and (min-width: 768px) {
      margin-top: 4rem;
    }
  }

  & > div {
    background-color: ${Colors.Blue00};
    border-radius: 50%;
    padding: 1.5rem;

    display: grid;
    place-content: center;

    & > svg {
      width: 43px;
      height: 43px;

      color: #fff;
    }
  }

  & > h1 {
    text-transform: capitalize;
    margin: 2rem auto;
    color: ${Colors.Blue00};
    font-size: 1.5rem;
  }

  & > p {
    text-align: center;
  }
`;
