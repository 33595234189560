import TableSection from "../../../../components/table/TableSection";
import { RoleOptions, StaffTableData } from "./components/data";
import { DataItemsProps, QueriesKey } from "../../../../components/type";
import StaffAction from "./components/StaffAction";
import { StaffRes } from "../../../../../../api/business/staff/types";
import { useGetAllStaff } from "../../../../../../hooks/business/useStaff";
import styled from "styled-components";
import { ModuleTitle } from "../../../../components/styles";
import { useEffect, useState } from "react";
import TableSearch from "../../../../components/table/TableSearch";
import TableDropdown from "../../../../components/table/TableDropdown";
import StaffForm from "./components/StaffForm";
import CreateBtn from "../../CreateBtn";
import { useSnapshot } from "valtio";
import { businessUserDataStore } from "../../../../../../store/businessUserData";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { ProfileSections } from "../../types";
import { isBusinessDataStore } from "../../../../../../store/isBusinessData";
import { BillingType } from "../billing/components/data";
import { useNavigate } from "react-router-dom";

const StaffManagement = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetAllStaff();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { businessUserData } = useSnapshot(businessUserDataStore);
  const { isBusinessData } = useSnapshot(isBusinessDataStore);

  const canNotAddStaff =
    !!businessUserData?.paymentInfo &&
    businessUserData?.paymentInfo.numOfDefaultSeatsAvailable === 0 &&
    businessUserData?.paymentInfo.numOfPaidSeatsAvailable === 0;

  const onAddStaff = () => {
    if (canNotAddStaff) {
      return;
    }
    setModalOpen(true);
  };

  useEffect(() => {
    const getToast = (param: JSX.Element) => {
      return toast.error(param, {
        autoClose: false,
      });
    };

    if (isBusinessData) {
      if (!businessUserData?.paymentInfo.subscriptionType) {
        getToast(
          <div>
            <strong>Access Denied:</strong> You currently don’t have access to
            staff management as you’re on a free trial. Please upgrade to a paid
            plan to gain access.
          </div>
        );
        navigate(`?active=${ProfileSections.billing}`);
        return;
      }

      if (
        businessUserData?.paymentInfo?.isSubscriptionActive !== undefined &&
        !businessUserData?.paymentInfo.isSubscriptionActive
      ) {
        getToast(
          <div>
            <strong>Access Denied:</strong> You do not currently have access
            because your subscription has expired or is no longer active.
          </div>
        );
        navigate(`?active=${ProfileSections.billing}`);
        return;
      }

      if (
        businessUserData?.paymentInfo.subscriptionType ===
        BillingType.perApplication
      ) {
        getToast(
          <div>
            <strong>Access Denied:</strong> Your current billing plan does not
            include access to this feature.
          </div>
        );
        navigate(`?active=${ProfileSections.billing}`);
        return;
      }
    }
  }, [isBusinessData, navigate, businessUserData]);

  return (
    <>
      <Wrapper>
        <div className="flex flex-wrap items-center justify-between gap-6">
          <Header>Staff Management</Header>

          <Tooltip
            title={
              canNotAddStaff
                ? "You’ve reached the maximum amount of staff you can add."
                : ""
            }
          >
            <span>
              <CreateBtn
                text="Add Staff"
                onClick={onAddStaff}
                isDisabled={canNotAddStaff}
              />
            </span>
          </Tooltip>
        </div>

        <MiddleWrapper className="my-6">
          <TableSearch placeholder="Search staff" />

          <MiniFlex>
            <TableDropdown
              width="200px"
              name="Role"
              option={RoleOptions}
              queryName={QueriesKey.status}
            />
          </MiniFlex>
        </MiddleWrapper>

        <StaffForm
          isOpen={modalOpen}
          data={null}
          onClose={() => setModalOpen(false)}
        />

        <div className="mt-6 ">
          <TableSection
            dataItems={data}
            tableData={StaffTableData}
            actionComp={(data: DataItemsProps) => (
              <StaffAction data={data as StaffRes} />
            )}
            isError={isError}
            isLoading={isLoading}
          />
        </div>
      </Wrapper>
    </>
  );
};

export default StaffManagement;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled(ModuleTitle)`
  margin-bottom: 0;
`;

const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MiniFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
