import React, { useEffect } from "react";
import { BillingStripeQuery, SubTypeValues } from "./components/data";
import { ModuleTitle } from "../../../../components/styles";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import FibiBilling from "./components/FibiBilling";

const Billing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const subTypeParams = searchParams.get(BillingStripeQuery.subType) as string;

  useEffect(() => {
    if (!subTypeParams) {
      searchParams.set(BillingStripeQuery.subType, SubTypeValues.fibi);
      setSearchParams(searchParams);
    }
  }, [searchParams, subTypeParams, setSearchParams]);

  return (
    <>
      <div className="flex flex-wrap items-center justify-between gap-6 mb-4">
        <Header>Manage Billing</Header>
      </div>

      <div className="flex items-center justify-center gap-2 my-9">
        {[
          {
            label: "FiBi",
            href: SubTypeValues.fibi,
          },
          // {
          //   label: "Forms",
          //   href: SubTypeValues.forms,
          // },
        ].map((ev) => {
          const isActive = subTypeParams === ev.href;
          return (
            <button
              className={`px-4 py-1 border rounded-full border-primaryColor text-primaryColor min-w-[100px] ${
                isActive ? " bg-primaryColor text-white " : ""
              }`}
              onClick={() => {
                searchParams.set(BillingStripeQuery.subType, ev.href);
                setSearchParams(searchParams);
              }}
            >
              {ev.label}
            </button>
          );
        })}
      </div>

      {/* {subTypeParams === SubTypeValues.forms && <FormBilling />} */}
      {subTypeParams === SubTypeValues.fibi && <FibiBilling />}
    </>
  );
};

export default Billing;

const Header = styled(ModuleTitle)`
  margin-bottom: 0;
`;
