export enum utilsQueryKeys {
  getCountries = "get-countries",
  getStates = "get-states",
  getUserData = "get-user-data",
  // getInterviewHistory = "get-interview-history",
  getChatGptInterviewHistory = "get-chat-gpt-interview-history",
  // getInterviewVoiceHistory = "get-interview-voice-history",
  getChatGptInterviewVoiceHistory = "get-chat-gpt-interview-voice-history",
  getGeoLocation = "getGeoLocation",
}
