import { useState, useEffect } from "react";
import { formatTime } from "./data";

interface CountdownTimerProps {
  duration: number; // Duration in seconds
  onEnd: () => Promise<any>;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ duration, onEnd }) => {
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    const storedEndTime = localStorage.getItem("countdownEndTime");
    let endTime: number = storedEndTime
      ? parseInt(storedEndTime, 10)
      : Date.now() + duration * 1000;

    if (!storedEndTime) {
      localStorage.setItem("countdownEndTime", endTime.toString());
    }

    let interval: NodeJS.Timeout | undefined;
    let requestCalled = false;

    const updateTimer = () => {
      const remainingTime = Math.max(
        0,
        Math.floor((endTime - Date.now()) / 1000)
      );
      setTimeLeft(remainingTime);

      if (remainingTime === 0 && !requestCalled) {
        requestCalled = true;
        clearInterval(interval);
        // Wait for the POST request to succeed before clearing localStorage
        onEnd()
          .then(() => {
            localStorage.removeItem("countdownEndTime");
          })
          .catch((err) => {
            console.error("API request failed:", err);
          });
      }
    };

    updateTimer();
    interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [duration, onEnd]);

  return (
    <div>
      <span className="hidden lg:inline">Time Left:</span>{" "}
      {formatTime(timeLeft)}
    </div>
  );
};

export default CountdownTimer;
