import { Link } from "react-router-dom";
import fibiImage from "../../../../../images/web/fibi-screenshot.png";
import { WebPaths } from "../../../../../routes/data";
import { AiOutlineComment } from "react-icons/ai";

const Hero = () => {
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden isolate bg-gradient-to-b from-blue-100/20">
        <div className="pt-10 pb-24 mx-auto max-w-7xl sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="max-w-2xl mx-auto">
              <div className="max-w-lg">
                <h1 className="text-5xl font-semibold tracking-tight text-balance text-Blue00 text-Blue0 sm:text-7xl font-spartan">
                  Prepare for Your U.S.
                  <span className="font-semibold font-spartan">
                    Visa Interview
                  </span>{" "}
                  with Confidence
                </h1>
                <p className="mt-6 text-gray-600 text-lg/8">
                  Meet FiBi AI - Your Personal Interview Trainer. Practice real
                  U.S. visa interview questions with both text and voice
                  simulations to boost your confidence and approval chances.
                </p>
                <div className="mt-10 max-w-64">
                  {/* <Link
                    to={WebPaths.FiBiVoice}
                    className="group bg-[#EAC5FC] grow text-sm font-semibold text-Black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 flex items-center gap-x-4 first:justify-between w-fit mx-auto"
                  >
                    <span className="px-4">Start Mock Interview</span>
                    <div className="bg-[#A881DB] group-hover:bg-[#A881DB]/40 p-3 text-white duration-150">
                      <AiOutlineComment className="w-6 h-6" />
                    </div>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-blue-600/10 ring-1 ring-blue-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-Blue00/10 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-blue-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                  <div className="max-w-2xl mx-auto md:mx-0 md:max-w-none">
                    <img
                      alt="Product screenshot"
                      src={fibiImage}
                      width={2432}
                      height={980}
                      className="-mb-12 w-[57rem] max-w-none rounded-tl-3xl bg-gray-800 ring-1 ring-white/10"
                    />
                  </div>
                  <div
                    className="absolute inset-0 pointer-events-none ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 h-24 -z-10 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
};

export default Hero;
