import React, { FC } from "react";
import {
  FaHandHoldingDollar,
  FaLaptopFile,
  FaSchool,
  FaSchoolFlag,
} from "react-icons/fa6";

const ServiceCard: FC<{
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
}> = ({ icon: Icon, title }) => (
  <div className="flex items-center lg:justify-center gap-3 p-4 text-center sm:flex-row sm:text-left">
    <span className="text-gray-700">
      <Icon className="w-10 h-10" />
    </span>
    <span className="text-gray-800 text-xl font-semibold font-spartan">
      {title}
    </span>
  </div>
);

const PackagesAddon = () => {
  const services = [
    {
      icon: FaLaptopFile,
      title: "Ivy League Applications",
    },
    {
      icon: FaSchool,
      title: "Additional schools",
    },
    {
      icon: FaSchoolFlag,
      title: "Additional Ivy League schools",
    },
    {
      icon: FaHandHoldingDollar,
      title: "Scholarship Assistance",
    },
  ];

  return (
    <div className="w-full py-16 mt-16">
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-2xl w-11/12 font-spartan md:text-3xl font-semibold text-gray-900 mb-4">
            Customize your application strategy with our range of optional
            add-on services.
          </h2>
          <p className="text-gray-700 max-w-3xl mx-auto">
            Choose from Ivy League application support, additional school
            applications (including more Ivy League options), and scholarship
            assistance (up to 3 programs) to maximize your opportunities.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 place-content-center gap-2 mt-12">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              icon={service.icon}
              title={service.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackagesAddon;
