import { BusinessPriceObj } from "../../../../../components/data";

export const recurringBillingData = [
  {
    name: "Standard",
    id: "tier-standard",
    href: "#",
    priceMonthly: BusinessPriceObj.standard,
    description: "Essential tools for your AI needs.",
    features: ["API Integration", "AI Risk Checker", "AI Interview Trainer"],
    featured: false,
  },
  {
    name: "Premium",
    id: "tier-premium",
    href: "#",
    priceMonthly: BusinessPriceObj.premium,
    description: "Advanced AI features with collaboration tools.",
    features: [
      "API Integration",
      "AI Risk Checker",
      "AI Interview Trainer",
      "Case Evaluation",
      "Client Collaboration",
      "Customized Dashboard",
    ],
    featured: true, // Highlight the premium plan
  },
  //   {
  //     name: "Enterprise",
  //     id: "tier-enterprise",
  //     href: "#",
  //     priceMonthly: "$99",
  //     description: "Tailored solutions for your business needs.",
  //     features: ["Get tailored solutions for your business needs"],
  //     featured: false,
  //   },
];

export enum BillingType {
  perApplication = "payPerApplication",
  standard = "standard",
  premium = "premium",
  enterprise = "enterprise",
  staff = "staff",
  starter = "starter",
  growth = "growth",
  starterYearly = "starterYearly",
  growthYearly = "growthYearly",
}

export interface BillingDataProps {
  id: BillingType;
  title: string;
  price: number;
  pricePeriod?: string;
  features: (string | JSX.Element)[];
  isCustom?: boolean;
  buttonText: string;
}

export const billingData = (
  onceHadASubscriptionPlan: boolean,
  seatPrice: string
): BillingDataProps[] => {
  const temp = [
    {
      id: BillingType.standard,
      title: "Standard",
      price: BusinessPriceObj.standard,
      pricePeriod: "month",
      features: [
        "AI Risk Checker",
        "AI Interview Trainer",
        "Client Management",
        "Case Management",
        "Staff Management",
        "Technical Support",
        `1 Staff Seat (can add up to 2 seats for ${seatPrice}/month, per seat)`,
      ],
      buttonText: "Select Standard",
    },
    {
      id: BillingType.premium,
      title: "Premium",
      price: BusinessPriceObj.premium,
      pricePeriod: "month",
      features: [
        <>
          Everything in <strong className=" text-primaryColor">Standard</strong>{" "}
          Plan
        </>,
        "Early access to new features",
        "Case Tracking",
        `3 staff seats (up to 2 additional seats available for ${seatPrice}/month, per seat)`,
      ],
      buttonText: "Select Premium",
    },
    {
      id: BillingType.enterprise,
      title: "Enterprise",
      price: 0,
      pricePeriod: "",
      features: [
        <>
          Everything in <strong className=" text-primaryColor">Premium</strong>{" "}
          Plan
        </>,
        "API integrations",
        "Get tailored solutions for your business needs",
      ],
      buttonText: "Contact Us",
      isCustom: true,
    },
  ];

  if (!onceHadASubscriptionPlan) {
    temp.unshift({
      id: BillingType.perApplication,
      title: "Per Application",
      price: BusinessPriceObj.perApplication,
      pricePeriod: "application",
      features: [
        "AI Risk Checker",
        "AI Interview Trainer",
        "Client Management",
        "Case Management",
        "Technical Support",
        "1 Staff Seat",
      ],
      buttonText: "Select Per Application",
    });
  }

  return temp;
};

export enum BillingStripeQuery {
  billingType = "billingType",
  paymentType = "paymentType",
  session_id = "session_id",
  subType = "subType",
}

export enum SubTypeValues {
  forms = "forms",
  fibi = "fibi",
}

export const fibiBillingData = () => {
  const temp = [
    {
      id: [BillingType.starter, BillingType.starterYearly],
      title: "Business Starter Pack",
      subText: "For agencies assisting up to 10 clients",
      monthPrice: 75000,
      yearPrice: 750000,
      savedYearPrice: 150000,
      dollarMonthPrice: 50,
      dollarYearPrice: 500,
      dollarSavedYearPrice: 100,
      features: [
        "5 Interview Simulations",
        "Real-Time Feedback & Personalized Recommendations",
        "Access to Curated Question Library",
        "Transcription in Document For 3 Simulations",
        "Practice History Storage For 3 Months",
      ],
      buttonText: "Select Starter Pack",
    },
    {
      id: [BillingType.growth, BillingType.growthYearly],
      title: "Business Growth Pack",
      subText: "For mid-sized agencies assisting up to 25 clients",
      monthPrice: 220000,
      yearPrice: 2200000,
      savedYearPrice: 440000,
      dollarMonthPrice: 150,
      dollarYearPrice: 1700,
      dollarSavedYearPrice: 100,
      features: [
        "10 Interview Simulations",
        "Real-Time Feedback & Personalized Recommendations",
        "Access to Curated Question Library",
        `Transcription in Document For All Simulations`,
        "Practice History Storage For 6 Months",
      ],
      buttonText: "Select Growth Pack",
    },
  ];

  return temp;
};
