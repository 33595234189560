import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import FormBtn from "../../../components/FormBtn";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import SelectField from "../../../components/formik fields/SelectField";
import { PrimaryOccupationData, processReviewData } from "../data";
import {
  ExplicitNGDateFormat,
  ToSaveDate,
  initCheck,
  initDate,
  initInput,
  initSelect,
} from "../../../components/data";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import {
  useGetDS160WETPresent,
  useSaveDS160WETPresent,
} from "../../../../../hooks/ds160/usDS160";
import { Dayjs } from "dayjs";
import { Modify, SingleOption } from "../../../../../types/types";
import { saveDS160WETPresentObj } from "../../../../../api/ds160/types";
import { CheckWrapper, VerticalFormWrapper } from "../styles";
import { WETPresentValidation } from "../validation";
import { resetDynamicFields } from "../../../components/data";
import useToNextSection from "../../../../../hooks/useToNextSection";
import useCountries from "../../../components/useCountries";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { ReviewWETAdditionalSectionA } from "../Review/components/data";
import useAiReview from "../useAiReview";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../../api/utils/types";
import { isAnonClientDataStore } from "../../../../../store/isAnonClientData";
import { useSnapshot } from "valtio";

export interface WETPresentFormData
  extends Modify<
    saveDS160WETPresentObj,
    {
      primaryOccupation: SingleOption | null;
      startDate: Dayjs | null;
      countryOrRegion: SingleOption | null;
      stateOrProvince: SingleOption | null;
    }
  > {}

const WETPresent = () => {
  const { toNextSection } = useToNextSection();
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160WETPresent({
    applicationId: getApplicationId,
    buid: isAnonClientData,
  });
  const { mutate, isLoading: isSaving } = useSaveDS160WETPresent();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();
  const { formattedCountryData, countryLoading } = useCountries();

  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(data?.countryOrRegion);

  const initialValues: WETPresentFormData = {
    primaryOccupation: initSelect(
      PrimaryOccupationData,
      data?.primaryOccupation
    ),
    presentEmployerOrSchoolName: initInput(data?.presentEmployerOrSchoolName),
    streetAddressLine1: initInput(data?.streetAddressLine1),
    streetAddressLine2: initInput(data?.streetAddressLine2),
    city: initInput(data?.city),
    zipCode: initInput(data?.zipCode),
    phoneNumber: initInput(data?.phoneNumber),
    countryOrRegion: initSelect(formattedCountryData, data?.countryOrRegion),
    stateOrProvince: initSelect(formattedStateData, data?.stateOrProvince),
    startDate: initDate(data?.startDate, ExplicitNGDateFormat),
    monthlyIncome: initInput(data?.monthlyIncome),
    doesNotApply: initCheck(data?.doesNotApply),
    dutiesDescription: initInput(data?.dutiesDescription),
  };
  const onSubmit = (values: WETPresentFormData) => {
    const {
      startDate,
      primaryOccupation,
      countryOrRegion,
      stateOrProvince,
      ...rest
    } = values;

    if (
      !startDate ||
      !primaryOccupation ||
      !countryOrRegion ||
      !stateOrProvince
    )
      return;

    const payload = {
      ...rest,
      primaryOccupation: primaryOccupation.value,
      startDate: ToSaveDate(startDate, ExplicitNGDateFormat),
      countryOrRegion: countryOrRegion.value,
      stateOrProvince: stateOrProvince.value,
    };

    const section = ReviewWETAdditionalSectionA(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      wet: {
        ...riskData?.wet,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId, buid: isAnonClientData },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || (!isAnonClientData && riskLoading)}
      isError={isError || (!isAnonClientData && riskError)}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your
            Work/Education/Training.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={WETPresentValidation}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <SelectField
                  label="Primary Occupation"
                  placeholder=""
                  name="primaryOccupation"
                  options={PrimaryOccupationData}
                />

                <InputField
                  label="Present employer or school name"
                  placeholder=""
                  name="presentEmployerOrSchoolName"
                />

                <InputField
                  label="Street Address Line 1"
                  placeholder=""
                  name="streetAddressLine1"
                />

                <InputField
                  label="Street Address Line 2"
                  coloredLabel="(optional)"
                  placeholder=""
                  name="streetAddressLine2"
                />

                <InputField label="City" placeholder="" name="city" />

                <SelectField
                  name="countryOrRegion"
                  label="Country/Region"
                  placeholder=""
                  options={formattedCountryData}
                  isLoading={countryLoading}
                  onChange={(value) => {
                    setCountry(value.label);
                    formik.setFieldValue(`stateOrProvince`, null);
                  }}
                />

                <SelectField
                  label="State/Province"
                  placeholder=""
                  name="stateOrProvince"
                  options={formattedStateData}
                  isLoading={stateLoading}
                  isDisabled={!selectedCountry}
                />

                <InputField
                  label="Postal Code/Zip Code"
                  placeholder=""
                  name="zipCode"
                />

                <InputField
                  label="Phone Number"
                  placeholder=""
                  name="phoneNumber"
                />

                <DatePickerField
                  name="startDate"
                  label="Start Date"
                  placeholder={ExplicitNGDateFormat}
                  disableFuture={true}
                />

                <IncomeWrapper>
                  <InputField
                    label="Monthly income in local currency"
                    placeholder=""
                    name="monthlyIncome"
                    isDisabled={!!formik.values.doesNotApply}
                  />
                  <CheckWrapper>
                    <CheckBoxField
                      label={`Does not apply`}
                      name="doesNotApply"
                      onChange={() => {
                        resetDynamicFields(formik, [
                          { name: "monthlyIncome", value: "" },
                        ]);
                      }}
                    />
                  </CheckWrapper>
                </IncomeWrapper>

                <InputField
                  label="Briefly describe your duties"
                  placeholder=""
                  name="dutiesDescription"
                  inputType="text-area"
                />
                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </FormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default WETPresent;

const Wrapper = styled.div``;

const Info = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 30px;
`;

const InfoBold = styled.div`
  font-weight: 700;
`;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    /* width: 50%; */
  }
`;

const IncomeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
`;
