import React, { FC, useState } from "react";
import styled from "styled-components";
import { BsArrowLeftShort } from "react-icons/bs";
import { AnimatePresence } from "framer-motion";
import { Form, Formik } from "formik";
import { mediaObj } from "../../../../styles/Media";
import Constaint from "../../layout/components/Constaint";
import Colors from "../../../../styles/Colors";
import RadioField from "../../components/formik fields/RadioField";
import EligibilityModal from "./modals/EligibilityModal";
import {
  EligibilityFormData,
  OnFieldChangeProps,
  checkQuestionsProps,
  terminateInfo,
} from "./types";
import { useParams } from "react-router-dom";
import { FormCategories } from "../../../../routes/type";
import ParamFormName from "./ParamFormName";
import { eligibilityDataStore } from "../../../../store/eligibilityData";
import { useSnapshot } from "valtio";
import Tooltip from "../../components/Tooltip";
import useOpener from "../../../../hooks/useOpener";
import { useEligibilityCountryApprovalRate } from "../../../../hooks/ai/useAI";
import ApprovalBox from "./ApprovalBox";

interface Props {
  onChangeState: (value: boolean) => void;
  questions: checkQuestionsProps<any>[];
}

const CheckEligibilityQuestions: FC<Props> = ({ onChangeState, questions }) => {
  const { eligibilityData } = useSnapshot(eligibilityDataStore);
  const [step, setStep] = useState(1);
  const [historySteps, setHistorySteps] = useState<number[]>([1]);
  const [formData, setFormData] = useState<EligibilityFormData>();
  const [openModal, setOpenModal] = useState(false);
  const [terminateInfo, setTerminateInfo] = useState<terminateInfo>();
  const { formType } = useParams();
  const { onOpen, open } = useOpener();
  const { mutate, isLoading, isError } = useEligibilityCountryApprovalRate();
  const [approvalRate, setApprovalRate] = useState<string>();

  const onRestart = () => {
    setStep(1);
    onChangeState(true);
  };

  const handleChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const result = questions[step - 1]; //since js is zero based
  const title = result.getTitle ? result.getTitle(formData) : result.title;

  const onBack = () => {
    if (historySteps.length === 1) return onChangeState(true);
    historySteps.pop();
    const lastStep = historySteps.slice(-1)[0];
    setStep(lastStep);
  };

  const onModalClose = () => {
    setOpenModal(false);
    setTerminateInfo(undefined);
  };

  const getTitlelabel = () => {
    if (formType === FormCategories.n565)
      return "For Application for replacement of citizenship document";
    if (formType === FormCategories.n400)
      return "For Application for U.S. Citizenship/Naturalization.";
    if (formType === FormCategories.n600)
      return "For Application for Certificate of Citizenship";
    if (formType === FormCategories.ds160)
      return "For Application for Nonimmigrant Visa";
  };

  const onFieldChange = (data: OnFieldChangeProps) => {
    const { selectedValue, nextStep, terminate, dynamicNextStep } = data;
    handleChange(selectedValue, result.storeField);
    eligibilityDataStore.eligibilityData.formType = formType;
    eligibilityDataStore.eligibilityData.formData = {
      ...eligibilityData.formData,
      [result.storeField]: selectedValue,
    };

    if (
      result.storeField === "where_are_applying_from" ||
      result.storeField === "where_will_you_apply_for_your_visa"
    ) {
      onOpen();
      mutate(
        { data: selectedValue },
        {
          onSuccess: (data) => {
            setApprovalRate(data.data.result);
          },
        }
      );
    }

    if (nextStep) {
      setStep(nextStep);
      setHistorySteps((prev) => [...prev, nextStep]);
      return;
    }

    const payload = {
      ...formData,
      [result.storeField]: selectedValue,
    };
    const temp = dynamicNextStep && dynamicNextStep(payload);
    if (temp) {
      setStep(temp);
      setHistorySteps((prev) => [...prev, temp]);
      return;
    }

    if (terminate) {
      setOpenModal(true);
      setTerminateInfo(terminate);
      return;
    }
  };

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Back onClick={onBack}>
            <BackIconWrapper>
              <BsArrowLeftShort />
            </BackIconWrapper>
            Back
          </Back>

          <Header>
            <CheckTitle>
              <ParamFormName /> Eligibility Checks
            </CheckTitle>
            <TitleLabel>{getTitlelabel()}</TitleLabel>
          </Header>
          {open && (
            <ApprovalBox
              isLoading={isLoading}
              approvalRate={approvalRate}
              isError={isError}
            />
          )}

          <QuestionText>
            {title}
            {result.subTitle && (
              <QuestionSubTitle>{result.subTitle}</QuestionSubTitle>
            )}
          </QuestionText>

          <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
              <OptionWrapper>
                {result.singleOption ? (
                  <OptionBox>
                    {result.singleOption.note && (
                      <TooltipWrapper>
                        <Tooltip
                          toolTipProps={{
                            body: result.singleOption.note,
                          }}
                        />
                      </TooltipWrapper>
                    )}

                    {result.singleOption.renderAltComp({
                      formData,
                      onChange: (data: OnFieldChangeProps) =>
                        onFieldChange(data),
                      result,
                    })}
                  </OptionBox>
                ) : (
                  result.options?.map((option, optionIndex) => {
                    const value = option.label;

                    return (
                      <OptionBox key={optionIndex}>
                        {option.note && (
                          <TooltipWrapper>
                            <Tooltip
                              toolTipProps={{
                                body: option.note,
                              }}
                            />
                          </TooltipWrapper>
                        )}

                        <RadioField
                          name={title}
                          value={value}
                          label={option.label}
                          boxSize="20px"
                          key={optionIndex}
                          isChecked={
                            formData
                              ? value === formData[result.storeField]
                                ? true
                                : false
                              : false
                          }
                          onChange={(value) => {
                            const nextStep = option.nextStep;
                            const terminate = option.terminate;
                            const selectedValue = value;
                            onFieldChange({
                              selectedValue,
                              nextStep,
                              terminate,
                              dynamicNextStep: option.dynamicNextStep,
                            });
                          }}
                        />
                      </OptionBox>
                    );
                  })
                )}
              </OptionWrapper>
            </Form>
          </Formik>

          <AnimatePresence>
            {openModal && (
              <EligibilityModal
                onClose={onModalClose}
                formToRetake={terminateInfo?.formToRetake}
                type={terminateInfo?.type}
                formName={formType}
                onRestart={onRestart}
                secondBtnText={terminateInfo?.secondBtnText}
                infoContent={terminateInfo?.infoContent}
              />
            )}
          </AnimatePresence>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default CheckEligibilityQuestions;

const Wrapper = styled.div``;

const Container = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 53px auto 220px auto;

  ${mediaObj.tablet} {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Back = styled.div`
  color: ${Colors.Black31};
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.875rem;
  margin-bottom: 61px;
  cursor: pointer;
`;

const BackIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.DarkD9};
  padding-bottom: 15px;
  margin-bottom: 61px;

  ${mediaObj.tablet} {
    gap: 5px;
    flex-wrap: wrap;
  }
`;

const CheckTitle = styled.div`
  color: ${Colors.Blue00};
  font-weight: 600;
`;

const TitleLabel = styled.div`
  color: ${Colors.Dark4E};
  font-size: 0.75rem;
`;

const QuestionText = styled.div`
  color: ${Colors.Black31};
  font-size: 2.5rem;
  font-weight: 500;
  margin: auto;
  margin-bottom: 48px;

  ${mediaObj.bigMobile} {
    font-size: 2rem;
  }
`;

const QuestionSubTitle = styled.div`
  color: ${Colors.Grey8B};
  font-size: 1.125rem;
  font-style: italic;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
`;

const OptionBox = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: auto;
  }

  & > div {
    border: 1px solid ${Colors.GreyA7};
    border-radius: 8px;
    width: 528px;
    min-height: 80px;
    padding: 15px 12px;
    color: ${Colors.Black31};
    cursor: pointer;
    gap: 16px;

    &:hover {
      background-color: ${Colors.DarkD9};
      transition: 0.3s ease-in-out;
    }

    ${mediaObj.tablet} {
      width: 100%;
    }
  }
`;

const TooltipWrapper = styled.section`
  position: absolute;

  display: flex;
  align-items: center;
  gap: 5px;

  right: 1%;
  bottom: 5%;

  @media screen and (min-width: 768px) {
    right: -26px;
    bottom: 0.8%;
  }

  font-weight: 900;
  font-size: 12px;
  color: ${Colors.RedE3};
`;
