import Hero from "./components/Hero";
import WhySeamless from "./components/WhySeamless";
import Mission from "./components/Mission";
import SEOMeta from "../../components/SEOMeta";
import { WebPaths } from "../../../../routes/data";
import { SVLogoSquare } from "../../../../images";

const About = () => {
  return (
    <div>
      <SEOMeta
        title="About Us | SeamlessVisa"
        description="Learn more about our mission, values, and team. We are a team of lawyers, engineers, and entrepreneurs who are passionate about helping people get the best possible immigration experience."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.About}`}
        url={`https://www.seamlessvisa.com${WebPaths.About}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="About Us | SeamlessVisa"
        twitterDescription="Learn more about our mission, values, and team. We are a team of lawyers, engineers, and entrepreneurs who are passionate about helping people get the best possible immigration experience."
        twitterSite="@SeamlessVisa_"
      />
      <Hero />
      <WhySeamless />
      <Mission />
    </div>
  );
};

export default About;
