import React, { useState } from "react";
import Constaint from "../../../layout/components/Constaint";
import styled from "styled-components";
import Colors from "../../../../../styles/Colors";
import academyImageBanner from "../../../../../images/web/academy-banner-img.png";
import { FaArrowRightLong } from "react-icons/fa6";
import CalendlyPopUp from "../../For Business/components/CalendlyPopUp";

const Banner: React.FC = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Wrapper>
        <Constaint>
          <Content>
            <LeftContent>
              <h1 className="font-spartan text-[2.9rem] md:text-[4rem] font-medium leading-[1.2] text-pretty">
                Achieve Your Dream of Studying Abroad with SeamlessVisa  
              </h1>
              <p>
                We connect you with top universities in the USA, Canada, UK,
                Australia, and Ireland. Our expert advisors, study abroad
                partners, and extensive university network ensure you secure
                admission to a school that aligns with your goal and budget.  
              </p>
              <button
                className=" w-full lg:w-[80%] text-lg text-white flex"
                onClick={() => setOpen(true)}
              >
                <span className="flex-1 p-6 bg-primaryColor">
                  Book A Free Consultation
                </span>
                <div className="p-6 bg-[#C3E1FF] w-[74px] h-auto flex items-center justify-center text-black">
                  <FaArrowRightLong />
                </div>
              </button>
            </LeftContent>
            <RightContent>
              <img
                src={academyImageBanner}
                alt="sv-for-academy-banner"
                className="object-contain h-full"
              />
            </RightContent>
          </Content>
        </Constaint>
      </Wrapper>

      <CalendlyPopUp
        url="https://calendly.com/gift-ubah-seamlessvisa/30min?month=2025-02"
        open={open}
        onClose={onClose}
      />
    </>
  );
};

export default Banner;

const Wrapper = styled.div``;

const Content = styled.div`
  min-height: 80dvh;
  margin: 0 auto;
  gap: 24px;
  /* overflow-x: hidden; //for image overflow happening on small screens (mobile) */

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    gap: 48px;
    flex-direction: row;
  }
`;

const LeftContent = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  flex: 1;

  & > h1 {
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 24px;
    color: ${Colors.Blue00};
  }

  & > p {
    line-height: 1.6;
    margin-bottom: 48px;
    color: ${Colors.Black1A};
    width: 80%;
  }
`;

const RightContent = styled.div`
  position: relative;
  flex: 1;

  & > img {
    height: 800px;
    width: 100%;

    object-fit: cover;
  }
`;
