import React, { FC } from "react";

interface Props {
  onStart: () => void;
}

const BusinessStarter: FC<Props> = ({ onStart }) => {
  return (
    <div className="flex flex-col items-center justify-center px-8 pt-5 pb-10 gap-y-4">
      <p className="text-gray-600">
        Prepare for your interview with this timed 10-minute simulation. FiBi
        will provide helpful suggestions during each question, giving you
        insight into how to best respond in a professional setting. This is a
        practice environment to help you succeed.
      </p>
      <p>
        <b className="text-primaryColor">Important:</b> This simulation is 10
        minutes long, which is longer than a typical real-world interview. Once
        the simulation begins, you cannot pause or take breaks. Completing the
        entire session is required and will count towards your total practice
        sessions.
      </p>

      <div
        role="button"
        onClick={onStart}
        className="flex items-center justify-center p-2 mt-4 text-lg text-white duration-150 px-28 bg-primaryColor rounded-xl hover:bg-Blue02"
      >
        Start
      </div>
    </div>
  );
};

export default BusinessStarter;
