import React from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Constaint from "../layout/components/Constaint";
import SEOMeta from "../components/SEOMeta";
import { WebPaths } from "../../../routes/data";
import { SVLogoSquare } from "../../../images";

const GuaranteePolicy: React.FC = () => {
  return (
    <>
      <SEOMeta
        title="Guarantee Policy | SeamlessVisa"
        description="Learn about SeamlessVisa's guarantee policy and how we ensure a seamless and risk-free experience in your visa application process."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.GuaranteePolicy}`}
        url={`https://www.seamlessvisa.com${WebPaths.GuaranteePolicy}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="Guarantee Policy | SeamlessVisa"
        twitterDescription="Learn about SeamlessVisa's guarantee policy and how we ensure a seamless and risk-free experience in your visa application process."
        twitterSite="@SeamlessVisa_"
      />

      <Wrapper>
        <Constaint>
          <div>
            <h1>Guarantee Policy</h1>
            <h4>Last Updated March 7, 2024</h4>
            <p>
              This guarantee policy notice for SeamlessVisa Inc. (‘we’, ‘us’, or
              ‘our’), describes how our approval guarantee works. If USCIS
              denies your immigration application (‘petition’), we will refund
              100% of the package or service fees that you paid to SeamlessVisa
              for the petition that was denied . The following conditions apply:
            </p>
            <ul>
              <li>
                Refund requests must be submitted (a) within thirty (30) days of
                the date that the denial notice (USCIS Form I-797, Notice of
                Action) was issued by the USCIS, and (b) must include a copy of
                the USCIS denial notice stating the reason for the denial.
              </li>
              <li>
                Refunds will not be issued if your petition was denied because
                of your failure to attend your scheduled immigration interview
                or failure to provide a timely response to an RFE from USCIS.
                USCIS filing fees and other fees or taxes paid to government
                entities, or to other third parties with a role in processing
                your application order, are not refundable.
              </li>
              <li>
                Our Guarantee does not cover petitions denied because of your
                failure to follow SeamlessVisa’s directions; your provision of
                false, inaccurate, or incomplete information to SeamlessVisa;
                your failure to file or delay in filing a required form; or your
                failure to correct any errors in the forms generated by
                SeamlessVisa.
              </li>
            </ul>
            <p>
              We will review all other refund requests outside of these policies
              on a case-by-case basis.
            </p>
          </div>
        </Constaint>
      </Wrapper>
    </>
  );
};

export default GuaranteePolicy;

const Wrapper = styled.div`
  background: #d7e1f5;
  padding: 4rem 0;
  font-size: 26px;

  h1 {
    font-size: 32px;
    margin-top: 1.24rem;
    font-weight: bold;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    color: ${Colors.Black31};
    margin-bottom: 1.12rem;
  }

  p {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: ${Colors.Black31};
  }

  ul {
    list-style: disc;
    margin-bottom: 1.24rem;
  }
  li {
    margin-left: 1.54rem;
    font-size: 1rem;
    color: ${Colors.Black31};
  }
`;
