import { useCallback, useEffect, useState } from "react";
import { Message, MessageSender } from "./types";
import {
  GetInterviewHistoryResult,
  GetInterviewHistoryResultRole,
} from "../../../../../../api/ai/types";

interface Props {
  historyLoading: boolean;
  historyData?: string | GetInterviewHistoryResult[];
}

const useAllMessages = ({ historyLoading, historyData }: Props) => {
  const [chatHistory, setChatHistory] = useState<Message[]>([]);
  const [prevHistory, setPrevHistory] = useState<Message[]>();

  useEffect(() => {
    // if (typeof historyData === "string") return;
    if (historyLoading || !historyData) return;

    if (typeof historyData === "string") {
      setPrevHistory([]);
      return;
    }

    const resultObj = historyData as any;

    const messages = Object.keys(resultObj)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => resultObj[key]);

    const prevTemp = messages
      ?.filter((ev) => ev.content)
      .map((ev) => ({
        sender:
          ev.role === GetInterviewHistoryResultRole.assistant
            ? MessageSender.bot
            : MessageSender.user,
        message: ev.content,
      }));

    setPrevHistory(prevTemp || []);
  }, [historyData, historyLoading]);

  const onReset = useCallback(() => {
    setPrevHistory([]);
    setChatHistory([]);
  }, []);

  const onAddToChatHistory = useCallback((second: Message) => {
    setChatHistory((prev) => [...prev, second]);
  }, []);

  const allMessages = prevHistory ? prevHistory.concat(chatHistory) : undefined;

  return { allMessages, onAddToChatHistory, onReset };
};

export default useAllMessages;
