import React, { useRef } from "react";
import TTSAudioVisualizer from "./TTSAudioVisualizer";

interface UseTTSVisualizerProps {
  width?: number;
  height?: number;
  mediaSource: MediaElementAudioSourceNode | null;
  audioContextRef: React.MutableRefObject<AudioContext | null>;
  analyserRef: React.MutableRefObject<AnalyserNode | null>;
}

const UseTTSVisualizer: React.FC<UseTTSVisualizerProps> = ({
  width = 500,
  height = 500,
  mediaSource,
  audioContextRef,
  analyserRef,
}: UseTTSVisualizerProps) => {
  // const [isReady, setIsReady] = useState(false);
  const visualizerRef = useRef<HTMLDivElement>(null);

  // Set ready state when audio element is available
  // useEffect(() => {
  //   const checkAudio = () => {
  //     if (audioRef.current) {
  //       setIsReady(true);
  //     }
  //   };

  //   checkAudio();

  //   // Check again if audio element becomes available later
  //   const interval = setInterval(checkAudio, 500);

  //   return () => clearInterval(interval);
  // }, [audioRef]);

  return (
    <div
      ref={visualizerRef}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        // width: width ? `${width}px` : "100%",
        // height: height ? `${height}px` : "100%",
        position: "relative",
        backgroundColor: "transparent", // Visual indicator
        display: "block",
      }}
    >
      {/* {!isReady && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          Waiting for audio...
        </div>
      )} */}

      {/*  {isReady && audioRef.current && ( */}
      <TTSAudioVisualizer
        width={width}
        height={height}
        mediaSource={mediaSource}
        audioContextRef={audioContextRef}
        analyserRef={analyserRef}
      />
      {/*  )} */}
    </div>
  );
};

export default UseTTSVisualizer;
