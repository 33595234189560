import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { BillingType, fibiBillingData } from "./data";
import useGetPrice from "../../../../../../../hooks/useGetPrice";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CheckboxBall from "../../../../../components/CheckboxBall";
import { useBusinessCancelSubscription } from "../../../../../../../hooks/business/useBilling";
import useOpener from "../../../../../../../hooks/useOpener";
import { toast } from "react-toastify";
import TerminateModal from "../../../../../components/TerminateModal";

interface Props {
  data: ReturnType<typeof fibiBillingData>[0];
  onButtonClick: (billingType: BillingType) => void;
  currentPlan?: BillingType;
}

const FibiBilingCard: FC<Props> = ({ data, onButtonClick, currentPlan }) => {
  const isHighlighted = currentPlan ? data.id.includes(currentPlan) : false;
  const [isChecked, setIsChecked] = useState(false);
  const { isNigeria } = useGetPrice();
  const {
    open: unsubscribeOpen,
    onClose: unsubscribeOnClose,
    onOpen: unsubscribeOnOpen,
  } = useOpener();
  const { mutate, isLoading } = useBusinessCancelSubscription();

  const onClick = () => {
    if (data.id.includes(BillingType.starter) && isChecked) {
      onButtonClick(BillingType.starterYearly);
    } else if (data.id.includes(BillingType.growth) && isChecked) {
      onButtonClick(BillingType.growthYearly);
    } else if (data.id.includes(BillingType.starter)) {
      onButtonClick(BillingType.starter);
    } else if (data.id.includes(BillingType.growth)) {
      onButtonClick(BillingType.growth);
    }
  };

  const onUnsubscribe = () => {
    mutate(undefined, {
      onSuccess: () => {
        toast.success("Successful");
        unsubscribeOnClose();
      },
    });
  };

  useEffect(() => {
    if (
      isHighlighted &&
      (currentPlan === BillingType.growthYearly ||
        currentPlan === BillingType.starterYearly)
    ) {
      setIsChecked(true);
    }
  }, [currentPlan, isHighlighted]);

  const isCurrentPlan =
    isHighlighted &&
    (((currentPlan === BillingType.growthYearly ||
      currentPlan === BillingType.starterYearly) &&
      isChecked) ||
      ((currentPlan === BillingType.growth ||
        currentPlan === BillingType.starter) &&
        !isChecked));

  return (
    <>
      <PricingCard
        className="relative transition-all hover:shadow "
        isHighlighted={isHighlighted}
      >
        {isHighlighted && (
          <IoIosCloseCircleOutline
            className="absolute text-red-500 bg-white cursor-pointer -top-3 -right-3"
            size={30}
            onClick={unsubscribeOnOpen}
          />
        )}

        <PlanTitle>{data.title}</PlanTitle>
        <p className="mb-4 ">{data.subText}</p>

        <div className="flex flex-wrap items-center gap-2 my-2 mb-4 lg:gap-6">
          <p
            className={`flex items-baseline ${
              !isChecked ? "text-primaryColor" : ""
            }`}
          >
            <span className={`text-xl font-semibold tracking-tight`}>
              {isNigeria
                ? `₦${data.monthPrice.toLocaleString("en-US")}`
                : `$${data.dollarMonthPrice.toLocaleString("en-US")}`}
            </span>
            <span className={`text-gray-500 text-base`}>/month</span>
          </p>
          <CheckboxBall setValue={setIsChecked} value={isChecked} />
          <div className="flex items-center gap-2">
            <p
              className={`flex items-baseline ${
                isChecked ? "text-primaryColor" : ""
              }`}
            >
              <span className={`text-xl font-semibold tracking-tight`}>
                {isNigeria
                  ? `₦${data.yearPrice.toLocaleString("en-US")}`
                  : `$${data.dollarYearPrice.toLocaleString("en-US")}`}
              </span>
              <span className={`text-gray-500 text-base`}>/year</span>
            </p>

            <div className="text-sm ">
              (save{" "}
              {isNigeria
                ? `₦${data.savedYearPrice.toLocaleString("en-US")}`
                : `$${data.dollarSavedYearPrice.toLocaleString("en-US")}`}
              )
            </div>
          </div>
        </div>

        <FeatureList>
          {data.features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </FeatureList>

        <div className="flex flex-col w-full gap-2 mt-auto">
          <Button
            isHighlighted={isCurrentPlan}
            onClick={onClick}
            disabled={isCurrentPlan}
          >
            {isCurrentPlan ? "Current Plan" : data.buttonText}
          </Button>
        </div>
      </PricingCard>
      {unsubscribeOpen && (
        <TerminateModal
          title={`Cancel ${data.title} Subscription`}
          subText={`Are you sure you want to cancel your ${data.title.toLowerCase()} subscription?`}
          onClose={unsubscribeOnClose}
          onConfirm={onUnsubscribe}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default FibiBilingCard;

const PricingCard = styled.div<{
  isHighlighted?: boolean;
}>`
  border: 1px solid ${(props) => (props.isHighlighted ? "#0073ff" : "#eeeeee")};
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const PlanTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 4px;
  font-weight: bold;
`;

const FeatureList = styled.ul`
  list-style: disc;
  padding: 0;
  margin-bottom: 20px;
  margin-left: 16px;

  &:last-child {
    list-style: none;
  }

  li {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const Button = styled.button<{ isHighlighted?: boolean }>`
  background-color: ${(props) =>
    !props.isHighlighted ? "#0073ff" : "#f9fafb"};
  color: ${(props) => (props.isHighlighted ? "#6b7280" : "#fff")};
  border: 1px solid ${(props) => (!props.isHighlighted ? "#0073ff" : "#e5e7eb")};
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  cursor: ${(props) => (props.isHighlighted ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    /* background-color: #f9fafb;
    border: none; */
  }
`;
