import React, { useEffect, useRef } from "react";
import { Message, MessageSender } from "./types";
import RequestWrapper from "../../../../components/RequestWrapper";

interface ChatInterfaceProps {
  messages: Message[];
  isLoading: boolean;
  isError: boolean;
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({
  messages,
  isError,
  isLoading,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages.length]);

  return (
    <div className="flex flex-col h-full border border-gray-300 rounded-xl overflow-hidden max-h-[480px]">
      <RequestWrapper data={messages} isLoading={isLoading} isError={isError}>
        <div
          ref={chatContainerRef}
          className="flex flex-col flex-1 p-3 overflow-y-auto"
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`max-w-[70%] mb-2 p-2 rounded-2xl ${
                message.sender === MessageSender.user
                  ? "bg-primaryColor text-white self-end rounded-br-sm rounded-bl-2xl"
                  : "bg-gray-200 text-black self-start rounded-br-2xl rounded-bl-sm"
              }`}
            >
              {message.message}
            </div>
          ))}
        </div>
      </RequestWrapper>
    </div>
  );
};

export default ChatInterface;
