import React, { useEffect } from "react";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import Constaint from "../../../layout/components/Constaint";
import AiDisclaimer from "../../../components/AiDisclaimer";
import { useSearchParams } from "react-router-dom";
import { useSignin } from "../../../../../hooks/auth/useAuth";
import useLoginRes from "../../../auth/components/useLoginRes";
import {
  businessFibiClientStore,
  seamlessFibiClientToken,
} from "../../../../../store/businessFibiClient";
import storage from "../../../../../utils/storage";

import useIsLoggedIn from "../../../../../hooks/useIsLoggedIn";
import { saveBusinessVoiceInterviewChatGpt } from "../../../../../api/ai";
import FiBiAuth from "../components/FiBiAuth";
import BusinessFibiVoice from "./components/BusinessFibiVoice";
import NormalFiBiVoice from "./components/NormalFibiVoice";
import { decodeBase64 } from "./components/data";
import { utilsQueryKeys } from "../../../../../hooks/utils/types";
import { useQueryClient } from "@tanstack/react-query";

const FiBiVoice = () => {
  const isLoggedIn = useIsLoggedIn();
  const [searchParams, setSearchParams] = useSearchParams();
  const eType = searchParams.get("e");
  const pType = searchParams.get("p");
  const bType = searchParams.get("b");
  const { mutate } = useSignin();
  const { onLoginSuccess } = useLoginRes();
  const { fibiClientToken } = useSnapshot(businessFibiClientStore);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (eType && pType && bType) {
      const eTemp = decodeBase64(eType);
      const pTemp = decodeBase64(pType);
      const bTemp = decodeBase64(bType);

      const fetch = async () => {
        try {
          await saveBusinessVoiceInterviewChatGpt({
            id: bTemp,
            payload: {
              prompt: "",
              countDown: "",
            },
            sessionCount: 1,
          });

          queryClient.invalidateQueries([utilsQueryKeys.getUserData]);
        } catch (error) {
          console.error("Error generating response:", error);
        }
      };

      const payload = {
        email: eTemp,
        password: pTemp,
      };

      mutate(payload, {
        onSuccess: (data) => {
          fetch();

          onLoginSuccess(data);
          searchParams.delete("e");
          searchParams.delete("p");
          searchParams.delete("b");
          setSearchParams(searchParams);
          businessFibiClientStore.fibiClientToken = bTemp;
          storage.set(seamlessFibiClientToken, bTemp);
        },
      });
    }
  }, [
    eType,
    pType,
    bType,
    mutate,
    searchParams,
    setSearchParams,
    onLoginSuccess,
    queryClient,
  ]);

  useEffect(() => {
    document.title = `FiBi Voice Interview | Your Personal Interview Trainer`;

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, []);

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <MiniContainer>
            {!isLoggedIn && (
              <FiBiAuth isFibiClient={!!(eType && pType && bType)} />
            )}

            {fibiClientToken ? <BusinessFibiVoice /> : <NormalFiBiVoice />}

            <AiDisclaimer />
          </MiniContainer>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default FiBiVoice;

const Wrapper = styled.div`
  background-color: #f9fcff;
`;

const Container = styled.div`
  padding: 20px 0;
`;

const MiniContainer = styled.div`
  min-height: calc(100vh - 200px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  gap: 24px;

  overflow-x: hidden;
  width: 100%;
`;
