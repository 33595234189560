import { useMutation, useQuery } from "@tanstack/react-query";
import { parseError } from "../../utils";
import {
  eligibilityAnalysis,
  eligibilityCountryApprovalRate,
  getBusinessChatGptInterviewHistory,
  getBusinessChatGptInterviewVoiceHistory,
  saveBusinessInterviewTimeout,
  saveFiBiInterviewRatings,
} from "../../api/ai";
import { aiQueryKeys } from "./types";

export const useEligibilityCountryApprovalRate = () => {
  return useMutation(eligibilityCountryApprovalRate, {
    onError: (error) => parseError(error),
  });
};

export const useEligibilityAnalysis = () => {
  return useMutation(eligibilityAnalysis, {
    onError: (error) => parseError(error),
  });
};

export const useGetBusinessChatGptInterviewVoiceHistory = (
  sessionCount: string,
  businessId: string
) => {
  return useQuery(
    [
      aiQueryKeys.getBusinessChatGptInterviewVoiceHistory,
      sessionCount,
      businessId,
    ],
    () => getBusinessChatGptInterviewVoiceHistory(sessionCount, businessId),
    {
      enabled: !!sessionCount,
      onError: (error) => parseError(error),
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetBusinessChatGptInterviewHistory = (
  sessionCount: string,
  businessId: string
) => {
  return useQuery(
    [aiQueryKeys.getBusinessChatGptInterviewHistory, sessionCount, businessId],
    () => getBusinessChatGptInterviewHistory(sessionCount, businessId),
    {
      enabled: !!sessionCount,
      onError: (error) => parseError(error),
      select: (data) => data.data.result,
      refetchOnWindowFocus: false,
    }
  );
};

//
export const useSaveBusinessInterviewTimeout = () => {
  return useMutation(saveBusinessInterviewTimeout, {
    onError: (error) => parseError(error),
  });
};

export const useSaveFiBiInterviewRatings = () => {
  return useMutation(saveFiBiInterviewRatings, {
    onError: (error) => parseError(error),
  });
};
