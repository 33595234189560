import React, { FC } from "react";
import { FaInfoCircle } from "react-icons/fa";

interface Props {
  isLoading: boolean;
  isError: boolean;
  approvalRate?: string;
}

const ApprovalBox: FC<Props> = ({ isLoading, approvalRate, isError }) => {
  return (
    <div className="mb-2 -mt-12 text-sm text-primaryColor">
      {isLoading ? (
        <div>Checking approval rate...</div>
      ) : isError ? (
        <div>
          An error occured while getting the approval rate. Try again later.
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <FaInfoCircle className="flex-shrink-0 text-[#FF8503]" />
          {approvalRate}
        </div>
      )}
    </div>
  );
};

export default ApprovalBox;
