import React from "react";

import SEOMeta from "../../components/SEOMeta";
import { WebPaths } from "../../../../routes/data";
import { SVLogoSquare } from "../../../../images";
import Banner from "./components/Banner";
import How from "./components/How";
import GetInTouch from "./components/GetInTouch";
import Packages from "./components/Packages";
import { tiersData } from "./components/data";
import WhySV from "./components/why-sv";

const ForAcademy = () => {
  return (
    <>
      <SEOMeta
        title="For Academy | Maximize Visa Approval"
        description="Find out how SeamlessVisa helps Academy manage employee visa applications, streamline the process, and reduce the risk of mistakes."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.Academy}`}
        url={`https://www.seamlessvisa.com${WebPaths.Academy}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="For Academy | SeamlessVisa"
        twitterDescription="Find out how SeamlessVisa helps Academy manage employee visa applications, streamline the process, and reduce the risk of mistakes."
        twitterSite="@SeamlessVisa_"
      />
      <div className="flex flex-col gap-20 py-14">
        <Banner />
        <WhySV />
        <How />
        <Packages tiers={tiersData} />
        <GetInTouch />
      </div>
    </>
  );
};

export default ForAcademy;
