import React, { FC } from "react";
import TerminateModal from "../../../../../components/TerminateModal";
import { StaffRes } from "../../../../../../../api/business/staff/types";
import { getStaffstatusText } from "./data";
import Colors from "../../../../../../../styles/Colors";
import { useToggleStaffStatus } from "../../../../../../../hooks/business/useStaff";
import { toast } from "react-toastify";

interface Props {
  onClose: () => void;
  data: StaffRes;
}

const ToggleStaffModal: FC<Props> = ({ data, onClose }) => {
  const { mutate: toggleMutate, isLoading: toggleLoading } =
    useToggleStaffStatus();
  const statusText = getStaffstatusText(data.isArchived);

  const onToggle = () => {
    if (!data) return;
    const payload = {
      staffId: data.staffId,
      data: {
        seatType: data.seatType,
        isArchived: !data.isArchived,
      },
    };
    toggleMutate(payload, {
      onSuccess: () => {
        onClose();
        toast.success(`Staff ${statusText} Successfully!`);
      },
    });
  };

  return (
    <TerminateModal
      onClose={onClose}
      title={`${statusText} Staff`}
      subText={`Are you sure you want to ${statusText.toLowerCase()} this staff?`}
      confirmBg={data.isArchived ? Colors.Blue00 : undefined}
      isLoading={toggleLoading}
      onConfirm={onToggle}
    />
  );
};

export default ToggleStaffModal;
