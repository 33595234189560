import React from "react";
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoMail } from "react-icons/io5";
import Constaint from "../../../layout/components/Constaint";
import styled from "styled-components";

const GetInTouch = () => {
  const onCall = () => {
    window.open("tel:+2347073254161", "_blank");
  };
  const onMail = () => {
    window.open("mailto:admissions@seamlessvisa.com", "_blank");
  };
  return (
    <Wrapper>
      <Constaint>
        <div className="flex flex-wrap items-center justify-between gap-6 px-10 py-10 bg-[#E0EFFD]">
          <div className="flex flex-col gap-6 ">
            <div className="text-6xl font-spartan font-semibold">
              Get In touch
            </div>
            <div
              role="button"
              onClick={onCall}
              className="flex items-center gap-2 text-[#30393C] text-lg font-[500] cursor-pointer hover:underline"
            >
              <IoIosCall className="h-7 w-7" />
              <FaWhatsapp className="h-7 w-7" />
              +234 707 325 4161
            </div>
            <div
              role="button"
              onClick={onMail}
              className="flex items-center gap-2 text-[#30393C] text-lg font-[500] cursor-pointer hover:underline"
            >
              <MdOutlineMailOutline className="h-7 w-7" />
              admissions@seamlessvisa.com
            </div>
          </div>
          <IoMail size={150} className=" text-primaryColor" />
        </div>
      </Constaint>
    </Wrapper>
  );
};

export default GetInTouch;

const Wrapper = styled.div``;
