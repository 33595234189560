import React from "react";
import { Helmet } from "react-helmet-async";
import { WebPaths } from "../../../routes/data";

type SEOMetaProps = {
  title: string; // Page title (required)
  description: string; // Page description (required)
  canonicalUrl?: string; // Canonical URL
  locale?: string; // Page locale (e.g., en_US)
  type?: string; // Page type (e.g., website)
  url?: string; // Page URL
  siteName?: string; // Site name
  publisher?: string; // Publisher URL
  modifiedTime?: string; // Last modified time
  imageUrl?: string; // URL of the image for social media
  imageWidth?: string; // Width of the image
  imageHeight?: string; // Height of the image
  imageType?: string; // Type of the image (e.g., image/png)
  twitterCard?: string; // Twitter card type (e.g., summary_large_image)
  twitterTitle?: string; // Twitter title
  twitterDescription?: string; // Twitter description
  twitterSite?: string; // Twitter handle of the site
};

const SEOMeta: React.FC<SEOMetaProps> = ({
  title = "Loading...",
  description = "Get the expertise of an immigration lawyer without the price tag. Apply for your green card or fiancé visa online with SeamlessVisa.",
  canonicalUrl = `https://www.seamlessvisa.com${WebPaths.Root}`,
  locale = "en_US",
  type = "website",
  url = `https://seamlessvisa.com${WebPaths.Root}`,
  siteName = "SeamlessVisa",
  publisher = "https://www.facebook.com/share/1BhLA5r2Ac/",
  modifiedTime = "2024-09-16T19:56:50+00:00",
  imageUrl = "logo512.png",
  imageWidth = "1920",
  imageHeight = "1080",
  imageType = "image/png",
  twitterCard = "summary_large_image",
  twitterTitle = "SeamlessVisa: Marriage-Based Green Cards Made Easy",
  twitterDescription = "Get the expertise of an immigration lawyer without the price tag. Applying for your green card or U.S. citizenship has never been easier or more affordable.",
  twitterSite = "@SeamlessVisa_",
}) => {
  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={siteName} />
      <meta property="article:publisher" content={publisher} />
      <meta property="article:modified_time" content={modifiedTime} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:image:type" content={imageType} />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:site" content={twitterSite} />
    </Helmet>
  );
};

export default SEOMeta;
