import { FormCategories } from "../routes/type";

export interface SingleOption<V = string, L = string> {
  value: V;
  label: L;
}

export type Modify<T, R> = Omit<T, keyof R> & R;

export interface ApiResponse<T = any, G = any> {
  hasError: boolean;
  code: number;
  data: T;
  fileBuffer: G;
  message: string;
  userData?: UserDataObj;
  formProgress: string;
  url: string;
  result: string;
  id: string;
  totalPageCount: number;
  total: number;
}

export interface N400FillingUrlObj {
  n400_filing_greenURL: string;
  n400_marriage_greenURL: string;
}

export interface CaseHistoryObj {
  caseId: number | string;
  caseType: string;
  dateOpened: string;
  dateSubmitted: string;
  Status: boolean;
  case_Status: string;
  Payment: boolean;
  formDownloadUrl: string;
  filingURL: string | N400FillingUrlObj;
  g1145URL?: string;
}

export interface UserDataObj {
  caseHistory: CaseHistoryObj[];
  currentFormInfo: {
    formCategory: string;
    formProgress: string;
    paymentStatus: boolean;
    isFormCompleted: boolean;
  };
  ds160FormInfo: {
    formCategory: string;
    formProgress: string;
    isFormCompleted: boolean;
    isPaid: boolean;
    isStandard: boolean;
    started_at: string;
    submitted_at: string;
  };
  userInfo: {
    disabled: boolean;
    email: string;
    phoneNumber: string;
    emailVerified: boolean;
    isDeleted: boolean;
    name: string;
    userId: string;
    n400Paid?: boolean;
    n565Paid?: boolean;
    n600Paid?: boolean;
    ds160Paid?: boolean;
    referralCode?: string;
    referralss: string[] | number[];
    isBusinessUser: boolean;
  };
  eligibilityHistory: {
    formCategory: FormCategories;
    isEligible: boolean;
    isFilled: boolean;
  }[];
  interviewCount: null | number;
  maxSessionCount: null | number;
}

export class EnumX {
  static of<T extends object>(e: T) {
    const values = Object.values(e);
    const prevMap = new Map(values.map((k, i) => [k, values[i - 1]]));
    const map = new Map(values.map((k, i) => [k, values[i + 1]]));

    return {
      prev: <K extends keyof T>(v: T[K]) => prevMap.get(v),
      next: <K extends keyof T>(v: T[K]) => map.get(v),
    };
  }
}

export interface RefreshTokenRes {
  access_token: string;
  expires_in: string;
  token_type: string;
  refresh_token: string;
  id_token: string;
  user_id: string;
  project_id: string;
}

export interface PaginatedResponse {
  hasError: boolean;
  code: number;
  totalPageCount: number;
  dataCount: number;
}

export interface CaseData {
  caseId: number;
  caseType: string;
  dateOpened: string;
  dateSubmitted: string;
  Status: boolean;
  case_Status: string;
  progress: string;
  Payment: boolean;
  formDownloadUrl: string;
}

export type RoleTypes = "administrator" | "staff";
