import React, { useState } from "react";
import { BillingType, fibiBillingData } from "./data";
import FibiBilingCard from "./FibiBilingCard";
import useGetPrice from "../../../../../../../hooks/useGetPrice";
import {
  useBusinessBillingDowngrade,
  useBusinessBillingUpgrade,
  useSubscriptionPayment,
} from "../../../../../../../hooks/business/useBilling";
import { SpinnerWrapper } from "../../../../../components/styles";
import Spinner from "../../../../../../../utils/Spinner";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import TerminateModal from "../../../../../components/TerminateModal";
import { isBusinessDataStore } from "../../../../../../../store/isBusinessData";
import { useGetBusinessProfile } from "../../../../../../../hooks/business/useAuth";
import Colors from "../../../../../../../styles/Colors";

//
const FibiBilling = () => {
  // const { businessUserData } = useSnapshot(businessUserDataStore);
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const {
    data: businessUserData,
    isLoading: businessDataLoading,
    refetch,
  } = useGetBusinessProfile(isBusinessData);
  const { isNigeria } = useGetPrice();
  const { mutate: submutate, isLoading: subLoading } = useSubscriptionPayment();
  const { mutate: upgradeMutate, isLoading: upgradeLoading } =
    useBusinessBillingUpgrade();
  const { mutate: downgradeMutate, isLoading: downgradeLoading } =
    useBusinessBillingDowngrade();
  const [upgradeOpen, setUpgradeOpen] = useState<BillingType>();
  const [downgradeOpen, setDowngradeOpen] = useState<BillingType>();

  const onUpgradeOpen = (second: BillingType) => {
    setUpgradeOpen(second);
  };

  const onUpgradeClose = () => {
    setUpgradeOpen(undefined);
  };

  const onDowngradeOpen = (second: BillingType) => {
    setDowngradeOpen(second);
  };

  const onDowngradeClose = () => {
    setDowngradeOpen(undefined);
  };

  const onUpgrade = () => {
    if (!upgradeOpen) return;

    const payload = {
      isNigeria,
      billingType: upgradeOpen,
    };

    upgradeMutate(payload, {
      onSuccess: () => {
        onUpgradeClose();
        refetch();
        toast.success("Upgrade Successful");
      },
    });
  };

  const onDowngrade = () => {
    if (!downgradeOpen) return;
    const payload = { email: [], isNigeria, billingType: downgradeOpen };

    downgradeMutate(payload, {
      onSuccess: () => {
        onDowngradeClose();
        refetch();
        toast.success("Downgrade Successful");
      },
    });
  };

  const onSubmit = (params: BillingType) => {
    const payload = {
      billingType: params,
      isNigeria,
    };

    submutate(payload, {
      onSuccess: (data) => {
        window.location.replace(data.data.url);
      },
    });
  };

  const onClick = (params: BillingType) => {
    const checkGrade = () => {
      if (!businessUserData?.paymentInfo.subscriptionType) return;

      if (
        (businessUserData.paymentInfo.subscriptionType ===
          BillingType.starter ||
          businessUserData.paymentInfo.subscriptionType ===
            BillingType.starterYearly) &&
        (params === BillingType.growth || params === BillingType.growthYearly)
      ) {
        onUpgradeOpen(params);
      } else if (
        businessUserData.paymentInfo.subscriptionType === BillingType.starter &&
        params === BillingType.starterYearly
      ) {
        onUpgradeOpen(params);
      } else if (
        businessUserData.paymentInfo.subscriptionType ===
          BillingType.starterYearly &&
        params === BillingType.starter
      ) {
        onDowngradeOpen(params);
      } else if (
        (businessUserData.paymentInfo.subscriptionType === BillingType.growth ||
          businessUserData.paymentInfo.subscriptionType ===
            BillingType.growthYearly) &&
        (params === BillingType.starter || params === BillingType.starterYearly)
      ) {
        // onDowngrade(params);
        onDowngradeOpen(params);
      } else if (
        businessUserData.paymentInfo.subscriptionType === BillingType.growth &&
        params === BillingType.growthYearly
      ) {
        onUpgradeOpen(params);
      } else if (
        businessUserData.paymentInfo.subscriptionType ===
          BillingType.growthYearly &&
        params === BillingType.growth
      ) {
        onDowngradeOpen(params);
      }
    };

    businessUserData?.paymentInfo.subscriptionType
      ? checkGrade()
      : onSubmit(params);
  };

  const masterLoading = subLoading || businessDataLoading;

  return (
    <>
      {masterLoading ? (
        <SpinnerWrapper>
          <Spinner size={40} />
        </SpinnerWrapper>
      ) : (
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 ">
          {fibiBillingData().map((ev, index) => (
            <FibiBilingCard
              key={index}
              data={ev}
              onButtonClick={onClick}
              currentPlan={businessUserData?.paymentInfo.subscriptionType}
            />
          ))}
        </div>
      )}
      {downgradeOpen && (
        <TerminateModal
          title={`Downgrade Subscription`}
          subText={`Are you sure you want to downgrade your subscription?`}
          onClose={onDowngradeClose}
          onConfirm={onDowngrade}
          isLoading={downgradeLoading}
          confirmBg={Colors.Blue00}
        />
      )}

      {upgradeOpen && (
        <TerminateModal
          title={`Upgrade Subscription`}
          subText={`Are you sure you want to upgrade your subscription?`}
          onClose={onUpgradeClose}
          onConfirm={onUpgrade}
          isLoading={upgradeLoading}
          confirmBg={Colors.Blue00}
        />
      )}
    </>
  );
};

export default FibiBilling;
