import { ApiResponse } from "../../../types/types.js";
import { SignInRes } from "../../auth/types.js";
import request from "../../request";
import { BusinessSignInRes } from "../auth/types.js";
import {
  CreateStaffPayload,
  DeleteStaffPayload,
  StaffRes,
  ToggleStaffStatusPayload,
  UpdateStaffPayload,
} from "./types.jsx";

export const getAllStaff = async () => {
  return await request.get<ApiResponse<StaffRes[]>>(`business/get-all-staff`);
};

export const getSingleStaff = async (staffId: string) => {
  return await request.get<ApiResponse<StaffRes[]>>(
    `business/get-staff/${staffId}`
  );
};

export const createStaff = async (payload: CreateStaffPayload) => {
  return await request.post<ApiResponse>(`business/create-staff`, payload);
};

export const updateStaff = async (payload: UpdateStaffPayload) => {
  return await request.post<ApiResponse>(
    `business/update-staff/${payload.staffId}`,
    payload.data
  );
};

export const deleteStaff = async (payload: DeleteStaffPayload) => {
  return await request.post<ApiResponse>(
    `business/delete-staff/${payload.staffId}`,
    payload.data
  );
};

export const toggleStaffStatus = async (payload: ToggleStaffStatusPayload) => {
  return await request.post<ApiResponse>(
    `business/archive-staff/${payload.staffId}`,
    payload.data
  );
};

export const staffSignIn = async (payload: {
  email: string;
  password: string;
}) => {
  return await request.post<ApiResponse<BusinessSignInRes | SignInRes>, any>(
    `business/staff/signin`,
    payload
  );
};
