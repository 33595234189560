export const tiersData = [
  {
    name: "Basic", // Changed from Tier 1 to Basic
    id: "tier-basic",
    href: "https://billing-studyabroad.seamlessvisa.com/", // not used
    price: { monthly: "", annually: "", oneTime: "200" }, // added oneTime price and removed monthly/annually
    description: "", // not used, using goal instead
    goal: "Goal: Gain admission to a reputable university", // added goal to match image
    features: [
      "Educational Development Advisory",
      "Assistance with school selection based on academic profile",
      "Application preparation and submission (up to 3 schools)",
      "Guidance on required documentation",
      "Status updates on application progress",
    ],
  },
  {
    name: "Standard", // Changed from Tier 2 to Standard
    id: "tier-standard",
    href: "https://billing-studyabroad.seamlessvisa.com/", // not used
    price: { monthly: "", annually: "", oneTime: "300" }, // added oneTime price and removed monthly/annually
    description: "", // not used, using goal instead
    goal: "Goal: Gain admission to a reputable university", // added goal to match image
    features: [
      "All features of Basic", // Changed from TIER 1 to Basic
      "Essay Writing & Personal Statement Writing",
      "Recommendation Letter Counselling",
    ],
  },
  {
    name: "Premium", // Changed from Tier 3 to Premium
    id: "tier-premium",
    href: "https://billing-studyabroad.seamlessvisa.com/", // not used
    price: { monthly: "", annually: "", oneTime: "500" }, // added oneTime price and removed monthly/annually
    description: "", // not used, using goal instead
    goal: "Goal: Gain admission to a reputable university", // added goal to match image
    features: [
      "All features of Standard", // Changed from TIER 2 to Standard
      "I-20 Document",
      "Visa Application service",
    ],
  },
];
