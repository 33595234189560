import React, { FC, useEffect, useMemo, useState } from "react";
import VoiceMessageDisplay from "./VoiceMessageDisplay";
import { Message, SessionQuery } from "./types";
import Spinner from "../../../../../../utils/Spinner";
import Colors from "../../../../../../styles/Colors";
import { Document, Image, Page, StyleSheet, usePDF } from "@react-pdf/renderer";
import Logo from "../../../../../../images/icons/logo.png";
import FeedbackModal from "../../components/FeedbackModal";
import storage from "../../../../../../utils/storage";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../../store/userData";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    // margin: 10,
  },
});

interface Props {
  messages: Message[];
  fibiClientToken: string;
  sessionTemp: string;
}

const SessionEnded: FC<Props> = ({
  messages,
  fibiClientToken,
  sessionTemp,
}) => {
  const doc = useMemo(
    () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image
            style={{
              width: "170px",
              height: "35px",
              margin: "auto",
              marginBottom: "40px",
            }}
            src={Logo}
          />
          {messages && <VoiceMessageDisplay messages={messages} />}
        </Page>
      </Document>
    ),
    [messages]
  );

  const [instance, updateInstance] = usePDF({
    document: doc,
  });

  useEffect(() => {
    updateInstance(doc);
  }, [doc, updateInstance]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [toAskForFeedback, setToAskForFeedback] = useState(false);
  const { userData } = useSnapshot(userDataStore);
  const [maxSessionCount, setMaxSessionCount] = useState<number>(0);

  useEffect(() => {
    if (userData) setMaxSessionCount(userData.maxSessionCount as number);
  }, [userData]);

  useEffect(() => {
    const haveGivenFeedback = storage.get(
      `@session_${Number(sessionTemp)}_feedback`
    );

    if (!haveGivenFeedback) setToAskForFeedback(true);
  }, [sessionTemp]);

  const onStartNewSession = () => {
    if (Number(sessionTemp) >= maxSessionCount) {
      toast.error(
        "You either have an active session or have reached the maximum number of allowed sessions"
      );

      return;
    }

    searchParams.set(SessionQuery.sessionCount, `${Number(sessionTemp) + 1}`);
    setSearchParams(searchParams);
    // onInterviewOff();
  };

  const onCloseFeedbackModal = () => setToAskForFeedback(false);

  return (
    <>
      <div className="flex items-center gap-2 ">
        This session has ended.{" "}
        <a
          href={instance.url || ""}
          download="fibi-session.pdf"
          className="underline text-primaryColor"
        >
          {instance.loading ? (
            <Spinner size={16} color={Colors.Black31} />
          ) : (
            "Download session"
          )}
        </a>
      </div>
      <div
        role="button"
        onClick={onStartNewSession}
        className="bg-Blue00 text-white text-center p-4 w-3/5 mmx-auto rounded-xl hover:bg-Blue02 duration-150"
      >
        Start a New Session?
      </div>
      <FeedbackModal
        isOpen={toAskForFeedback}
        onClose={onCloseFeedbackModal}
        title="How was your interview experience with FiBi?"
        description="Please rate your interview session and provide any feedback to help us improve."
        ratingType="emoji" // or "stars"
        sessionCount={Number(sessionTemp) as number}
        businessId={fibiClientToken as string}
      />
    </>
  );
};

export default SessionEnded;
