import React from "react";
// import Hero from "./components/Hero";
// import Why from "./components/Why";
// import HowItWorks from "./components/HowItWorks";
import Pricing from "./components/Pricing";
import Cta from "./components/Cta";
import Banner from "./components/Banner";
import ToHelp from "./components/ToHelp";
import Dashboard from "./components/Dashboard";
import SEOMeta from "../../components/SEOMeta";
import { WebPaths } from "../../../../routes/data";
import { SVLogoSquare } from "../../../../images";

const BusinessService = () => {
  return (
    <>
      <SEOMeta
        title="For Businesses | Maximize Visa Approval"
        description="Find out how SeamlessVisa helps businesses manage employee visa applications, streamline the process, and reduce the risk of mistakes."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.Businesses}`}
        url={`https://www.seamlessvisa.com${WebPaths.Businesses}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="For Businesses | SeamlessVisa"
        twitterDescription="Find out how SeamlessVisa helps businesses manage employee visa applications, streamline the process, and reduce the risk of mistakes."
        twitterSite="@SeamlessVisa_"
      />
      {/* <Hero /> */}
      <Banner />
      {/* <Why /> */}
      <ToHelp />
      {/* <HowItWorks /> */}
      <Dashboard />
      <Pricing />
      <Cta />
    </>
  );
};

export default BusinessService;
