import { Form, Formik, FormikHelpers } from "formik";
import { FC, useEffect, useState } from "react";
import { FaCloudArrowUp, FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";

import styled from "styled-components";
import { SingleOption } from "../../../../../../../types/types";
import { initSelect, StaffSeatPrice } from "../../../../../components/data";
import { RoleOptions } from "./data";
import InputField from "../../../../../components/formik fields/InputField";
import SelectField from "../../../../../components/formik fields/SelectField";
import CustomButton from "../../../../../components/CustomButton";
import Modal from "./Modal";
import {
  CreateStaffPayload,
  CreateStaffSeatType,
  StaffRes,
  UpdateStaffPayload,
} from "../../../../../../../api/business/staff/types";
import {
  useCreateStaff,
  useGetAllStaff,
  useUpdateStaff,
} from "../../../../../../../hooks/business/useStaff";
import { businessUserDataStore } from "../../../../../../../store/businessUserData";
import { useSnapshot } from "valtio";
import { FaInfoCircle } from "react-icons/fa";
import Colors from "../../../../../../../styles/Colors";
import useGetPrice from "../../../../../../../hooks/useGetPrice";
// import {
//   CreateStaffPayload,
//   StaffRes,
//   UpdateStaffPayload,
// } from "../../../api/staff/types.tsx";
// import {
//   useCreateStaff,
//   useGetAllStaff,
//   useUpdateStaff,
// } from "../../../hooks/staff/useStaff.tsx";
// import { SingleOption } from "../../../types/types.ts";
// import CustomButton from "../../components/CustomButton.tsx";
// import InputField from "../../components/formik fields/InputField.tsx";
// import SelectField from "../../components/formik fields/SelectField.tsx";
// import { initSelect, RoleOptions } from "./data.tsx";
// // import Modal from "./Modal.tsx";

interface Props {
  data: StaffRes | null;
  isOpen: boolean;
  onClose: () => void;
}

export interface StaffPayload
  extends Omit<CreateStaffPayload, "role" | "seatType" | "isArchived"> {
  role: SingleOption | null;
}

const StaffForm: FC<Props> = ({ data, isOpen, onClose }) => {
  const { getPrice } = useGetPrice();
  const { businessUserData } = useSnapshot(businessUserDataStore);
  const [staffId, setStaffId] = useState("");
  const { refetch } = useGetAllStaff();
  const { mutate: createStaff, isLoading: creatingStaff } = useCreateStaff();
  const { mutate: updateStaff, isLoading: updatingStaff } = useUpdateStaff();

  const initialValues: StaffPayload = {
    name: data?.name || "",
    email: data?.email || "",
    role: initSelect(RoleOptions, data?.role),
    job_title: data?.job_title || "",
    location: data?.location || "",
  };

  useEffect(() => {
    if (data) setStaffId(data.staffId);
  }, [data]);

  const getSeatType = () =>
    businessUserData?.paymentInfo.numOfDefaultSeatsAvailable !== undefined &&
    businessUserData.paymentInfo.numOfDefaultSeatsAvailable > 0
      ? CreateStaffSeatType.default
      : CreateStaffSeatType.paid;

  const onSubmit = (
    values: StaffPayload,
    { resetForm }: FormikHelpers<StaffPayload>
  ) => {
    if (staffId) {
      const { role, ...rest } = values;

      if (!role) return;

      const payload: UpdateStaffPayload = {
        staffId,
        data: {
          ...rest,
          role: role?.value,
          seatType: getSeatType(),
          isArchived: false,
        },
      };

      updateStaff(payload, {
        onSuccess: () => {
          resetForm();
          toast.success("Staff Updated successfully");
          refetch().then((data) => data);
          onClose();
        },
      });
    }

    if (!data) {
      const { role, ...rest } = values;

      if (!role) return;

      const payload = {
        ...rest,
        role: role.value,
        seatType: getSeatType(),
        isArchived: false,
      };

      createStaff(payload, {
        onSuccess: () => {
          resetForm();
          toast.success("Staff Created successfully");
          refetch().then((data) => data);
          onClose();
        },
      });
    }
  };

  return (
    <Wrapper>
      <Modal isOpen={isOpen} onClose={onClose}>
        <SectionHeader>
          <h2>Staff Information</h2>
          <p>Fill in the staff information below and assign a role.</p>
        </SectionHeader>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form>
            <FormGrid>
              <FormField colSpan={6}>
                <InputField
                  name={"name"}
                  placeholder={"Staff Name..."}
                  label={"Staff Name"}
                />
              </FormField>
              <FormField colSpan={6}>
                <InputField
                  name={"email"}
                  placeholder={"Staff Email..."}
                  label={"Staff Email"}
                />
              </FormField>

              <FormField colSpan={6}>
                <SelectField
                  name={"role"}
                  options={RoleOptions}
                  label={"Staff Role"}
                  placeholder={"-Role-"}
                />
              </FormField>

              <FormField colSpan={6}>
                <InputField
                  name={"job_title"}
                  placeholder={"Staff Job title"}
                  label={"Job Title"}
                />
              </FormField>

              <FormField colSpan={6}>
                <InputField
                  name={"location"}
                  placeholder={"Staff Location"}
                  label={"Location"}
                />
              </FormField>
            </FormGrid>

            {getSeatType() === CreateStaffSeatType.paid && (
              <Info>
                <FaInfoCircle />
                You will be billed {getPrice(StaffSeatPrice)}/month for this
                staff
              </Info>
            )}

            <div className="flex items-center justify-center w-full mx-auto [&>button]:w-full [&>button]:justify-center">
              <CustomButton
                name={data ? "UPDATE STAFF" : "ADD NEW STAFF"}
                icon={data ? <FaCloudArrowUp /> : <FaPlus />}
                type={"submit"}
                IsLoading={creatingStaff || updatingStaff}
                isDisabled={creatingStaff || updatingStaff}
              />
            </div>
          </Form>
        </Formik>
      </Modal>
    </Wrapper>
  );
};
export default StaffForm;

const Wrapper = styled.div``;

const SectionHeader = styled.div`
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
  }

  p {
    margin-top: 0.25rem;
    font-size: 0.85rem;
    color: #5f6570; /* Tailwind's text-gray-600 */
  }
`;

const FormGrid = styled.div`
  margin: 2.5rem 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.5rem;
`;

const FormField = styled.div<{ colSpan: number; colStart?: number }>`
  grid-column: span ${(props) => props.colSpan} / span
    ${(props) => props.colSpan};
  ${(props) => props.colStart && `grid-column-start: ${props.colStart};`}
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  & > svg {
    color: ${Colors.RedDA};
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
`;
