import React, { FC } from "react";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import {
  GroupWrapper,
  Info,
  InfoBold,
  SubTitle,
  VerticalFormWrapper,
  Wrapper,
} from "./styles";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import SelectField from "../../components/formik fields/SelectField";
import FormBtn from "../../components/FormBtn";
import styled from "styled-components";
import DatePickerField from "../../components/formik fields/DatePickerField";
import InputField from "../../components/formik fields/InputField";
import {
  AcademicSpecificOptions,
  AllSpecificOptions,
  BusinessSpecificOptions,
  IntendedLengthOfStayDropDown,
  PersonPayingOptions,
  PersonPayingOptionsValues,
  PersonPayingRelationship,
  processReviewData,
  PurposeTripOptions,
  PurposeTripOptionsValues,
  USText,
} from "./data";
import RadioComp from "../../components/RadioComp";
import {
  DS160EligibilityPayload,
  saveDS160TravelObj,
} from "../../../../api/ds160/types";
import { Modify, SingleOption } from "../../../../types/types";
import {
  useGetDS160Travel,
  useSaveDS160Travel,
} from "../../../../hooks/ds160/usDS160";
import { Dayjs } from "dayjs";
import { TravelSectionValidation } from "./validation";
import GroupedSection from "./GroupedSection";
import AddAnotherBtn from "./AddAnotherBtn";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  resetDynamicFields,
  ToSaveDate,
  initDate,
  initInput,
  initSelect,
  ExplicitNGDateFormat,
  initCheck,
} from "../../components/data";
import useStatesByCountry from "../../components/useStatesByCountry";
import useToNextSection from "../../../../hooks/useToNextSection";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import useCountries from "../../components/useCountries";
import { ReviewTravelSection } from "./Review/components/data";
import useAiReview from "./useAiReview";
import { DS160PrimaryReasonForVisitingLabels } from "../../checkEligibilty/components/types";
import { BusinessQueryKey } from "../../../../api/utils/types";
import { useSearchParams } from "react-router-dom";
import { isAnonClientDataStore } from "../../../../store/isAnonClientData";
import { useSnapshot } from "valtio";

const initialLocation = {
  location: "",
};

interface LocationsPropsPayload {
  location: string;
}

export interface TravelSectionFormData
  extends Modify<
    Omit<saveDS160TravelObj, "location">,
    {
      purposeOfTrip: SingleOption | null;
      specific: SingleOption | null;
      dateOfArrival: Dayjs | null;
      dateOfDeparture: Dayjs | null;
      intendedDateOfArrival: Dayjs | null;
      intendedLengthOfStayDropDown: SingleOption | null;
      state: SingleOption | null;
      personPaying: SingleOption | null;
      locationArray: LocationsPropsPayload[];
      personPayingRelationship: SingleOption | null;
      personPayingState: SingleOption | null;
      personPayingCountry: SingleOption | null;
      companyPayingCountry: SingleOption | null;
      companyPayingState: SingleOption | null;
    }
  > {}

interface Props {
  formEligibility: DS160EligibilityPayload;
}

const TravelSection: FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160Travel({
    applicationId: getApplicationId,
    buid: isAnonClientData,
  });
  const { mutate, isLoading: isSaving } = useSaveDS160Travel();
  const {
    formattedCountryData: personPayingCountry,
    countryLoading: personCountryLoading,
  } = useCountries();
  const {
    formattedStateData: personPayingState,
    stateLoading: personPayingStateLoading,
    selectedCountry: personPayingSelectedCountry,
    setCountry: personPayingSetCountry,
  } = useStatesByCountry(data?.personPayingCountry);

  const {
    formattedCountryData: companyPayingCountry,
    countryLoading: companyCountryLoading,
  } = useCountries();
  const {
    formattedStateData: companyPayingState,
    stateLoading: companyPayingStateLoading,
    selectedCountry: companyPayingSelectedCountry,
    setCountry: companyPayingSetCountry,
  } = useStatesByCountry(data?.companyPayingCountry);
  const { formattedStateData, stateLoading } = useStatesByCountry(USText);
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const getDefaultPurposeOfTrip = () => {
    if (
      formEligibility.primary_reason_for_visiting_us ===
        DS160PrimaryReasonForVisitingLabels["Vacation/Tourism"] ||
      formEligibility.primary_reason_for_visiting_us ===
        DS160PrimaryReasonForVisitingLabels["Business trip"]
    ) {
      return PurposeTripOptions.find(
        (ev) =>
          ev.value ===
          PurposeTripOptionsValues["Temp. Business Pleasure Visitor"]
      )?.value;
    }

    if (
      formEligibility.primary_reason_for_visiting_us ===
      DS160PrimaryReasonForVisitingLabels["To study"]
    ) {
      return PurposeTripOptions.find(
        (ev) =>
          ev.value === PurposeTripOptionsValues["Academic or Language Student"]
      )?.value;
    }
  };

  const getSpecificOptions = (formik: FormikProps<TravelSectionFormData>) => {
    const purposeOfTrip = formik.values.purposeOfTrip?.value;
    if (
      purposeOfTrip ===
      PurposeTripOptionsValues["Temp. Business Pleasure Visitor"]
    ) {
      return BusinessSpecificOptions;
    }

    if (
      purposeOfTrip === PurposeTripOptionsValues["Academic or Language Student"]
    ) {
      return AcademicSpecificOptions;
    }

    return AllSpecificOptions;
  };

  const defaultPurposeOfTrip = getDefaultPurposeOfTrip();

  const initialValues: TravelSectionFormData = {
    purposeOfTrip: initSelect(
      PurposeTripOptions,
      data?.purposeOfTrip || defaultPurposeOfTrip
    ),
    specific: initSelect(AllSpecificOptions, data?.specific),
    haveYouMadeSpecificTravelPlans: initInput(
      data?.haveYouMadeSpecificTravelPlans
    ),
    dateOfArrival: initDate(data?.dateOfArrival, ExplicitNGDateFormat),
    arrivalFlight: initInput(data?.arrivalFlight),
    arrivalCity: initInput(data?.arrivalCity),
    dateOfDeparture: initDate(data?.dateOfDeparture, ExplicitNGDateFormat),
    departureFlight: initInput(data?.departureFlight),
    departureCity: initInput(data?.departureCity),
    locationArray: data?.location
      ? data?.location.split(",").map((ev) => ({ location: ev }))
      : [initialLocation],
    intendedDateOfArrival: initDate(
      data?.intendedDateOfArrival,
      ExplicitNGDateFormat
    ),
    intendedLengthOfStayDuration: initInput(data?.intendedLengthOfStayDuration),
    intendedLengthOfStayDropDown: initSelect(
      IntendedLengthOfStayDropDown,
      data?.intendedLengthOfStayDropDown
    ),
    streetAddressLine1: initInput(data?.streetAddressLine1),
    streetAddressLine2: initInput(data?.streetAddressLine2),
    city: initInput(data?.city),
    state: initSelect(formattedStateData, data?.state),
    zipCode: initInput(data?.zipCode),
    personPaying: initSelect(PersonPayingOptions, data?.personPaying),
    personPayingSurnames: initInput(data?.personPayingSurnames),
    personPayingGivenNames: initInput(data?.personPayingGivenNames),
    personPayingTelephone: initInput(data?.personPayingTelephone),
    personPayingEmail: initInput(data?.personPayingEmail),
    doesNotApplyPersonPayingEmail: initCheck(
      data?.doesNotApplyPersonPayingEmail
    ),
    personPayingRelationship: initSelect(
      PersonPayingRelationship,
      data?.personPayingRelationship
    ),
    personPayingIsSameAddress: initInput(data?.personPayingIsSameAddress),
    personPayingStreetAddressLine1: initInput(
      data?.personPayingStreetAddressLine1
    ),
    personPayingStreetAddressLine2: initInput(
      data?.personPayingStreetAddressLine2
    ),
    personPayingCity: initInput(data?.personPayingCity),
    personPayingState: initSelect(formattedStateData, data?.personPayingState),
    personPayingZipCode: initInput(data?.personPayingZipCode),
    personPayingCountry: initSelect(
      personPayingCountry,
      data?.personPayingCountry
    ),
    doesNotApplyPersonPayingState: initCheck(
      data?.doesNotApplyPersonPayingState
    ),
    doesNotApplyPersonPayingZipCode: initCheck(
      data?.doesNotApplyPersonPayingZipCode
    ),
    companyPayingName: initInput(data?.companyPayingName),
    companyPayingTelephone: initInput(data?.companyPayingTelephone),
    companyPayingRelationship: initInput(data?.companyPayingRelationship),
    companyPayingStreetAddressLine1: initInput(
      data?.companyPayingStreetAddressLine1
    ),
    companyPayingStreetAddressLine2: initInput(
      data?.companyPayingStreetAddressLine2
    ),
    companyPayingCity: initInput(data?.companyPayingCity),
    companyPayingCountry: initSelect(
      companyPayingCountry,
      data?.companyPayingCountry
    ),
    companyPayingState: initSelect(
      companyPayingState,
      data?.companyPayingState
    ),
    doesNotApplyCompanyPayingState: initCheck(
      data?.doesNotApplyCompanyPayingState
    ),
    companyPayingZipCode: initInput(data?.companyPayingZipCode),
    doesNotApplyCompanyPayingZipCode: initCheck(
      data?.doesNotApplyCompanyPayingZipCode
    ),
  };

  const onSubmit = (values: TravelSectionFormData) => {
    const {
      purposeOfTrip,
      specific,
      dateOfArrival,
      dateOfDeparture,
      intendedDateOfArrival,
      intendedLengthOfStayDropDown,
      state,
      personPaying,
      locationArray,
      personPayingRelationship,
      personPayingCountry,
      personPayingState,
      companyPayingCountry,
      companyPayingState,
      ...rest
    } = values;

    if (!purposeOfTrip || !specific) return;

    const payload = {
      ...rest,
      purposeOfTrip: purposeOfTrip.value,
      specific: specific.value,
      dateOfArrival: ToSaveDate(dateOfArrival, ExplicitNGDateFormat),
      dateOfDeparture: ToSaveDate(dateOfDeparture, ExplicitNGDateFormat),
      intendedDateOfArrival: ToSaveDate(
        intendedDateOfArrival,
        ExplicitNGDateFormat
      ),
      intendedLengthOfStayDropDown: intendedLengthOfStayDropDown?.value || "",
      state: state?.value || "",
      personPaying: personPaying?.value || "",
      location: locationArray.map((ev) => ev.location).join(","),
      personPayingRelationship: personPayingRelationship?.value || "",
      personPayingCountry: personPayingCountry?.value || "",
      personPayingState: personPayingState?.value || "",
      companyPayingCountry: companyPayingCountry?.value || "",
      companyPayingState: companyPayingState?.value || "",
    };

    const section = ReviewTravelSection(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      travel: {
        ...riskData?.travel,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId, buid: isAnonClientData },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || (!isAnonClientData && riskLoading)}
      isError={isError || (!isAnonClientData && riskError)}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your travel
            plans.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={TravelSectionValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <SelectField
                  name="purposeOfTrip"
                  label="Purpose of Trip to the U.S"
                  placeholder="-Select-"
                  options={PurposeTripOptions}
                  onChange={() => {
                    formik.values.specific = null;
                  }}
                />
                <SelectField
                  name="specific"
                  label="Specific"
                  placeholder="-Select-"
                  isDisabled={!formik.values.purposeOfTrip?.value}
                  options={getSpecificOptions(formik)}
                />
                <RadioComp
                  title="Have you made specific travel plans?"
                  name="haveYouMadeSpecificTravelPlans"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "dateOfArrival", value: null },
                      { name: "arrivalFlight", value: "" },
                      { name: "arrivalCity", value: "" },
                      { name: "dateOfDeparture", value: null },
                      { name: "departureFlight", value: "" },
                      { name: "departureCity", value: "" },
                      { name: "locationArray", value: [initialLocation] },
                      { name: "intendedDateOfArrival", value: null },
                      { name: "intendedLengthOfStayDuration", value: "" },
                      { name: "intendedLengthOfStayDropDown", value: null },
                      { name: "streetAddressLine1", value: "" },
                      { name: "streetAddressLine2", value: "" },
                      { name: "city", value: "" },
                      { name: "state", value: null },
                      { name: "zipCode", value: "" },
                      { name: "personPaying", value: null },
                    ]);
                  }}
                />

                {formik.values.haveYouMadeSpecificTravelPlans ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <DatePickerField
                      name="dateOfArrival"
                      label="Date of Arrival in U.S"
                      placeholder={ExplicitNGDateFormat}
                      format={ExplicitNGDateFormat}
                      disablePast
                    />
                    <InputField
                      label="Arrival Flight"
                      coloredLabel="(If known)"
                      placeholder=""
                      name="arrivalFlight"
                    />
                    <InputField
                      label="Arrival City"
                      placeholder=""
                      name="arrivalCity"
                    />
                    <DatePickerField
                      name="dateOfDeparture"
                      label="Date of Departure from U.S"
                      placeholder={ExplicitNGDateFormat}
                      format={ExplicitNGDateFormat}
                      disablePast
                    />
                    <InputField
                      label="Departure Flight"
                      coloredLabel="(If known)"
                      placeholder=""
                      name="departureFlight"
                    />
                    <InputField
                      label="Departure City"
                      placeholder=""
                      name="departureCity"
                    />
                    <GroupWrapper>
                      <LocationLabel>
                        Provide the locations you plan to visit in the U.S
                      </LocationLabel>
                      <FieldArray
                        name="locationArray"
                        render={(arrayHelpers) => (
                          <div>
                            {formik.values.locationArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <InputField
                                  name={`locationArray[${index}].location`}
                                  label={`Location ${
                                    formik.values.locationArray.length === 1
                                      ? ""
                                      : index + 1
                                  }`}
                                  placeholder=""
                                />
                              </GroupedSection>
                            ))}
                            <AddAnotherBtn
                              onClick={() => arrayHelpers.push(initialLocation)}
                            />
                          </div>
                        )}
                      />
                    </GroupWrapper>
                  </>
                )}

                {formik.values.haveYouMadeSpecificTravelPlans ===
                  GenericYesNoOptionsValue.no && (
                  <>
                    <DatePickerField
                      name="intendedDateOfArrival"
                      label="Intended Date of Arrival"
                      placeholder={ExplicitNGDateFormat}
                      disablePast
                    />
                    <IntendedLengthOfSTayFlex>
                      <div>
                        <InputField
                          label="Intended Length of Stay in U.S"
                          placeholder=""
                          name="intendedLengthOfStayDuration"
                        />
                      </div>
                      <div>
                        <SelectField
                          name="intendedLengthOfStayDropDown"
                          label="Timeframe"
                          placeholder="-Select-"
                          options={IntendedLengthOfStayDropDown}
                        />
                      </div>
                    </IntendedLengthOfSTayFlex>
                  </>
                )}

                {(formik.values.haveYouMadeSpecificTravelPlans ===
                  GenericYesNoOptionsValue.yes ||
                  formik.values.haveYouMadeSpecificTravelPlans ===
                    GenericYesNoOptionsValue.no) && (
                  <>
                    <Sub>Address where you will stay in the U.S</Sub>
                    <InputField
                      label="Street Address (Line 1)"
                      placeholder=""
                      name="streetAddressLine1"
                    />
                    <InputField
                      label="Street Address (Line 2)"
                      coloredLabel="*Optional"
                      placeholder=""
                      name="streetAddressLine2"
                    />
                    <InputField label="City" placeholder="" name="city" />
                    <SelectField
                      name="state"
                      label="State"
                      placeholder="-Select-"
                      options={formattedStateData}
                      isLoading={stateLoading}
                    />
                    <InputField
                      label="Zip Code"
                      coloredLabel="(If known)"
                      placeholder=""
                      name="zipCode"
                    />
                    <SelectField
                      name="personPaying"
                      label="Person Or Entity Paying For Your Trip"
                      placeholder="-Select-"
                      options={PersonPayingOptions}
                    />

                    {formik.values.personPaying?.value ===
                      PersonPayingOptionsValues["Other Person"] && (
                      <>
                        <GroupWrapper>
                          <LocationLabel>
                            Provide the following information:
                          </LocationLabel>
                          <div className="flex flex-col gap-4">
                            <InputField
                              name="personPayingSurnames"
                              label="Surnames of Person Paying for Trip"
                              placeholder=""
                            />
                            <InputField
                              name="personPayingGivenNames"
                              label="Given Names of Person Paying for Trip"
                              placeholder=""
                            />
                            <InputField
                              name="personPayingTelephone"
                              label="Telephone Number"
                              placeholder=""
                            />
                            <div className="flex flex-col gap-2 ">
                              <InputField
                                name="personPayingEmail"
                                label="Email Address"
                                placeholder="e.g. emailaddress@example.com"
                                isDisabled={
                                  !!formik.values.doesNotApplyPersonPayingEmail
                                }
                              />

                              <CheckBoxField
                                name="doesNotApplyPersonPayingEmail"
                                label="Does not apply"
                                onChange={() => {
                                  resetDynamicFields(formik, [
                                    { name: "personPayingEmail", value: "" },
                                  ]);
                                }}
                              />
                            </div>
                            <SelectField
                              name="personPayingRelationship"
                              label="Relationship to You"
                              options={PersonPayingRelationship}
                            />
                            <RadioComp
                              title="Is the address of the party paying for your trip the same as your Home or Mailing Address?"
                              name="personPayingIsSameAddress"
                              options={GenericYesNo}
                            />
                          </div>
                        </GroupWrapper>

                        {formik.values.personPayingIsSameAddress ===
                          GenericYesNoOptionsValue.no && (
                          <>
                            <Sub>Address of Person Paying</Sub>
                            <InputField
                              label="Street Address (Line 1)"
                              placeholder=""
                              name="personPayingStreetAddressLine1"
                            />
                            <InputField
                              label="Street Address (Line 2)"
                              coloredLabel="*Optional"
                              placeholder=""
                              name="personPayingStreetAddressLine2"
                            />
                            <InputField
                              label="City"
                              placeholder=""
                              name="personPayingCity"
                            />
                            <SelectField
                              name="personPayingCountry"
                              label="Country"
                              options={personPayingCountry}
                              isLoading={personCountryLoading}
                              onChange={(value) => {
                                personPayingSetCountry(value.label);
                                formik.setFieldValue("personPayingState", null);
                              }}
                            />

                            <div className="flex flex-col gap-2 ">
                              <SelectField
                                name="personPayingState"
                                label="State"
                                placeholder="-Select-"
                                options={personPayingState}
                                isLoading={personPayingStateLoading}
                                isDisabled={
                                  !personPayingSelectedCountry ||
                                  !!formik.values.doesNotApplyPersonPayingState
                                }
                              />

                              <CheckBoxField
                                name="doesNotApplyPersonPayingState"
                                label="Does not apply"
                                onChange={() => {
                                  resetDynamicFields(formik, [
                                    { name: "personPayingState", value: null },
                                  ]);
                                }}
                              />
                            </div>

                            <div className="flex flex-col gap-2 ">
                              <InputField
                                label="Postal Zone/ZIP Code"
                                placeholder=""
                                name="personPayingZipCode"
                                isDisabled={
                                  !!formik.values
                                    .doesNotApplyPersonPayingZipCode
                                }
                              />

                              <CheckBoxField
                                name="doesNotApplyPersonPayingZipCode"
                                label="Does not apply"
                                onChange={() => {
                                  resetDynamicFields(formik, [
                                    { name: "personPayingZipCode", value: "" },
                                  ]);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {/*  */}
                  </>
                )}

                {formik.values.personPaying?.value ===
                  PersonPayingOptionsValues["Other Company/Organization"] && (
                  <GroupWrapper>
                    <LocationLabel>
                      Provide the following information:
                    </LocationLabel>
                    <div className="flex flex-col gap-4">
                      <InputField
                        name="companyPayingName"
                        label="Name of Company/Organization Paying for Trip"
                        placeholder=""
                      />
                      <InputField
                        name="companyPayingTelephone"
                        label="Telephone Number"
                        placeholder=""
                      />

                      <InputField
                        name="companyPayingRelationship"
                        label="Relationship to You"
                        placeholder=""
                      />
                      <>
                        <Sub>Address of Company/Organization Paying</Sub>
                        <InputField
                          label="Street Address (Line 1)"
                          placeholder=""
                          name="companyPayingStreetAddressLine1"
                        />
                        <InputField
                          label="Street Address (Line 2)"
                          coloredLabel="*Optional"
                          placeholder=""
                          name="companyPayingStreetAddressLine2"
                        />
                        <InputField
                          label="City"
                          placeholder=""
                          name="companyPayingCity"
                        />
                        <SelectField
                          name="companyPayingCountry"
                          label="Country"
                          options={companyPayingCountry}
                          isLoading={companyCountryLoading}
                          onChange={(value) => {
                            companyPayingSetCountry(value.label);
                            formik.setFieldValue("companyPayingState", null);
                          }}
                        />

                        <div className="flex flex-col gap-2 ">
                          <SelectField
                            name="companyPayingState"
                            label="State"
                            placeholder="-Select-"
                            options={companyPayingState}
                            isLoading={companyPayingStateLoading}
                            isDisabled={
                              !companyPayingSelectedCountry ||
                              !!formik.values.doesNotApplyCompanyPayingState
                            }
                          />

                          <CheckBoxField
                            name="doesNotApplyCompanyPayingState"
                            label="Does not apply"
                            onChange={() => {
                              resetDynamicFields(formik, [
                                { name: "companyPayingState", value: null },
                              ]);
                            }}
                          />
                        </div>

                        <div className="flex flex-col gap-2 ">
                          <InputField
                            label="Postal Zone/ZIP Code"
                            placeholder=""
                            name="companyPayingZipCode"
                            isDisabled={
                              !!formik.values.doesNotApplyCompanyPayingZipCode
                            }
                          />

                          <CheckBoxField
                            name="doesNotApplyCompanyPayingZipCode"
                            label="Does not apply"
                            onChange={() => {
                              resetDynamicFields(formik, [
                                { name: "companyPayingZipCode", value: "" },
                              ]);
                            }}
                          />
                        </div>
                      </>
                    </div>
                  </GroupWrapper>
                )}
              </FormWrapper>
              <FormBtn
                isLoading={isSaving || riskSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default TravelSection;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    /* width: 50%; */
  }
`;

const Sub = styled(SubTitle)`
  margin-bottom: 0;
`;

const LocationLabel = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 16px;
`;

const IntendedLengthOfSTayFlex = styled.div`
  display: flex;
  gap: 8px;

  & > div {
    flex: 1;
  }
`;
