import { ApiResponse } from "../../../types/types";
import request from "../../request";
import {
  BusinessBillingDowngradePayload,
  BusinessBillingUpgradePayload,
  ChoosePaymentModePayload,
  SubscribeAdditionalStaffSeatPayload,
  SubscriptionPaymentPayload,
  SubscriptionPaymentRes,
} from "./types";

export const choosePaymentMode = async (payload: ChoosePaymentModePayload) => {
  return await request.post(`business/choose-payment-mode`, payload);
};

export const subscriptionPayment = async (
  payload: SubscriptionPaymentPayload
) => {
  const { isNigeria, ...rest } = payload;

  return await request.post<SubscriptionPaymentRes>(
    `business/${
      isNigeria ? "paystack-subscription-payment" : "subscription-payment"
    }`,
    rest
  );
};

export const businessSubscriptionSuccess = async (session_id: string) => {
  return await request.get<ApiResponse>(
    `business/subscription-success?session_id=${session_id}`
  );
};

export const businessSubscriptionFailure = async (session_id: string) => {
  return await request.get<ApiResponse>(
    `business/subscription-cancel?session_id=${session_id}`
  );
};

export const subscribeAdditionalStaffSeat = async (
  payload: SubscribeAdditionalStaffSeatPayload
) => {
  return await request.post<SubscriptionPaymentRes>(
    `business/subscribe-additional-staff-seat`,
    payload
  );
};

export const businessSubscriptionStaffSeatSuccess = async (
  session_id: string
) => {
  return await request.get<ApiResponse>(
    `business/staff-subscription-success?session_id=${session_id}`
  );
};

export const businessSubscriptionStaffSeatFailure = async (
  session_id: string
) => {
  return await request.get<ApiResponse>(
    `business/staff-subscription-cancel?session_id=${session_id}`
  );
};

export const businessBillingDowngrade = async (
  payload: BusinessBillingDowngradePayload
) => {
  const { isNigeria, ...rest } = payload;
  return await request.post<SubscriptionPaymentRes>(
    `/business/${
      isNigeria ? "paystack-downgrade-subscription" : "downgrade-subscription"
    }`,
    rest
  );
};

export const businessBillingUpgrade = async (
  payload: BusinessBillingUpgradePayload
) => {
  const { isNigeria, ...rest } = payload;
  return await request.post<SubscriptionPaymentRes>(
    `/business/${
      isNigeria ? "paystack-upgrade-subscription" : "upgrade-subscription"
    }`,
    rest
  );
};

export const businessCancelSubscription = async () => {
  return await request.post(`/business/cancel-subscription`);
};
