import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Constaint from "../layout/components/Constaint";
import LeftSideBar from "../components/LeftSideBar";
import { ds160leftSideBarData } from "./components/data";
import RightSectionWrapper from "../components/RightSectionWrapper";
import PIPersonal1 from "./components/Personal info/PIPersonal1";
import {
  FormDS160Progress,
  PayModalParam,
  PayStackParam,
} from "./components/types";
import PIPersonal2 from "./components/Personal info/PIPersonal2";
import TravelSection from "./components/TravelSection";
import TravelCompSection from "./components/TravelCompSection";
import PrevUSTravelSection from "./components/PrevUSTravelSection";
import AddressAndPhone from "./components/AddressAndPhone";
import Passport from "./components/Passport";
import USContact from "./components/USContact";
import FamilyRelative from "./components/FamilyRelatives/FamilyRelatives";
import StudentExchangeVisaSEVIS from "./components/Student exchange visa/Sevis";
import StudentExchangeVisaAdditional from "./components/Student exchange visa/AdditionalContact";
import WETAdditional from "./components/Work edu training/Additional";
import WETPresent from "./components/Work edu training/WPresent";
import WETPrevious from "./components/Work edu training/Previous";
import SecurityBackgroundP1 from "./components/Security-Background/Part1";
import SecurityBackgroundP2 from "./components/Security-Background/Part2";
import SecurityBackgroundP3 from "./components/Security-Background/Part3";
import SecurityBackgroundP4 from "./components/Security-Background/Part4";
import SecurityBackgroundP5 from "./components/Security-Background/Part5";
import DS160Review from "./components/Review";
import useFormRouteGuard from "../../../hooks/useFormRouteGuard";
import {
  useGetDS160Eligibility,
  usePayDS160,
} from "../../../hooks/ds160/usDS160";
import { formEligibilityStore } from "../../../store/formEligibilityData";
import useIsPaid from "../../../hooks/useIsPaid";
import PricingModal from "../components/PricingModal";
import { pricingData } from "../components/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WebPaths } from "../../../routes/data";
import DS160PayFail from "./components/DS160PayFail";
import Ds160PayModal from "./components/Ds160PayModal";
import { userDataStore } from "../../../store/userData";
import { useSnapshot } from "valtio";
import MaritalStatus from "./components/FamilyRelatives/MaritalStatus";
import { DS160PrimaryReasonForVisitingLabels } from "../checkEligibilty/components/types";
import FibiSidebar from "./components/FibiSidebar";
import { BusinessQueryKey } from "../../../api/utils/types";
import { useGetSingleBusinessApplication } from "../../../hooks/business/useBusinessApplications";
import { isBusinessDataStore } from "../../../store/isBusinessData";
import { applicationDataStore } from "../../../store/applicationData";
import useGetPrice from "../../../hooks/useGetPrice";
import useAnonClient from "./hook/useAnonClient";
import { isAnonClientDataStore } from "../../../store/isAnonClientData";

const DS160 = () => {
  const { isNigeria } = useGetPrice();
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const { userData } = useSnapshot(userDataStore);
  const { getType, lastProgress } = useFormRouteGuard(FormDS160Progress);

  const [searchParams, setSearchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data } = useGetDS160Eligibility(
    undefined,
    getApplicationId,
    isAnonClientData
  );
  formEligibilityStore.formEligibility = data;
  const { data: singleApplicationData } = useGetSingleBusinessApplication(
    getApplicationId,
    !!getApplicationId
  );
  applicationDataStore.applicationData = singleApplicationData;
  useAnonClient();

  const { mutate: payMutate, isLoading: isPaying } = usePayDS160();

  const isSuccess = searchParams.get(PayModalParam.pay);
  const isFail = searchParams.get("fail");

  const [selectPlan, setSelectPlan] = useState<boolean>(false);

  const isToStudy =
    data?.primary_reason_for_visiting_us ===
    DS160PrimaryReasonForVisitingLabels["To study"];

  const { isFormPaid } = useIsPaid();
  const navigate = useNavigate();
  // the onPay function for N400 having plan options,
  // so we use the API type data `payN400Data`; {amount: string; plan: string;}
  const onPay = (plan: string, couponCode: string) => {
    payMutate(
      // the data is supposed to go in here, but I'm so confused on how to do that 🥲
      { plan, couponCode, applicationId: getApplicationId, isNigeria },
      {
        onSuccess: (data) => {
          window.location.replace(data.data.url);
        },
      }
    );
  };

  const onSelectPlan = () => setSelectPlan(true);

  const getBusinessShowModal = () => {
    if (singleApplicationData?.applicationInfo?.isFreeTrialApplication) {
      return false;
    } else {
      return !singleApplicationData?.applicationInfo?.isPaid;
    }
  };

  useEffect(() => {
    const trxrefParam = searchParams.get(PayStackParam.trxref);
    const referenceParam = searchParams.get(PayStackParam.reference);

    if (trxrefParam) {
      searchParams.delete(PayStackParam.trxref);
    }

    if (referenceParam) {
      searchParams.delete(PayStackParam.reference);
    }
    if (trxrefParam || referenceParam) {
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <LeftSideBar
            title={`${
              isToStudy ? "F1/F2" : "B1/B2"
            } Application for Non-immigrant Visa`}
            data={ds160leftSideBarData(isToStudy)}
            storeProgress={
              getApplicationId
                ? singleApplicationData?.applicationInfo?.formProgressCount
                : lastProgress
            }
            progressEnum={FormDS160Progress}
            isDefaultHidden
          />

          {/* MODAL */}

          {(isBusinessData
            ? getBusinessShowModal()
            : (userData && !isFormPaid) || selectPlan) && (
            <PricingModal
              pricing={
                isToStudy
                  ? pricingData.StudentVisaPricing
                  : pricingData.VisitorsVisaPricing
              }
              onModalClose={() => navigate(WebPaths.Profile)}
              onContinue={onPay}
              isDs160
            />
          )}

          {isSuccess && <Ds160PayModal />}

          {isFail && (
            <DS160PayFail onRetry={onSelectPlan} isRetrying={isPaying} />
          )}

          {/* Right section */}
          {
            <>
              {/* {getType === FormDS160Progress.getStarted && (
                <RightSectionWrapper
                  title="1. Get Started"
                  subText="JPEG is the only supported image format."
                  sectionComp={<GetStartedSection />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )} */}

              {getType === FormDS160Progress.personalInfoP1 && data && (
                <RightSectionWrapper
                  title="1. Personal Information"
                  subText=""
                  sectionComp={<PIPersonal1 formEligibility={data} />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}

              {getType === FormDS160Progress.personalInfoP2 && (
                <RightSectionWrapper
                  title="1. Personal Information"
                  subText=""
                  sectionComp={<PIPersonal2 />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}

              {getType === FormDS160Progress.travel && data && (
                <RightSectionWrapper
                  title="2. Travel Information"
                  subText=""
                  sectionComp={<TravelSection formEligibility={data} />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.travelCompanions && (
                <RightSectionWrapper
                  title="3. Travel Companion"
                  subText=""
                  sectionComp={<TravelCompSection />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.previousUSTravel && (
                <RightSectionWrapper
                  title="4. Previous US Travel"
                  subText=""
                  sectionComp={<PrevUSTravelSection />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.addressAndPhone && (
                <RightSectionWrapper
                  title="5. Address and Phone"
                  subText=""
                  sectionComp={<AddressAndPhone />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.passport && (
                <RightSectionWrapper
                  title="6. Passport"
                  subText=""
                  sectionComp={<Passport />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.usContact && (
                <RightSectionWrapper
                  title="7. US Contact"
                  subText=""
                  sectionComp={<USContact />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.familyRelatives && (
                <RightSectionWrapper
                  title="8. Family"
                  subText=""
                  sectionComp={<FamilyRelative />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.maritalStatus && (
                <RightSectionWrapper
                  title="9. Family"
                  subText=""
                  sectionComp={<MaritalStatus />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.WETPresent && (
                <RightSectionWrapper
                  title="10. Work/Education/Training"
                  subText=""
                  sectionComp={<WETPresent />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.WETPrevious && (
                <RightSectionWrapper
                  title="10. Work/Education/Training"
                  subText=""
                  sectionComp={<WETPrevious />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.WETAdditional && data && (
                <RightSectionWrapper
                  title="10. Work/Education/Training"
                  subText=""
                  sectionComp={<WETAdditional formEligibility={data} />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}

              {getType === FormDS160Progress.securityBackgroundP1 && (
                <RightSectionWrapper
                  title="11. Security and Background"
                  subText=""
                  sectionComp={<SecurityBackgroundP1 />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.securityBackgroundP2 && (
                <RightSectionWrapper
                  title="11. Security and Background"
                  subText=""
                  sectionComp={<SecurityBackgroundP2 />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.securityBackgroundP3 && (
                <RightSectionWrapper
                  title="11. Security and Background"
                  subText=""
                  sectionComp={<SecurityBackgroundP3 />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.securityBackgroundP4 && (
                <RightSectionWrapper
                  title="11. Security and Background"
                  subText=""
                  sectionComp={<SecurityBackgroundP4 />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.securityBackgroundP5 && (
                <RightSectionWrapper
                  title="11. Security and Background"
                  subText=""
                  sectionComp={<SecurityBackgroundP5 />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}

              {getType === FormDS160Progress.studentExchangeVisaSEVIS && (
                <RightSectionWrapper
                  title="12. Student Exchange Visa"
                  subText=""
                  sectionComp={
                    <StudentExchangeVisaSEVIS isToStudy={isToStudy} />
                  }
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.studentExchangeVisaAdditional && (
                <RightSectionWrapper
                  title="12. Student Exchange Visa"
                  subText=""
                  sectionComp={
                    <StudentExchangeVisaAdditional isToStudy={isToStudy} />
                  }
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
              {getType === FormDS160Progress.review && (
                <RightSectionWrapper
                  title={
                    isAnonClientData
                      ? "Form Completed"
                      : `${isToStudy ? "13" : "12"}. Review and Download`
                  }
                  subHead={
                    isAnonClientData ? "" : "Review and Download - DS160"
                  }
                  subText={
                    isAnonClientData
                      ? ""
                      : `Please take a moment to review your answers before submitting your visa application. Ensure that all information is accurate and complete. You can make edits by clicking the 'Edit' button next to each section.`
                  }
                  sectionComp={<DS160Review isToStudy={isToStudy} />}
                  getType={getType}
                  enumVal={FormDS160Progress}
                />
              )}
            </>
          }

          <FibiSidebar />
          {/* <ChatSystem formName="DS160" /> */}
          {/* <AiReviewBox /> */}
          {/* <AiReviewFloat /> */}
          {/* <AiChatGptReviewFloat /> */}
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default DS160;

const Wrapper = styled.div`
  margin: 28px 0;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-bottom: 170px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 2fr 1.5fr;
  }
`;
