import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WebPaths } from "../../../../../../../routes/data";
import ItemAction from "../../../../../components/table/ItemAction";
import {
  CreateStaffSeatType,
  StaffRes,
} from "../../../../../../../api/business/staff/types";
import { useDeleteStaff } from "../../../../../../../hooks/business/useStaff";
import useOpener from "../../../../../../../hooks/useOpener";
import TerminateModal from "../../../../../components/TerminateModal";
import { getStaffstatusText } from "./data";
import ToggleStaffModal from "./ToggleStaffModal";

interface Props {
  data: StaffRes;
}

const StaffAction: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { mutate: deleteStaff, isLoading } = useDeleteStaff();
  const { open, onClose, onOpen } = useOpener();
  const {
    open: deleteOpen,
    onClose: deleteOnClose,
    onOpen: deleteOnOpen,
  } = useOpener();

  const statusText = getStaffstatusText(data.isArchived);

  const handleDelete = async () => {
    const payload = {
      staffId: data.staffId,
      data: {
        seatType: data.seatType,
      },
    };

    deleteStaff(payload, {
      onSuccess: () => {
        deleteOnClose();
        toast.success("Staff Deleted Successfully!");
      },
    });
  };

  const actionOptions = () => [
    {
      name: "View",
      onClick: () =>
        navigate(
          `${WebPaths.Profile}?active=staff-management&staffId=${data.staffId}`
        ),
    },
    {
      name: statusText,
      onClick: onOpen,
    },
    {
      name: "Delete",
      onClick: deleteOnOpen,
    },
  ];

  return (
    <>
      <ItemAction options={actionOptions()} />

      {open && <ToggleStaffModal onClose={onClose} data={data} />}

      {deleteOpen && (
        <TerminateModal
          onClose={deleteOnClose}
          title={`Delete Staff`}
          cancelText="No, Cancel"
          subText={
            <>
              Are you sure you want to delete this staff?
              {data.seatType === CreateStaffSeatType.paid && (
                <div>
                  They are currently occupying a paid seat billed monthly.
                  Proceeding will also remove the associated monthly billing.
                </div>
              )}
            </>
          }
          isLoading={isLoading}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default StaffAction;
