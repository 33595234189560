export const WebPaths = {
  Root: "/",
  Auth: "/auth/signin",
  Join: "/auth/register",
  FormCategory: `/form`,
  Eligibility: "/eligibility",
  checkEligibility: "/check-eligibility",
  Profile: "/profile",
  About: "/about-us",
  Help: "/help",
  Services: "/services",
  Blogs: "/blogs",
  GuaranteePolicy: "/guarantee-policy",
  AiPolicy: "/ai-policy",
  Feedback: "/customer-feedback",
  FiBiAi: "/fibi-ai",
  FiBiVoice: "/fibi-voice-interview-prep",
  FiBiText: "/fibi-text-interview-prep",
  Businesses: "/businesses",
  BusinessStaffLogin: "/auth/business-staff-login",
  Individuals: "/individuals",
  Academy: "/study-abroad",
};
