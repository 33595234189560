import React, { FC } from "react";

interface Props {
  bg: string;
  subText: string;
  title: string;
  image: string;
}

const HowCard: FC<Props> = ({ bg, title, subText, image }) => {
  return (
    <div
      className={`flex flex-col gap-6 min-w-[320px] min-h-[530px] flex-1 bg-[${bg}]`}
    >
      <div className=" w-full h-[250px] flex-shrink-0">
        <img
          src={image}
          alt={title}
          className="flex-shrink-0 object-cover w-full h-full"
        />
      </div>
      <div className="flex flex-col gap-3 mt-auto p-9">
        <h5 className="text-3xl font-spartan font-[600]">{title}</h5>
        <p className="">{subText}</p>
      </div>
    </div>
  );
};

export default HowCard;
