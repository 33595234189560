import React from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { FaInfoCircle } from "react-icons/fa";
import { riskyCheckBoxName } from "./data";
import CheckBoxField from "./formik fields/CheckBoxField";
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import { styled as MuiStyled } from "@mui/material/styles";

export enum TooltipType {
  info = "info",
  risky = "risky",
  deadly = "deadly",
}

export interface TooltipProps {
  body?: JSX.Element | string;
  heading?: string;
  size?: "sm" | "md" | "base" | "lg";
  type?: TooltipType;
  isReversed?: boolean;
  checkLabel?: string;
}

export interface Props {
  toolTipProps: TooltipProps;
  parentName?: string;
}

const Tooltip: React.FC<Props> = ({ toolTipProps, parentName }) => {
  const getHeader = () => {
    if (toolTipProps.type === TooltipType.info) {
      return "Important Information!";
    }
    if (toolTipProps.type === TooltipType.risky) {
      return "Please Note!";
    }
    if (toolTipProps.type === TooltipType.deadly) {
      return "We found a problem!";
    } else return "Important Information!";
  };

  const defaultRiskyText =
    "The option you've chosen does not match your eligibility data";

  const LightTooltip = MuiStyled(({ className, ...props }: MuiTooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: "1rem 2rem",
      borderRadius: "8px",
      border: `1px solid ${Colors.GreyA7}`,
      color: `${Colors.Grey8B}`,
      backgroundColor:
        toolTipProps.type === "risky"
          ? `#ffe3a1`
          : toolTipProps.type === "deadly"
          ? "#ff9999"
          : "#ffedc2",
    },
  }));

  const content = (
    <div>
      <ToolH2>{toolTipProps.heading || getHeader()}</ToolH2>
      <ToolP>
        {toolTipProps.body ||
          (toolTipProps.type === TooltipType.risky ? defaultRiskyText : "")}
      </ToolP>

      {toolTipProps.type === TooltipType.risky && (
        <ConsultAttorney>
          <CheckBoxField
            name={`${parentName}${riskyCheckBoxName}`}
            label={
              toolTipProps.checkLabel ||
              "I acknowledge the risk of proceeding forward."
            }
          />
        </ConsultAttorney>
      )}
    </div>
  );

  return (
    <>
      <LightTooltip title={content}>
        <TipIcon
          type={toolTipProps.type || "info"}
          size={toolTipProps.size || "md"}
        >
          <FaInfoCircle />
        </TipIcon>
      </LightTooltip>
    </>
    // <TipIcon
    //   type={toolTipProps.type || "info"}
    //   size={toolTipProps.size || "md"}
    // >
    //   <FaInfoCircle />
    //   <TooltipContent isReversed={toolTipProps.isReversed}>
    //     <h2>{toolTipProps.heading || getHeader()}</h2>
    //     <p>
    //       {toolTipProps.body ||
    //         (toolTipProps.type === TooltipType.risky ? defaultRiskyText : "")}
    //     </p>

    //     {toolTipProps.type === TooltipType.risky && (
    //       <ConsultAttorney>
    //         <CheckBoxField
    //           name={`${parentName}${riskyCheckBoxName}`}
    //           label={
    //             toolTipProps.checkLabel ||
    //             "I acknowledge the risk of proceeding forward."
    //           }
    //         />
    //       </ConsultAttorney>
    //     )}
    //   </TooltipContent>
    // </TipIcon>
  );
};

export default Tooltip;

const TipIcon = styled.div<{
  type: "info" | "risky" | "deadly";
  size: "sm" | "md" | "base" | "lg" | undefined;
}>`
  width: 32px;
  ${({ size }) => size === "lg" && "width: 42px;"}
  ${({ size }) => size === "md" && "width: 24px;"}
  ${({ size }) => size === "sm" && "width: 20px;"}

  aspect-ratio: 1/1;
  border-radius: 50% 50% 50% 15%;

  background-color: ${Colors.AmberFE};
  ${({ type }) => type === "risky" && `background-color: ${Colors.AmberFF};`}
  ${({ type }) => type === "deadly" && `background-color: ${Colors.RedC3};`}

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* position: relative; */
  z-index: 50;
  flex-shrink: 0;
  & > svg {
    color: #fff;
  }

  /* 
  &:hover > div {
    visibility: visible;
    opacity: 1;

    background-color: #ffedc2;
    ${({ type }) => type === "risky" && `background-color: #ffe3a1;`}
    ${({ type }) => type === "deadly" && `background-color: #ff9999;`}
  }
*/
`;

// interface TooltipContentProps {
//   isReversed?: boolean;
// }

// const TooltipContent = styled.div<TooltipContentProps>`
//   padding: 1rem 2rem;
//   border-radius: 8px;
//   position: absolute;
//   right: ${({ isReversed }) => (isReversed ? "unset" : "110%")};
//   left: ${({ isReversed }) => (isReversed ? "110%" : "unset")};
//   bottom: 0;

//   min-width: 350px;
//   border: 1px solid ${Colors.GreyA7};
//   color: ${Colors.Grey8B};

//   visibility: hidden;
//   opacity: 0;
//   transition: 0.2s ease-out;

//   & > h2 {
//     font-weight: bold;
//     color: ${Colors.Black31};
//     font-size: 0.9rem;
//     text-transform: uppercase;

//     margin-bottom: 8px;
//   }

//   & > p {
//     font-weight: 400;
//     color: #000;
//   }
// `;

const ConsultAttorney = styled.div`
  margin-top: 1rem;

  & > div > input {
    accent-color: #000 !important;
  }

  & > div > span {
    color: #000;
  }
`;

const ToolH2 = styled.h2`
  font-weight: bold;
  color: ${Colors.Black31};
  font-size: 0.9rem;
  text-transform: uppercase;

  margin-bottom: 8px;
`;

const ToolP = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  color: #000;
`;
