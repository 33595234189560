import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import FormBtn from "../../../components/FormBtn";
import { saveDS160SecurityBackgroundP5Obj } from "../../../../../api/ds160/types";
import {
  useGetDS160SecurityBackgroundP5,
  useSaveDS160SecurityBackgroundP5,
} from "../../../../../hooks/ds160/usDS160";
import { Info, InfoBold, VerticalFormWrapper } from "../styles";
import { SecurityBackgroundP5Validation } from "../validation";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initInput,
} from "../../../components/data";
import RadioComp from "../../../components/RadioComp";
import { resetDynamicFields } from "../../../components/data";
import InputField from "../../../components/formik fields/InputField";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { getSecurityBackgroundValidations, processReviewData } from "../data";
import useAiReview from "../useAiReview";
import { ReviewSecuritySectionE } from "../Review/components/data";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../../api/utils/types";
import { isAnonClientDataStore } from "../../../../../store/isAnonClientData";
import { useSnapshot } from "valtio";

const SecurityBackgroundP5 = () => {
  const { toNextSection } = useToNextSection();
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160SecurityBackgroundP5({
    applicationId: getApplicationId,
    buid: isAnonClientData,
  });
  const { mutate, isLoading: isSaving } = useSaveDS160SecurityBackgroundP5();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const onSubmit = (values: saveDS160SecurityBackgroundP5Obj) => {
    const payload = values;

    const section = ReviewSecuritySectionE(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      security: {
        ...riskData?.security,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId, buid: isAnonClientData },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const initialValues: saveDS160SecurityBackgroundP5Obj = {
    partFiveI: data?.partFiveI || GenericYesNoOptionsValue.no,
    explanationPartFiveI: initInput(data?.explanationPartFiveI),
    partFiveII: data?.partFiveII || GenericYesNoOptionsValue.no,
    explanationPartFiveII: initInput(data?.explanationPartFiveII),
    partFiveIII: data?.partFiveIII || GenericYesNoOptionsValue.no,
    explanationPartFiveIII: initInput(data?.explanationPartFiveIII),
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || (!isAnonClientData && riskLoading)}
      isError={isError || (!isAnonClientData && riskError)}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Security and
            Background.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={SecurityBackgroundP5Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  title="Have you ever withheld custody of a US citizen child outside
                  the United States from a person granted legal custody by a U.S
                  court?"
                  name="partFiveI"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partFiveI"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartFiveI", value: "" },
                    ]);
                  }}
                />
                {formik.values.partFiveI === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartFiveI"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Have you voted in the united states in violation of any law
                  or regulation?"
                  name="partFiveII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partFiveII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartFiveII", value: "" },
                    ]);
                  }}
                />
                {formik.values.partFiveII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartFiveII"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Have you ever renounced United States citizenship for the
                  purposes of avoiding taxation?"
                  name="partFiveIII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partFiveIII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartFiveIII", value: "" },
                    ]);
                  }}
                />
                {formik.values.partFiveIII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartFiveIII"
                      inputType="text-area"
                    />
                  </>
                )}
                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </FormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default SecurityBackgroundP5;

const Wrapper = styled.div``;

const FormWrapper = styled(VerticalFormWrapper)``;
