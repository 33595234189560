import styled from "styled-components";
import { SingleOption } from "../../../../../../../types/types";
import { DataItemsProps, tableDataProps } from "../../../../../components/type";
import { StaffRes } from "../../../../../../../api/business/staff/types";

export const RoleTag = styled.div`
  text-transform: capitalize;
`;

export const StaffTableData: tableDataProps[] = [
  {
    columnName: "Staff Name",
    columnKey: "name",
  },
  {
    columnName: "Email",
    columnKey: "email",
  },
  // {
  //   columnName: "Location",
  //   columnKey: "location",
  // },
  {
    columnName: "Seat Type",
    columnKey: "seatType",
    customComp: (data: DataItemsProps) => {
      const staff = data as StaffRes;
      return <div className="capitalize ">{staff.seatType}</div>;
    },
  },
  {
    columnName: "Role",
    columnKey: "role",
    customComp: (data: DataItemsProps) => {
      const staff = data as StaffRes;
      return (
        <RoleTag>{`${initSelect(RoleOptions, staff?.role)?.label}`}</RoleTag>
      );
    },
  },
  {
    columnName: "Status",
    columnKey: "role",
    customComp: (data: DataItemsProps) => {
      const staff = data as StaffRes;
      return (
        <div
          className={`!font-semibold ${
            staff.isArchived ? "!text-red-500" : "!text-green-600"
          }`}
        >
          {staff.isArchived ? "Inactive" : "Active"}
        </div>
      );
    },
  },
  {
    columnName: "Actions",
    isAction: true,
  },
];

export const RoleOptions: SingleOption[] = [
  { label: "Administrator", value: "administrator" },
  { label: "Staff", value: "staff" },
  // { label: "Product Manager", value: "quality-assurance" },
  // { label: "Support Specialist", value: "support" },
  // { label: "Content Manager", value: "content-manager" },
  // { label: "Marketing Coordinator", value: "marketing" },
  // {label: 'Designer', value: 'designer'},
  // {label: 'HR Specialist', value: 'hr_specialist'},
  // {label: 'Accountant', value: 'accountant'},
  // {label: 'Intern', value: 'intern'}
];

export const getSelectedOption = (
  data: SingleOption<string, string>[],
  value: string | number
) => {
  return data.filter((ev) => ev.value === value)[0];
};

export const initSelect = (option?: SingleOption[], params?: string) => {
  return params !== undefined && option !== undefined
    ? getSelectedOption(option, params)
    : null;
};

export const getStaffstatusText = (isArchived: boolean) =>
  isArchived ? "Unarchive" : "Archive";

// export const staffMockData: StaffRes[] = [
//   {
//     id: "ad002b9f-6d7f-40c5-88b9-3e64c472a49a",
//     cases: [
//       {
//         caseId: 511,
//         caseType: "what",
//         dateOpened: "1984-11-24",
//         dateSubmitted: "2003-02-28",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "https://murphy.info/",
//       },
//       {
//         caseId: 835,
//         caseType: "hundred",
//         dateOpened: "2023-07-04",
//         dateSubmitted: "1971-06-29",
//         Status: false,
//         case_Status: "open",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "https://thompson.com/",
//       },
//       {
//         caseId: 908,
//         caseType: "theory",
//         dateOpened: "1997-12-18",
//         dateSubmitted: "2007-08-18",
//         Status: false,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "http://www.doyle.com/",
//       },
//     ],
//     createdBy: "Brittany Rivera",
//     email: "francisjames@yahoo.com",
//     isAdmin: true,
//     isStaff: true,
//     job_title: "Publishing copy",
//     location: "South Michaelshire",
//     name: "Daniel Lynch",
//     role: "staff",
//   },
//   {
//     id: "692a785b-137a-45eb-a030-6be8494bde05",
//     cases: [
//       {
//         caseId: 62,
//         caseType: "attorney",
//         dateOpened: "1990-08-28",
//         dateSubmitted: "2002-02-06",
//         Status: false,
//         case_Status: "open",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://chang.info/",
//       },
//       {
//         caseId: 784,
//         caseType: "deal",
//         dateOpened: "1986-03-10",
//         dateSubmitted: "1989-02-12",
//         Status: false,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://wong.com/",
//       },
//     ],
//     createdBy: "Bryce Bowers",
//     email: "james78@gmail.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Writer",
//     location: "Lake Philip",
//     name: "Renee Henderson",
//     role: "staff",
//   },
//   {
//     id: "7dd909de-0d53-4f06-8cd6-2b1bf5d9fb54",
//     cases: [
//       {
//         caseId: 311,
//         caseType: "animal",
//         dateOpened: "2008-12-11",
//         dateSubmitted: "1979-04-26",
//         Status: false,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "https://www.novak.org/",
//       },
//       {
//         caseId: 227,
//         caseType: "material",
//         dateOpened: "2017-07-28",
//         dateSubmitted: "2020-09-20",
//         Status: false,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://cox.info/",
//       },
//       {
//         caseId: 600,
//         caseType: "worker",
//         dateOpened: "2017-07-13",
//         dateSubmitted: "1979-06-28",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://hughes-mason.info/",
//       },
//     ],
//     createdBy: "Eric Mcguire",
//     email: "lindawilliams@hotmail.com",
//     isAdmin: false,
//     isStaff: false,
//     job_title: "Journalist, newspaper",
//     location: "Oliverland",
//     name: "Isabel Barber",
//     role: "staff",
//   },
//   {
//     id: "d24985d8-c746-41d0-b307-ae2134087b2e",
//     cases: [
//       {
//         caseId: 373,
//         caseType: "senior",
//         dateOpened: "1990-10-04",
//         dateSubmitted: "1995-02-18",
//         Status: false,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "https://www.marshall.biz/",
//       },
//       {
//         caseId: 122,
//         caseType: "ten",
//         dateOpened: "1996-04-17",
//         dateSubmitted: "1971-09-02",
//         Status: false,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "https://www.anderson.com/",
//       },
//       {
//         caseId: 682,
//         caseType: "style",
//         dateOpened: "2010-12-20",
//         dateSubmitted: "2016-10-20",
//         Status: true,
//         case_Status: "open",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "http://www.smith-adams.org/",
//       },
//       {
//         caseId: 685,
//         caseType: "before",
//         dateOpened: "1977-03-02",
//         dateSubmitted: "2009-10-27",
//         Status: false,
//         case_Status: "open",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://www.strickland.info/",
//       },
//     ],
//     createdBy: "Andre Mason",
//     email: "dana31@reid.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Herpetologist",
//     location: "Port Shawntown",
//     name: "Patricia Gomez",
//     role: "staff",
//   },
//   {
//     id: "520626b9-dcc6-468c-9f4f-770b5a89c332",
//     cases: [
//       {
//         caseId: 528,
//         caseType: "candidate",
//         dateOpened: "2005-06-19",
//         dateSubmitted: "2003-08-09",
//         Status: true,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "http://benson.com/",
//       },
//       {
//         caseId: 887,
//         caseType: "view",
//         dateOpened: "2010-12-26",
//         dateSubmitted: "2012-05-25",
//         Status: true,
//         case_Status: "open",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "https://brady.com/",
//       },
//       {
//         caseId: 312,
//         caseType: "western",
//         dateOpened: "1971-05-26",
//         dateSubmitted: "2008-02-28",
//         Status: true,
//         case_Status: "open",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "https://soto-martin.com/",
//       },
//       {
//         caseId: 237,
//         caseType: "step",
//         dateOpened: "2021-07-17",
//         dateSubmitted: "2012-12-22",
//         Status: true,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.kim.com/",
//       },
//     ],
//     createdBy: "James Robertson",
//     email: "jowens@cardenas.com",
//     isAdmin: false,
//     isStaff: false,
//     job_title: "Engineering geologist",
//     location: "Tinaland",
//     name: "Eric Villegas",
//     role: "administrator",
//   },
//   {
//     id: "ad2dd5f6-ff22-4d43-b393-5dadf5d5a182",
//     cases: [
//       {
//         caseId: 94,
//         caseType: "thank",
//         dateOpened: "2007-01-08",
//         dateSubmitted: "2005-02-17",
//         Status: false,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://www.walker-gonzales.com/",
//       },
//       {
//         caseId: 469,
//         caseType: "bill",
//         dateOpened: "1983-03-21",
//         dateSubmitted: "2008-06-10",
//         Status: false,
//         case_Status: "open",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "http://www.zamora.com/",
//       },
//       {
//         caseId: 366,
//         caseType: "media",
//         dateOpened: "2010-03-31",
//         dateSubmitted: "1984-01-11",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://richardson-mills.com/",
//       },
//       {
//         caseId: 342,
//         caseType: "majority",
//         dateOpened: "2004-01-01",
//         dateSubmitted: "2015-06-20",
//         Status: true,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "https://www.terry-garrison.org/",
//       },
//       {
//         caseId: 617,
//         caseType: "table",
//         dateOpened: "1999-02-06",
//         dateSubmitted: "1983-08-02",
//         Status: false,
//         case_Status: "open",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://jackson.com/",
//       },
//     ],
//     createdBy: "Willie Taylor",
//     email: "kaylabarajas@ortiz.com",
//     isAdmin: true,
//     isStaff: true,
//     job_title: "Retail banker",
//     location: "East Steven",
//     name: "Eric Wood",
//     role: "administrator",
//   },
//   {
//     id: "847a9f2c-d52b-46db-8018-b0b805e7646b",
//     cases: [
//       {
//         caseId: 142,
//         caseType: "dog",
//         dateOpened: "1991-01-17",
//         dateSubmitted: "1990-09-20",
//         Status: false,
//         case_Status: "open",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "https://www.hogan.org/",
//       },
//     ],
//     createdBy: "Norma Key",
//     email: "beckjohn@hotmail.com",
//     isAdmin: false,
//     isStaff: true,
//     job_title: "Art therapist",
//     location: "Travismouth",
//     name: "Jeffrey Matthews",
//     role: "staff",
//   },
//   {
//     id: "42166a97-7325-4ae1-a4b6-d0822cf87dd5",
//     cases: [
//       {
//         caseId: 282,
//         caseType: "young",
//         dateOpened: "1978-07-08",
//         dateSubmitted: "1982-12-14",
//         Status: true,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://brown-martin.net/",
//       },
//       {
//         caseId: 974,
//         caseType: "bring",
//         dateOpened: "2022-05-11",
//         dateSubmitted: "2017-12-25",
//         Status: true,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "https://www.tran-montoya.com/",
//       },
//     ],
//     createdBy: "Rachel Carpenter",
//     email: "zreese@anderson.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Television/film/video producer",
//     location: "South Cindystad",
//     name: "April Russell",
//     role: "administrator",
//   },
//   {
//     id: "6ab3785a-6de7-449c-837e-8063fa5a540f",
//     cases: [
//       {
//         caseId: 831,
//         caseType: "think",
//         dateOpened: "2019-10-20",
//         dateSubmitted: "1989-04-14",
//         Status: false,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "http://warner.com/",
//       },
//       {
//         caseId: 799,
//         caseType: "effect",
//         dateOpened: "1980-03-11",
//         dateSubmitted: "2021-11-10",
//         Status: true,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://smith.com/",
//       },
//       {
//         caseId: 746,
//         caseType: "name",
//         dateOpened: "1998-01-18",
//         dateSubmitted: "1978-07-09",
//         Status: false,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "https://www.hartman.com/",
//       },
//       {
//         caseId: 937,
//         caseType: "certain",
//         dateOpened: "1990-06-21",
//         dateSubmitted: "1970-10-09",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "https://bryant.info/",
//       },
//       {
//         caseId: 182,
//         caseType: "first",
//         dateOpened: "2016-05-02",
//         dateSubmitted: "2022-02-05",
//         Status: true,
//         case_Status: "closed",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "http://www.ramsey-martin.biz/",
//       },
//     ],
//     createdBy: "Lauren Long",
//     email: "andrewsullivan@gmail.com",
//     isAdmin: false,
//     isStaff: true,
//     job_title: "Engineer, civil (contracting)",
//     location: "New Johnbury",
//     name: "John Graham",
//     role: "staff",
//   },
//   {
//     id: "a3ab02ca-969d-469e-9ccb-19fc36c0fc72",
//     cases: [
//       {
//         caseId: 744,
//         caseType: "adult",
//         dateOpened: "1996-11-15",
//         dateSubmitted: "1993-06-27",
//         Status: true,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://guzman.com/",
//       },
//       {
//         caseId: 800,
//         caseType: "member",
//         dateOpened: "2007-05-31",
//         dateSubmitted: "1973-01-21",
//         Status: true,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.mclaughlin.com/",
//       },
//       {
//         caseId: 383,
//         caseType: "recently",
//         dateOpened: "2007-01-28",
//         dateSubmitted: "1995-11-21",
//         Status: false,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.brown-rice.biz/",
//       },
//     ],
//     createdBy: "Ann Dorsey",
//     email: "erin06@yahoo.com",
//     isAdmin: true,
//     isStaff: true,
//     job_title: "Scientist, research (medical)",
//     location: "New Victorberg",
//     name: "Steven Phillips",
//     role: "administrator",
//   },
//   {
//     id: "0ab2ad88-60fb-42b4-9ede-44981dfdac98",
//     cases: [
//       {
//         caseId: 614,
//         caseType: "within",
//         dateOpened: "2015-02-20",
//         dateSubmitted: "2012-08-26",
//         Status: false,
//         case_Status: "closed",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "https://cannon.net/",
//       },
//       {
//         caseId: 207,
//         caseType: "special",
//         dateOpened: "2015-02-11",
//         dateSubmitted: "2003-12-21",
//         Status: false,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "https://www.brooks-perry.com/",
//       },
//       {
//         caseId: 665,
//         caseType: "consider",
//         dateOpened: "1974-01-05",
//         dateSubmitted: "2005-06-11",
//         Status: true,
//         case_Status: "open",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "https://www.davis.com/",
//       },
//     ],
//     createdBy: "Katie Gibson",
//     email: "penaadam@edwards.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Development worker, community",
//     location: "Charlesburgh",
//     name: "Timothy Edwards",
//     role: "administrator",
//   },
//   {
//     id: "12affec9-53b3-42a9-b4d6-ae04e954ce0e",
//     cases: [
//       {
//         caseId: 254,
//         caseType: "realize",
//         dateOpened: "2019-01-20",
//         dateSubmitted: "2011-04-22",
//         Status: false,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://singleton.com/",
//       },
//       {
//         caseId: 751,
//         caseType: "interview",
//         dateOpened: "2002-05-16",
//         dateSubmitted: "2009-11-11",
//         Status: true,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "https://www.smith-mullen.org/",
//       },
//     ],
//     createdBy: "Christopher Lopez Jr.",
//     email: "zachary33@terry-davis.com",
//     isAdmin: false,
//     isStaff: false,
//     job_title: "Film/video editor",
//     location: "New Frankchester",
//     name: "Dr. Paige Brewer MD",
//     role: "staff",
//   },
//   {
//     id: "b34ab315-d7fe-46ea-84c1-0b979e82d8e6",
//     cases: [
//       {
//         caseId: 304,
//         caseType: "simply",
//         dateOpened: "1988-08-26",
//         dateSubmitted: "2018-03-15",
//         Status: true,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "http://dougherty.com/",
//       },
//     ],
//     createdBy: "Sarah Wilson",
//     email: "emorris@gmail.com",
//     isAdmin: false,
//     isStaff: false,
//     job_title: "Designer, ceramics/pottery",
//     location: "Kaylaton",
//     name: "Rachel Phillips",
//     role: "administrator",
//   },
//   {
//     id: "488eac71-fb40-4599-88e8-95a37f8c545c",
//     cases: [
//       {
//         caseId: 330,
//         caseType: "bed",
//         dateOpened: "1983-04-27",
//         dateSubmitted: "2019-12-22",
//         Status: true,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://hurst.info/",
//       },
//       {
//         caseId: 796,
//         caseType: "company",
//         dateOpened: "1978-04-24",
//         dateSubmitted: "1993-06-13",
//         Status: false,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.fuller.com/",
//       },
//       {
//         caseId: 810,
//         caseType: "fact",
//         dateOpened: "1972-06-13",
//         dateSubmitted: "1997-04-09",
//         Status: false,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "https://www.butler.com/",
//       },
//       {
//         caseId: 773,
//         caseType: "account",
//         dateOpened: "1978-02-28",
//         dateSubmitted: "1990-08-01",
//         Status: true,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "https://www.brown-miller.biz/",
//       },
//     ],
//     createdBy: "Hannah Burch",
//     email: "elizabeth35@yahoo.com",
//     isAdmin: false,
//     isStaff: true,
//     job_title: "Television floor manager",
//     location: "West Michaelville",
//     name: "Gabrielle Lee",
//     role: "administrator",
//   },
//   {
//     id: "3499dfae-b37c-4ebd-b014-da0c3912d5b6",
//     cases: [
//       {
//         caseId: 440,
//         caseType: "just",
//         dateOpened: "2016-03-30",
//         dateSubmitted: "1990-09-03",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://www.reynolds.info/",
//       },
//       {
//         caseId: 50,
//         caseType: "blue",
//         dateOpened: "1972-05-27",
//         dateSubmitted: "2000-03-20",
//         Status: false,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.allen-thompson.com/",
//       },
//       {
//         caseId: 773,
//         caseType: "picture",
//         dateOpened: "1983-09-29",
//         dateSubmitted: "2009-11-23",
//         Status: false,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "https://smith.com/",
//       },
//       {
//         caseId: 54,
//         caseType: "agree",
//         dateOpened: "2016-02-18",
//         dateSubmitted: "1970-04-03",
//         Status: true,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "http://www.richardson.com/",
//       },
//       {
//         caseId: 367,
//         caseType: "charge",
//         dateOpened: "1979-02-16",
//         dateSubmitted: "2014-10-12",
//         Status: true,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://gomez.biz/",
//       },
//     ],
//     createdBy: "Michelle Wilson",
//     email: "hannahmason@luna.com",
//     isAdmin: false,
//     isStaff: true,
//     job_title: "Development worker, community",
//     location: "East William",
//     name: "Joshua Nichols",
//     role: "administrator",
//   },
//   {
//     id: "7abaa39b-421c-47bf-82c6-9ecb2374ba7e",
//     cases: [
//       {
//         caseId: 237,
//         caseType: "begin",
//         dateOpened: "1995-05-14",
//         dateSubmitted: "2022-09-07",
//         Status: false,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://tucker.com/",
//       },
//     ],
//     createdBy: "Mark Patton",
//     email: "hvasquez@gmail.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Production assistant, radio",
//     location: "Watsonfurt",
//     name: "Logan Smith",
//     role: "staff",
//   },
//   {
//     id: "83471725-e496-4a38-9d51-81ec3387ec4a",
//     cases: [
//       {
//         caseId: 493,
//         caseType: "discover",
//         dateOpened: "2000-06-11",
//         dateSubmitted: "1971-12-21",
//         Status: false,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "https://www.aguirre.com/",
//       },
//       {
//         caseId: 82,
//         caseType: "catch",
//         dateOpened: "2000-08-30",
//         dateSubmitted: "1985-07-03",
//         Status: false,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "http://miller.com/",
//       },
//       {
//         caseId: 726,
//         caseType: "commercial",
//         dateOpened: "2013-09-10",
//         dateSubmitted: "2017-06-17",
//         Status: false,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.escobar.com/",
//       },
//       {
//         caseId: 512,
//         caseType: "return",
//         dateOpened: "2020-01-08",
//         dateSubmitted: "2011-12-27",
//         Status: false,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://cortez-barnett.biz/",
//       },
//     ],
//     createdBy: "Dawn Flores",
//     email: "thompsonsherry@gmail.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Geologist, engineering",
//     location: "Whitneyport",
//     name: "Kelli Dixon",
//     role: "administrator",
//   },
//   {
//     id: "ccbb8494-4ecf-401b-8f83-86398d6f28e0",
//     cases: [
//       {
//         caseId: 765,
//         caseType: "hotel",
//         dateOpened: "1991-01-23",
//         dateSubmitted: "1978-01-30",
//         Status: false,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "https://www.armstrong.info/",
//       },
//       {
//         caseId: 293,
//         caseType: "term",
//         dateOpened: "1980-02-07",
//         dateSubmitted: "2021-08-16",
//         Status: false,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "https://brooks-mclaughlin.com/",
//       },
//       {
//         caseId: 467,
//         caseType: "various",
//         dateOpened: "2009-02-14",
//         dateSubmitted: "2007-05-27",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://miller-williams.com/",
//       },
//       {
//         caseId: 145,
//         caseType: "order",
//         dateOpened: "2014-06-12",
//         dateSubmitted: "2013-09-28",
//         Status: false,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.welch.org/",
//       },
//       {
//         caseId: 357,
//         caseType: "could",
//         dateOpened: "2003-07-04",
//         dateSubmitted: "2021-12-07",
//         Status: false,
//         case_Status: "open",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "https://richardson.com/",
//       },
//     ],
//     createdBy: "Daniel Andersen",
//     email: "michellehampton@yahoo.com",
//     isAdmin: false,
//     isStaff: false,
//     job_title: "Warden/ranger",
//     location: "East Michael",
//     name: "Ricardo Morrison",
//     role: "staff",
//   },
//   {
//     id: "468d6915-6d05-4f9b-af7e-ce61fd11ac98",
//     cases: [
//       {
//         caseId: 761,
//         caseType: "ground",
//         dateOpened: "2001-02-11",
//         dateSubmitted: "2009-11-03",
//         Status: false,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://sanders.com/",
//       },
//       {
//         caseId: 403,
//         caseType: "serious",
//         dateOpened: "2017-07-19",
//         dateSubmitted: "2001-01-16",
//         Status: true,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "http://fox.net/",
//       },
//       {
//         caseId: 10,
//         caseType: "chance",
//         dateOpened: "2010-06-25",
//         dateSubmitted: "1982-04-27",
//         Status: false,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://www.evans.info/",
//       },
//       {
//         caseId: 207,
//         caseType: "type",
//         dateOpened: "1988-02-06",
//         dateSubmitted: "2006-06-15",
//         Status: false,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://cervantes.com/",
//       },
//       {
//         caseId: 839,
//         caseType: "everything",
//         dateOpened: "1977-08-30",
//         dateSubmitted: "2006-01-10",
//         Status: true,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://tran.com/",
//       },
//     ],
//     createdBy: "Anna Keith",
//     email: "walkerelizabeth@hayes.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Investment banker, operational",
//     location: "Mcneilport",
//     name: "Gary Brown",
//     role: "administrator",
//   },
//   {
//     id: "04c35017-efe3-4896-b250-1dd7d68c6a14",
//     cases: [
//       {
//         caseId: 774,
//         caseType: "view",
//         dateOpened: "1986-06-26",
//         dateSubmitted: "2018-02-22",
//         Status: true,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.atkinson-hunter.com/",
//       },
//       {
//         caseId: 733,
//         caseType: "himself",
//         dateOpened: "1997-02-22",
//         dateSubmitted: "2019-06-02",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "https://randall.biz/",
//       },
//       {
//         caseId: 102,
//         caseType: "near",
//         dateOpened: "1987-04-08",
//         dateSubmitted: "2020-02-04",
//         Status: true,
//         case_Status: "closed",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "https://www.smith-sanchez.org/",
//       },
//       {
//         caseId: 838,
//         caseType: "pull",
//         dateOpened: "1995-03-15",
//         dateSubmitted: "1970-05-06",
//         Status: true,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "https://williams-smith.com/",
//       },
//     ],
//     createdBy: "Hayley Harris",
//     email: "ljones@ward.com",
//     isAdmin: true,
//     isStaff: true,
//     job_title: "Research officer, trade union",
//     location: "South Raymondchester",
//     name: "James Smith",
//     role: "administrator",
//   },
//   {
//     id: "4a02a012-f5aa-4902-b6d0-198dbd3fc468",
//     cases: [
//       {
//         caseId: 271,
//         caseType: "attack",
//         dateOpened: "2017-04-26",
//         dateSubmitted: "2019-05-31",
//         Status: false,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "https://www.charles.com/",
//       },
//     ],
//     createdBy: "Jennifer Giles",
//     email: "justin19@hotmail.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Accounting technician",
//     location: "South Jessicaport",
//     name: "Julie Kaufman",
//     role: "administrator",
//   },
//   {
//     id: "76193479-9135-43d3-af26-85da7c2ed66f",
//     cases: [
//       {
//         caseId: 361,
//         caseType: "service",
//         dateOpened: "2018-06-28",
//         dateSubmitted: "2013-01-07",
//         Status: false,
//         case_Status: "open",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://www.lloyd.com/",
//       },
//       {
//         caseId: 385,
//         caseType: "race",
//         dateOpened: "2006-10-31",
//         dateSubmitted: "1984-10-28",
//         Status: true,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://rivera.com/",
//       },
//       {
//         caseId: 991,
//         caseType: "recognize",
//         dateOpened: "1988-11-08",
//         dateSubmitted: "2008-10-30",
//         Status: true,
//         case_Status: "closed",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "http://www.frazier-allen.com/",
//       },
//       {
//         caseId: 217,
//         caseType: "sing",
//         dateOpened: "2006-01-23",
//         dateSubmitted: "1973-07-01",
//         Status: false,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "https://www.dennis-valdez.com/",
//       },
//     ],
//     createdBy: "Heather Rowe",
//     email: "usmith@yahoo.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Housing manager/officer",
//     location: "Kellyshire",
//     name: "Ricky Davis",
//     role: "staff",
//   },
//   {
//     id: "562bb790-9e6b-4701-845f-6f68e4884004",
//     cases: [
//       {
//         caseId: 495,
//         caseType: "sense",
//         dateOpened: "1973-12-10",
//         dateSubmitted: "2006-04-09",
//         Status: true,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "http://adkins.info/",
//       },
//       {
//         caseId: 304,
//         caseType: "none",
//         dateOpened: "2021-04-20",
//         dateSubmitted: "2014-02-25",
//         Status: false,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://www.powell.net/",
//       },
//       {
//         caseId: 612,
//         caseType: "hour",
//         dateOpened: "2002-10-11",
//         dateSubmitted: "1972-06-07",
//         Status: true,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://www.harding.com/",
//       },
//       {
//         caseId: 27,
//         caseType: "begin",
//         dateOpened: "1978-05-20",
//         dateSubmitted: "2009-11-26",
//         Status: false,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://www.griffith.com/",
//       },
//       {
//         caseId: 262,
//         caseType: "assume",
//         dateOpened: "1986-03-22",
//         dateSubmitted: "1989-01-22",
//         Status: false,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://www.castillo.info/",
//       },
//     ],
//     createdBy: "Gregory Mcdonald",
//     email: "amywalker@schwartz.net",
//     isAdmin: false,
//     isStaff: true,
//     job_title: "Social researcher",
//     location: "Port Jonathan",
//     name: "Craig Gibson",
//     role: "administrator",
//   },
//   {
//     id: "a8fa545c-d505-4241-9211-962a3364bc4c",
//     cases: [
//       {
//         caseId: 973,
//         caseType: "safe",
//         dateOpened: "1985-01-17",
//         dateSubmitted: "1977-01-23",
//         Status: true,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "https://www.armstrong.org/",
//       },
//     ],
//     createdBy: "Tiffany Dunlap",
//     email: "morristhomas@reyes.net",
//     isAdmin: false,
//     isStaff: false,
//     job_title: "Education officer, environmental",
//     location: "Lake William",
//     name: "Rachel Wilson",
//     role: "staff",
//   },
//   {
//     id: "cff0f85d-e06c-40ad-aa6f-1a9a62f7281f",
//     cases: [
//       {
//         caseId: 324,
//         caseType: "environment",
//         dateOpened: "1983-11-28",
//         dateSubmitted: "1971-11-04",
//         Status: true,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "https://www.miller.org/",
//       },
//     ],
//     createdBy: "Caleb Rice",
//     email: "sean42@hotmail.com",
//     isAdmin: false,
//     isStaff: true,
//     job_title: "Engineer, civil (consulting)",
//     location: "West Amyberg",
//     name: "Kayla Palmer",
//     role: "staff",
//   },
//   {
//     id: "6407a3ce-7d8e-4720-a2a9-59412f43237c",
//     cases: [
//       {
//         caseId: 654,
//         caseType: "its",
//         dateOpened: "1993-06-06",
//         dateSubmitted: "1994-10-10",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://www.parker-duarte.net/",
//       },
//       {
//         caseId: 383,
//         caseType: "discussion",
//         dateOpened: "1988-06-04",
//         dateSubmitted: "2023-05-19",
//         Status: true,
//         case_Status: "open",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://www.lewis.com/",
//       },
//       {
//         caseId: 477,
//         caseType: "science",
//         dateOpened: "2009-05-26",
//         dateSubmitted: "2017-02-16",
//         Status: true,
//         case_Status: "pending",
//         progress: "in progress",
//         Payment: true,
//         formDownloadUrl: "http://www.adams.com/",
//       },
//     ],
//     createdBy: "Heather Ellis",
//     email: "gabriellarichards@andrews-graham.com",
//     isAdmin: false,
//     isStaff: false,
//     job_title: "Therapist, speech and language",
//     location: "East Ronaldberg",
//     name: "David Anderson",
//     role: "administrator",
//   },
//   {
//     id: "da3d9408-454d-45ed-ab6e-d5b75b74b350",
//     cases: [
//       {
//         caseId: 549,
//         caseType: "conference",
//         dateOpened: "2003-05-12",
//         dateSubmitted: "1983-01-27",
//         Status: false,
//         case_Status: "open",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://www.reed.com/",
//       },
//       {
//         caseId: 708,
//         caseType: "how",
//         dateOpened: "1987-02-07",
//         dateSubmitted: "2021-12-31",
//         Status: false,
//         case_Status: "pending",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "http://quinn.com/",
//       },
//     ],
//     createdBy: "Dr. Alice Harding",
//     email: "angelica28@hotmail.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Armed forces training and education officer",
//     location: "Rebeccaberg",
//     name: "Robert Taylor",
//     role: "administrator",
//   },
//   {
//     id: "9ac500e7-86bc-4e4e-9a3a-091380a662c6",
//     cases: [
//       {
//         caseId: 636,
//         caseType: "class",
//         dateOpened: "1979-01-10",
//         dateSubmitted: "2021-02-27",
//         Status: false,
//         case_Status: "open",
//         progress: "not started",
//         Payment: true,
//         formDownloadUrl: "http://www.marshall-thompson.com/",
//       },
//       {
//         caseId: 15,
//         caseType: "plant",
//         dateOpened: "1970-09-23",
//         dateSubmitted: "1976-02-26",
//         Status: false,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "http://lopez.com/",
//       },
//       {
//         caseId: 81,
//         caseType: "establish",
//         dateOpened: "1989-11-18",
//         dateSubmitted: "1981-02-03",
//         Status: false,
//         case_Status: "open",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://nelson.com/",
//       },
//       {
//         caseId: 932,
//         caseType: "walk",
//         dateOpened: "1983-11-05",
//         dateSubmitted: "2008-03-26",
//         Status: true,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "http://www.stephens.info/",
//       },
//       {
//         caseId: 88,
//         caseType: "budget",
//         dateOpened: "1977-12-25",
//         dateSubmitted: "1999-09-30",
//         Status: true,
//         case_Status: "closed",
//         progress: "completed",
//         Payment: true,
//         formDownloadUrl: "https://savage-burgess.com/",
//       },
//     ],
//     createdBy: "Shannon Hartman",
//     email: "johnsonalexa@kelly.net",
//     isAdmin: true,
//     isStaff: true,
//     job_title: "Manufacturing engineer",
//     location: "Gregoryland",
//     name: "Jasmine Fox",
//     role: "staff",
//   },
//   {
//     id: "de17b2e9-e873-4637-9984-8ccfa08d618e",
//     cases: [
//       {
//         caseId: 719,
//         caseType: "window",
//         dateOpened: "1993-07-09",
//         dateSubmitted: "2016-12-01",
//         Status: true,
//         case_Status: "pending",
//         progress: "not started",
//         Payment: false,
//         formDownloadUrl: "http://fernandez-schwartz.com/",
//       },
//       {
//         caseId: 803,
//         caseType: "case",
//         dateOpened: "1997-09-02",
//         dateSubmitted: "2006-04-02",
//         Status: false,
//         case_Status: "open",
//         progress: "completed",
//         Payment: false,
//         formDownloadUrl: "http://palmer.org/",
//       },
//     ],
//     createdBy: "James Hansen",
//     email: "ovilla@edwards.info",
//     isAdmin: false,
//     isStaff: false,
//     job_title: "Ophthalmologist",
//     location: "Port Matthewville",
//     name: "Christian Smith",
//     role: "administrator",
//   },
//   {
//     id: "a8077c2c-9764-4020-9c6d-d440eee7f1d4",
//     cases: [
//       {
//         caseId: 189,
//         caseType: "total",
//         dateOpened: "2001-12-22",
//         dateSubmitted: "1996-10-15",
//         Status: true,
//         case_Status: "closed",
//         progress: "in progress",
//         Payment: false,
//         formDownloadUrl: "http://sweeney.org/",
//       },
//     ],
//     createdBy: "Brian Hall",
//     email: "dmckenzie@gmail.com",
//     isAdmin: true,
//     isStaff: false,
//     job_title: "Stage manager",
//     location: "Lopezbury",
//     name: "Paula Martinez",
//     role: "administrator",
//   },
// ];
