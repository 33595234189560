export enum businessAuthQueryKeys {
  getChangePasswordCode = "get-change-password-code",
  getBusinessProfile = "getBusinessProfile",
}

export enum businessUsersQueryKeys {
  getBusinessUsers = "getBusinessUsers",
  getSingleBusinessUsers = "getSingleBusinessUsers",
  shareFibiInvite = "shareFibiInvite",
}

export enum businessApplicationsQueryKeys {
  getBusinessApplications = "getBusinessApplications",
  getSingleBusinessApplication = "getSingleBusinessApplication",
  getTotalCases = "getTotalCases",
  getTotalPaidCases = "getTotalPaidCases",
  getTotalPendingCases = "getTotalPendingCases",
  getTotalCompletedCases = "getTotalCompletedCases",
}

export enum StaffQueryKeys {
  getAllStaff = "get-all-staff",
  getSingleStaff = "get-staff",
}

export enum businessBillingQueryKeys {
  businessSubscriptionSuccess = "businessSubscriptionSuccess",
  businessSubscriptionFailure = "businessSubscriptionFailure",
  businessSubscriptionStaffSeatSuccess = "businessSubscriptionStaffSeatSuccess",
  businessSubscriptionStaffSeatFailure = "businessSubscriptionStaffSeatFailure",
}
