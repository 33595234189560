import { useSnapshot } from "valtio";
import { isBusinessDataStore } from "../store/isBusinessData";
import { useGetGeoLocation } from "./utils/useUtils";
import { businessUserDataStore } from "../store/businessUserData";

const useGetPrice = () => {
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { businessUserData } = useSnapshot(businessUserDataStore);
  const { data: locationData } = useGetGeoLocation();

  const isNigeria = isBusinessData
    ? businessUserData?.businessInfo.country === "Nigeria"
    : locationData?.country === "NG";

  const getPrice = (dollarAmount: number) => {
    if (isNigeria) {
      return `₦${(dollarAmount * 1000).toLocaleString("en-US")}`;
    } else {
      return `$${dollarAmount.toLocaleString("en-US")}`;
    }
  };
  return { getPrice, isNigeria };
};

export default useGetPrice;
