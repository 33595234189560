import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import { RiSendPlaneFill } from "react-icons/ri";
import Colors from "../../../../styles/Colors";
import Markdown from "markdown-to-jsx";
import Spinner from "../../../../utils/Spinner";
import { saveInterviewChatGpt } from "../../../../api/ai";
import AiDisclaimer from "../../components/AiDisclaimer";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../store/userData";
import {
  useDeleteInterviewHistory,
  useGetChatGptInterviewHistory,
} from "../../../../hooks/utils/useUtils";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import { VscDebugRestart } from "react-icons/vsc";
import Modal from "../../components/modal/Modal";
import Disclaimer from "./components/Disclaimer";
import { noticeStore } from "../../../../store/utils";
import { GetInterviewHistoryResultRole } from "../../../../api/ai/types";
import FiBiAuth from "./components/FiBiAuth";

enum MessageSender {
  user = "user",
  bot = "bot",
}

interface Message {
  message: string;
  sender: MessageSender;
}

const FiBiText = () => {
  const { data: historyData, isFetching: historyLoading } =
    useGetChatGptInterviewHistory();
  const { mutate: deleteMutate, isLoading: deleteLoading } =
    useDeleteInterviewHistory();
  const { userData } = useSnapshot(userDataStore);
  const [input, setInput] = useState<string>("");
  const [chatHistory, setChatHistory] = useState<Message[]>([]);
  const [prevHistory, setPrevHistory] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const { seenDisclaimer } = noticeStore;
  const [shownDisclaimer, setShownDisclaimer] = useState(seenDisclaimer);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatHistory.length]);

  useEffect(() => {
    document.title = `FiBi Text Interview | Your Personal Interview Trainer`;

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, []);

  const baseFetch = async ({ id, prompt }: { id: string; prompt: string }) => {
    setLoading(true);
    try {
      const result = await saveInterviewChatGpt({
        id,
        payload: {
          prompt,
        },
      });

      const aiResponse = {
        sender: MessageSender.bot,
        message: result.data.result,
      };
      setChatHistory((prev) => [...prev, aiResponse]);
    } catch (error) {
      const errorMessage = {
        sender: MessageSender.bot,
        message:
          "We're experiencing a temporary hiccup. Feel free to retry your request. If you still encounter problems, our support team at support@seamlessvisa.com is here for you.",
      };
      setChatHistory((prev) => [...prev, errorMessage]);
      console.error("Error generating response:", error);
    } finally {
      setLoading(false);
      setInput("");
    }
  };

  const sendMessage = async (tempData?: string) => {
    if (!userData) return;
    const temp = tempData || input;
    if (temp.trim()) {
      const newMessage: Message = {
        message: temp.trim(),
        sender: MessageSender.user,
      };
      setChatHistory([...chatHistory, newMessage]);
      setInput("");
      baseFetch({
        id: userData?.userInfo.userId,
        prompt: temp,
      });
    }
  };

  useEffect(() => {
    if (historyLoading || (historyData && historyData?.length > 0)) return;
    baseFetch({
      id: "",
      prompt: "",
    });
  }, [historyData, historyLoading]);

  useEffect(() => {
    if (typeof historyData === "string") return;
    const prevTemp = historyData?.map((ev) => ({
      sender:
        ev.role === GetInterviewHistoryResultRole.assistant
          ? MessageSender.bot
          : MessageSender.user,
      message: ev.content,
    }));

    setPrevHistory(prevTemp || []);
  }, [historyData]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  const onRestart = () => {
    deleteMutate();
    setPrevHistory([]);
    setChatHistory([]);
  };

  const masterLoading = loading || historyLoading || deleteLoading;

  // Function to handle key down events in the textarea
  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        return;
      } else {
        event.preventDefault();
        sendMessage();
      }
    }
  };

  const onSeenDisclaimer = () => {
    setShownDisclaimer(true);
    noticeStore.seenDisclaimer = true;
    sessionStorage.setItem("seenDisclaimer", "true");
  };

  return (
    <Wrapper>
      <Constaint>
        <Container>
          {isLoggedIn && !shownDisclaimer && (
            <Modal isOpen={!shownDisclaimer} onClose={onSeenDisclaimer}>
              <Disclaimer onClose={onSeenDisclaimer} />
            </Modal>
          )}

          <MiniContainer>
            {!isLoggedIn && <FiBiAuth />}

            <Body ref={chatContainerRef}>
              {(prevHistory.length > 0
                ? prevHistory.concat(chatHistory)
                : chatHistory
              ).map((message, index) => (
                <div
                  key={index}
                  className={`mb-4 flex ${
                    message.sender === "user" ? "justify-end" : ""
                  }`}
                >
                  <span
                    className={`inline-block p-2 rounded-3xl relative ${
                      message.sender === "user"
                        ? "inline-block max-w-[70%] px-4 py-3 rounded-tr-[4px] bg-[#0276E9] text-white"
                        : "bg-white shadow px-4 py-3 border border-gray-300 text-gray-600 rounded-tl-[4px] mb-4 max-w-[85%]"
                    }`}
                  >
                    <Markdown>{message.message}</Markdown>
                  </span>
                </div>
              ))}
            </Body>
            <Footer
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            >
              <Btn
                bg={Colors.RedE3}
                onClick={onRestart}
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <Spinner size={24} color="white" />
                ) : (
                  <VscDebugRestart size={24} color="white" />
                )}
              </Btn>
              <Input
                className="flex-1 w-full border-none rounded-md"
                rows={1}
                placeholder={"Message FiBi"}
                onChange={handleChange}
                disabled={masterLoading}
                value={input}
                onKeyDown={handleKeyDown}
              />

              <Btn
                disabled={!!input.trim() || masterLoading}
                onClick={() => sendMessage()}
              >
                {loading || historyLoading ? (
                  <Spinner size={24} color="white" />
                ) : (
                  <RiSendPlaneFill color="white" size={24} />
                )}
              </Btn>
            </Footer>
            <AiDisclaimer />
          </MiniContainer>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default FiBiText;

const Wrapper = styled.div`
  background-color: #f9fcff;
  background-color: white;
`;

const Container = styled.div`
  /* padding: 100px 0; */
  padding: 50px 0;
`;

const MiniContainer = styled.div`
  min-height: calc(100vh - 200px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  gap: 24px;
`;

const Body = styled.div`
  overflow-y: auto;
  border: 1px solid #b3bac1;
  border-radius: 8px;
  background-color: #f9fcff;
  padding: 24px;
`;

// const Input = styled.input`
//   height: 100%;
//   background-color: transparent;
//   width: 100%;
//   padding: 0.5rem;
//   &:focus-visible {
//     outline: 0;
//   }
// `;

const Input = styled.textarea`
  width: 100%;
  min-height: 100%;
  max-height: 6.5em;
  /* overflow-y: hidden; */
  resize: none;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  background: none !important;
`;

const Footer = styled.form`
  min-height: 56px;
  border: 1px solid #b3bac1;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  gap: 12px;
  /* position: sticky;
  bottom: 0;
  left: 0; */
`;

interface BtnProps {
  bg?: string;
}

const Btn = styled.button<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) => (bg ? bg : Colors.Blue00)};
  width: 48px;
  height: 48px;
  border-radius: 4px;
  flex-shrink: 0;

  &:disabled {
    background-color: #b3bac1;
  }
`;
