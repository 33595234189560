import styled from "styled-components";
import { Form, Formik } from "formik";
import { BusinessBoxWrapper, SingleCaseBoxHeader } from "./styles";
import { FC } from "react";
import InputField from "../../../../../components/formik fields/InputField";
import PhoneFormatter from "../../../../../components/formik fields/PhoneFormatter";

interface Props {
  name: string;
  email: string;
  phoneNumber: string;
}

const ClientInfo: FC<Props> = ({ name, email, phoneNumber }) => {
  return (
    <Wrapper>
      <SingleCaseBoxHeader>Client Information</SingleCaseBoxHeader>
      <Formik
        initialValues={{
          name: name,
          email: email,
          phoneNumber: phoneNumber,
        }}
        enableReinitialize
        onSubmit={() => {}}
      >
        <Form>
          <ClientFlexColumn>
            <ClientFlexRow>
              <InputField name="name" label="Name" isDisabled />
              <InputField name="email" label="Email" isDisabled />
            </ClientFlexRow>
            <ClientFlexRow>
              <PhoneFormatter
                name="phoneNumber"
                label="Phone Number"
                isDisabled
              />
              {/* <div></div> */}
            </ClientFlexRow>
          </ClientFlexColumn>
        </Form>
      </Formik>
    </Wrapper>
  );
};

export default ClientInfo;

const Wrapper = styled(BusinessBoxWrapper)``;

const ClientFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ClientFlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 21px;

  & > div {
    flex: 1;
  }
`;
