import { useEffect, useRef } from "react";

const useAudio = () => {
  const audioRef = useRef(new Audio());
  const audioContextRef = useRef<AudioContext | null>(null);
  const mediaSourceRef = useRef<MediaElementAudioSourceNode | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) return; // Prevent reinitialization

    // Create a single AudioContext
    const AudioContextClass =
      window.AudioContext || (window as any).webkitAudioContext;
    const audioContext = new AudioContextClass();
    audioContextRef.current = audioContext;

    // If the AudioContext is suspended (common on mobile), resume it
    if (audioContext.state === "suspended") {
      audioContext.resume();
    }

    try {
      // Create the MediaElementSource (only once)
      const mediaSource = audioContext.createMediaElementSource(
        audioRef.current
      );
      mediaSourceRef.current = mediaSource;

      // Create an analyser using the same AudioContext
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      analyserRef.current = analyser;

      mediaSource.connect(analyser);
      analyser.connect(audioContext.destination);

      isInitialized.current = true;
    } catch (error) {
      console.error("Error setting up audio nodes:", error);
    }
  }, []);

  return { audioRef, mediaSourceRef, audioContextRef, analyserRef };
};

export default useAudio;
