import { FormDS160Progress } from "./types";
import { leftSideBarDataProps } from "../../components/type";
import { WETAdditionalFormData } from "./Work edu training/Additional";
import { GenericEligibilityYesNoValues } from "../../checkEligibilty/components/types";
import { DS160EligibilityPayload } from "../../../../api/ds160/types";
import { GenericYesNoOptionsValue } from "../../components/data";
import { FormikProps } from "formik";
import { TooltipType } from "../../components/Tooltip";
import { SingleOption, UserDataObj } from "../../../../types/types";
import { ReviewSectionChildrenData } from "../../components/Review/ReviewBox";
import { PIPersonal1FormData } from "./Personal info/PIPersonal1";

const data = [
  // {
  //   label: "Get Started",
  //   formProgress: FormDS160Progress.getStarted,
  // },
  {
    label: "Personal Information",
    children: [
      {
        name: "Personal 1",
        formProgress: FormDS160Progress.personalInfoP1,
      },
      {
        name: "Personal 2",
        formProgress: FormDS160Progress.personalInfoP2,
      },
    ],
  },
  {
    label: "Travel",
    formProgress: FormDS160Progress.travel,
  },
  {
    label: "Travel Companions",
    formProgress: FormDS160Progress.travelCompanions,
  },
  {
    label: "Previous US Travel",
    formProgress: FormDS160Progress.previousUSTravel,
  },
  {
    label: "Address and Phone",
    formProgress: FormDS160Progress.addressAndPhone,
  },
  {
    label: "Passport",
    formProgress: FormDS160Progress.passport,
  },
  {
    label: "US Contact",
    formProgress: FormDS160Progress.usContact,
  },
  {
    label: "Family",
    children: [
      {
        name: "Relatives",
        formProgress: FormDS160Progress.familyRelatives,
      },
      {
        name: "Marital Status",
        formProgress: FormDS160Progress.maritalStatus,
      },
    ],
  },
  {
    label: "Work/Education/Training",
    children: [
      {
        name: "Present",
        formProgress: FormDS160Progress.WETPresent,
      },
      {
        name: "Previous",
        formProgress: FormDS160Progress.WETPrevious,
      },
      {
        name: "Additional",
        formProgress: FormDS160Progress.WETAdditional,
      },
    ],
  },
  {
    label: "Security/Background",
    children: [
      {
        name: "Part 1",
        formProgress: FormDS160Progress.securityBackgroundP1,
      },
      {
        name: "Part 2",
        formProgress: FormDS160Progress.securityBackgroundP2,
      },
      {
        name: "Part 3",
        formProgress: FormDS160Progress.securityBackgroundP3,
      },
      {
        name: "Part 4",
        formProgress: FormDS160Progress.securityBackgroundP4,
      },
      {
        name: "Part 5",
        formProgress: FormDS160Progress.securityBackgroundP5,
      },
    ],
  },
  {
    label: "Review and Download",
    formProgress: FormDS160Progress.review,
  },
];

export const ds160leftSideBarData = (
  isToStudy: boolean
): leftSideBarDataProps[] => {
  if (isToStudy) {
    const studentObj = {
      label: "Student/Exchange Visa",
      children: [
        {
          name: "Additional Contact",
          formProgress: FormDS160Progress.studentExchangeVisaAdditional,
        },
        {
          name: "SEVIS",
          formProgress: FormDS160Progress.studentExchangeVisaSEVIS,
        },
      ],
    };

    const temp = [...data];

    temp.splice(temp.length - 1, 0, studentObj);

    return temp;
  } else return data;
};

export enum PurposeTripOptionsValues {
  "Temp. Business Pleasure Visitor" = "Temp. Business Pleasure Visitor",
  "Academic or Language Student" = "Academic or Language Student",
}

export const PurposeTripOptions = [
  // {
  //   value: "Foreign Government Officials",
  //   label: "Foreign Government Officials",
  // },
  {
    value: "Temp. Business Pleasure Visitor",
    label: PurposeTripOptionsValues["Temp. Business Pleasure Visitor"],
  },
  // {
  //   value: "Alien in Transit",
  //   label: "Alien in Transit",
  // },
  // {
  //   value: "CNMI Worker or Investor",
  //   label: "CNMI Worker or Investor",
  // },
  // {
  //   value: "Crew member",
  //   label: "Crew member",
  // },
  // {
  //   value: "Treaty Trader or Investor",
  //   label: "Treaty Trader or Investor",
  // },
  {
    value: "Academic or Language Student",
    label: PurposeTripOptionsValues["Academic or Language Student"],
  },
  // {
  //   value: "International Organization Rep/Emp",
  //   label: "International Organization Rep/Emp",
  // },
  // {
  //   value: "Temporary Worker",
  //   label: "Temporary Worker",
  // },
  // {
  //   value: "Foreign Media Representative",
  //   label: "Foreign Media Representative",
  // },
  // {
  //   value: "Exchange Visitor",
  //   label: "Exchange Visitor",
  // },
  // {
  //   value: "Fiance(e) or Spouse of U.S Citizen",
  //   label: "Fiance(e) or Spouse of U.S Citizen",
  // },
  // {
  //   value: "Intracompany Transferee",
  //   label: "Intracompany Transferee",
  // },
  // {
  //   value: "Vocational/Nonacademic Student",
  //   label: "Vocational/Nonacademic Student",
  // },
  // {
  //   value: "Other",
  //   label: "Other",
  // },
  // {
  //   value: "NATO Staff",
  //   label: "NATO Staff",
  // },
  // {
  //   value: "Alien with extraordinary ability",
  //   label: "Alien with extraordinary ability",
  // },
  // {
  //   value: "Internationally Recognized Alien",
  //   label: "Internationally Recognized Alien",
  // },
  // {
  //   value: "Cultural Exchange Visitor",
  //   label: "Cultural Exchange Visitor",
  // },
  // {
  //   value: "Religious Worker",
  //   label: "Religious Worker",
  // },
  // {
  //   value: "Informant or Witness",
  //   label: "Informant or Witness",
  // },
  // {
  //   value: "Victim of Trafficking",
  //   label: "Victim of Trafficking",
  // },
  // {
  //   value: "NAFTA Professional",
  //   label: "NAFTA Professional",
  // },
  // {
  //   value: "Victim of CriminaL Activity",
  //   label: "Victim of CriminaL Activity",
  // },
  // {
  //   value: "Parole Beneficiary (PARCIS)",
  //   label: "Parole Beneficiary (PARCIS)",
  // },
];

export enum AllSpecificOptionsValues {
  "B1/B2" = "B1/B2",
  "B1" = "B1",
  "B2" = "B2",
  "Others" = "Others",
  "Student (F1)" = "Student (F1)",
  "Child of an F1 (F2)" = "Child of an F1 (F2)",
  "Spouse of an F1 (F2)" = "Spouse of an F1 (F2)",
}

export const BusinessSpecificOptions = [
  {
    label: "Business & Tourism (Temporary Visitor) (B1/B2)",
    value: AllSpecificOptionsValues["B1/B2"],
  },
  {
    label: "Business/Conference (B1)",
    value: AllSpecificOptionsValues["B1"],
  },
  {
    label: "Tourism/Medical Treatment (B2)",
    value: AllSpecificOptionsValues["B2"],
  },
];

export const AcademicSpecificOptions = [
  {
    label: "Student (F1)",
    value: AllSpecificOptionsValues["Student (F1)"],
  },
  {
    label: "Child of an F1 (F2)",
    value: AllSpecificOptionsValues["Child of an F1 (F2)"],
  },
  {
    label: "Spouse of an F1 (F2)",
    value: AllSpecificOptionsValues["Spouse of an F1 (F2)"],
  },
];

export const AllSpecificOptions = BusinessSpecificOptions.concat(
  AcademicSpecificOptions
).concat([
  {
    label: "Others",
    value: AllSpecificOptionsValues["Others"],
  },
]);

export const PassportType = [
  {
    value: "Regular",
    label: "Regular",
  },
  {
    value: "Official",
    label: "Official",
  },
  {
    value: "Diplomatic",
    label: "Diplomatic",
  },
  {
    value: "Laissez-Passez",
    label: "Laissez-Passez",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const RelationshipToYou = [
  {
    value: "Relative",
    label: "Relative",
  },
  {
    value: "Spouse",
    label: "Spouse",
  },
  {
    value: "Friend",
    label: "Friend",
  },
  {
    value: "Business Associate",
    label: "Business Associate",
  },
  {
    value: "Employer",
    label: "Employer",
  },
  {
    value: "School Official",
    label: "School Official",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const ImmigrationStatus = [
  {
    value: "US Citizen",
    label: "US Citizen",
  },
  {
    value: "U.S Legal Permanent resident (LPR)",
    label: "U.S Legal Permanent resident (LPR)",
  },
  {
    value: "Nonimmigrant",
    label: "Nonimmigrant",
  },
  {
    value: "Other/I don’t know",
    label: "Other/I don’t know",
  },
];

export const RelativeStatus = [
  {
    value: "Spouse",
    label: "Spouse",
  },
  {
    value: "Fiance/Fiancee",
    label: "Fiance/Fiancee",
  },
  {
    value: "Child",
    label: "Child",
  },
  {
    value: "Sibling",
    label: "Sibling",
  },
];

export const PIPersonal1Gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Others", value: "others" },
];

export enum PersonPayingOptionsValues {
  "Self" = "Self",
  "Other Person" = "Other Person",
  "Present Employer" = "Present Employer",
  "Employer in the US" = "Employer in the US",
  "Other Company/Organization" = "Other Company/Organization",
}

export const PersonPayingOptions = [
  {
    value: "Self",
    label: PersonPayingOptionsValues.Self,
  },
  {
    value: "Other Person",
    label: PersonPayingOptionsValues["Other Person"],
  },
  {
    value: "Present Employer",
    label: PersonPayingOptionsValues["Present Employer"],
  },
  {
    value: "Employer in the US",
    label: PersonPayingOptionsValues["Employer in the US"],
  },
  {
    value: "Other Company/Organization",
    label: PersonPayingOptionsValues["Other Company/Organization"],
  },
];

export const RelationShipOptions = [
  {
    value: "Parent",
    label: "Parent",
  },
  {
    value: "Child",
    label: "Child",
  },
  {
    value: "Spouse",
    label: "Spouse",
  },
  {
    value: "Other Relative",
    label: "Other Relative",
  },
  {
    value: "Friend",
    label: "Friend",
  },
  {
    value: "Business Associate",
    label: "Business Associate",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const PersonPayingRelationship = RelationShipOptions.filter(
  (ev) => ev.value !== "Business Associate"
);

export const SocialMediaObjData = [
  "ASK FM",
  "DOUBAN",
  "FACEBOOK",
  "FLICKR",
  "GOOGLE+",
  "INSTAGRAM",
  "LINKEDIN",
  "MYSPACE",
  "PINTEREST",
  "QZONE (QQ)",
  "REDDIT",
  "SINA WEIBO",
  "TENCENT WEIBO",
  "TUMBLR",
  "TWITTER",
  "TWOO",
  "VINE",
  "VKONTAKTE (VK)",
  "YOUKU",
  "YOUTUBE",
  "NONE",
];

export const SocialMediaData = SocialMediaObjData.map((ev) => ({
  label: ev,
  value: ev,
}));

export const PrimaryOccupationData = [
  { label: "Agriculture", value: "Agriculture" },
  { label: "Artist/Performer", value: "Artist/Performer" },
  { label: "Business", value: "Business" },
  { label: "Communications", value: "Communications" },
  { label: "Computer Science", value: "Computer Science" },
  { label: "Culinary/Food Services", value: "Culinary/Food Services" },
  { label: "Education", value: "Education" },
  { label: "Engineering", value: "Engineering" },
  { label: "Government", value: "Government" },
  { label: "Homemaker", value: "Homemaker" },
  { label: "Legal Profession", value: "Business" },
  { label: "Medical Health", value: "Medical Health" },
  { label: "Military", value: "Military" },
  { label: "Natural Science", value: "Natural Science" },
  { label: "Not employed", value: "Not employed" },
  { label: "Physical Sciences", value: "Physical Sciences" },
  { label: "Religious Vocation", value: "Religious Vocation" },
  { label: "Research", value: "Research" },
  { label: "Retired", value: "Retired" },
];

export const USText = "United States";

export const WETATravelledLastFiveYearsCondition = (
  data: WETAdditionalFormData,
  formEligibility: DS160EligibilityPayload
) => {
  const scenerio1 =
    data.travelToCountriesOrRegions === GenericYesNoOptionsValue.yes &&
    formEligibility.have_you_traveled_to_any_other_countries_in_the_past_five_years_aside_country ===
      GenericEligibilityYesNoValues.No;

  const scenerio2 =
    data.travelToCountriesOrRegions === GenericYesNoOptionsValue.no &&
    formEligibility.have_you_traveled_to_any_other_countries_in_the_past_five_years_aside_country ===
      GenericEligibilityYesNoValues.Yes;

  return scenerio1 || scenerio2;
};

export const getSecurityBackgroundValidations = (
  formik: FormikProps<any>,
  name: string,
  isNo?: boolean
) => {
  let temp: any;
  const object = formik.values;
  Object.keys(object).forEach((key) => {
    const value = (object as any)[key];

    if (
      name === key &&
      (isNo
        ? value === GenericYesNoOptionsValue.no
        : value === GenericYesNoOptionsValue.yes)
    ) {
      temp = {
        isReversed: true,
        type: TooltipType.risky,
        body: "Due to your selected option, it is advisable you connect with an attorney.",
        checkLabel:
          "I acknowledge that I have consulted with an attorney and/or want to continue anyway",
      };
      return;
    }
  });

  return temp;
};

export enum IntendedLengthOfStayDropDownValues {
  "Year(S)" = "Year(S)",
  "Month(S)" = "Month(S)",
  "Week(S)" = "Week(S)",
  "Day(S)" = "Day(S)",
}

export const IntendedLengthOfStayDropDown = [
  {
    value: "Year(S)",
    label: IntendedLengthOfStayDropDownValues["Year(S)"],
  },
  {
    value: "Month(S)",
    label: IntendedLengthOfStayDropDownValues["Month(S)"],
  },
  {
    value: "Week(S)",
    label: IntendedLengthOfStayDropDownValues["Week(S)"],
  },
  {
    value: "Day(S)",
    label: IntendedLengthOfStayDropDownValues["Day(S)"],
  },
];

const VisaLocationsData = [
  "ALBANIA, TIRANA",
  "ALGERIA, ALGIERS",
  "ANGOLA, LUANDA",
  "ARGENTINA, BUENOS AIRES",
  "ARMENIA, YEREVAN",
  "AUSTRALIA, MELBOURNE",
  "AUSTRALIA, PERTH",
  "AUSTRALIA, SYDNEY",
  "AUSTRIA, VIENNA",
  "AZERBAIJAN, BAKU",
  "BAHAMAS, NASSAU",
  "BAHRAIN, MANAMA",
  "BANGLADESH, DHAKA",
  "BARBADOS, BRIDGETOWN",
  "BELGIUM, BRUSSELS",
  "BELIZE, BELMOPAN",
  "BENIN, COTONOU",
  "BERMUDA, HAMILTON",
  "BOLIVIA, LA PAZ",
  "BOSNIA-HERZEGOVINA, SARAJEVO",
  "BOTSWANA, GABORONE",
  "BRAZIL, BRASILIA",
  "BRAZIL, PORTO ALEGRE",
  "BRAZIL, RECIFE",
  "BRAZIL, RIO DE JANEIRO",
  "BRAZIL, SAO PAULO",
  "BRUNEI, BANDAR SERI BEGAWAN",
  "BULGARIA, SOFIA",
  "BURKINA FASO, OUAGADOUGOU",
  "BURMA, RANGOON",
  "BURUNDI, BUJUMBURA",
  "CABO VERDE, PRAIA",
  "CAMBODIA, PHNOM PENH",
  "CAMEROON, YAOUNDE",
  "CANADA, CALGARY",
  "CANADA, HALIFAX",
  "CANADA, MONTREAL",
  "CANADA, OTTAWA",
  "CANADA, QUEBEC",
  "CANADA, TORONTO",
  "CANADA, VANCOUVER",
  "CHAD, N'DJAMENA",
  "CHILE, SANTIAGO",
  "CHINA, BEIJING",
  "CHINA, GUANGZHOU",
  "CHINA, SHANGHAI",
  "CHINA, SHENYANG",
  "CHINA, WUHAN",
  "COLOMBIA, BOGOTA",
  "CONGO, BRAZZAVILLE",
  "CONGO, KINSHASA",
  "COSTA RICA, SAN JOSE",
  "COTE D'IVORIE, ABIDJAN",
  "CROATIA, ZAGREB",
  "CUBA, HAVANA",
  "CURACAO, CURACAO",
  "CYPRUS, NICOSIA",
  "CZECH REPUBLIC, PRAGUE",
  "DENMARK, COPEN-HAGEN",
  "DJIBOUTI, DJIBOUTI",
  "DOMINICAN REPUBLIC, SANTO DOMINGO",
  "ECUADOR, GUAYAQUIL",
  "ECUADOR, QUITO",
  "EGYPT, CAIRO",
  "EL SALVADOR, SAN SALVADOR",
  "ENGLAND, LONDON",
  "EQUATORIAL GUINEA, MALABO",
  "ERITREA, ASMARA",
  "ESTONIA, TALLINN",
  "ESWATINI, MBABANE",
  "ETHIOPIA, ADDIS ABABA",
  "FIJI, SUVA",
  "FINLAND, HELSINKI",
  "FRANCE, PARIS",
  "GABON, LIBREVILLE",
  "GAMBIA, BANJUL",
  "GEORGIA, TBILISI",
  "GERMANY, BERLIN",
  "GERMANY, FRANKFURT",
  "GERMANY, MUNICH",
  "GHANA, ACCRA",
  "GREECE, ATHENS",
  "GUATEMALA, GUATEMALA CITY",
  "GUINEA, CONAKRY",
  "GUYANA, GEORGETOWN",
  "HAITI, PORT-AU-PRINCE",
  "HONDURAS, TEGUCIGALPA",
  "HONG KONG",
  "HUNGARY, BUDAPEST",
  "ICELAND, REYKJAVIK",
  "INDIA, CHENNAI",
  "INDIA, HYDERABAD",
  "INDIA, KOLKATA",
  "INDIA, MUMBAI",
  "INDIA, NEW DELHI",
  "INDONESIA, JAKARTA",
  "INDONESIA, SURABAYA",
  "IRAQ, BAGHDAD",
  "IRAQ, ERBIL",
  "IRELAND, DUBLIN",
  "ISRAEL, TEL AVIV",
  "ITALY, FLORENCE",
  "ITALY, MILAN",
  "ITALY, NAPLES",
  "ITALY, ROME",
  "JAMAICA, KINGSTON",
  "JAPAN, NAHA",
  "JAPAN, OSAKA/FUKUOKA",
  "JAPAN, TOKYO/SAPPORO",
  "JERUSALEM",
  "JORDAN, AMMAN",
  "KAZAKHSTAN, ALMATY",
  "KAZAKHSTAN, ASTANA",
  "KENYA, NAIROBI",
  "KOSOVO, PRISTINA",
  "KUWAIT, KUWAIT CITY",
  "KYRGYZSTAN, BISHKEK",
  "LAOS, VIENTIANE",
  "LATVIA, RIGA",
  "LEBANON, BEIRUT",
  "LESOTHO, MASERU",
  "LIBERIA, MONROVIA",
  "LITHUANIA, VILNIUS",
  "LUXEMBOURG, LUXEMBOURG",
  "MADAGASCAR, ANTANANARIVO",
  "MALAWI, LILONGWE",
  "MALAYSIA, KUALA LUMPUR",
  "MALI, BAMAKO",
  "MALTA, VALLETTA",
  "MARSHALL ISLANDS, MAJURO",
  "MAURITANIA, NOUAKCHOTT",
  "MAURITIUS, PORT LOUIS",
  "MEXICO, CIUDAD JUAREZ",
  "MEXICO, GUADALAJARA",
  "MEXICO, HERMOSILLO",
  "MEXICO, MATAMOROS",
  "MEXICO, MERIDA",
  "MEXICO, MEXICO CITY",
  "MEXICO, MONTERREY",
  "MEXICO, NOGALES",
  "MEXICO, NUEVO LAREDO",
  "MEXICO, TIJUANA",
  "MICRONESIA, KOLONIA",
  "MOLDOVA, CHISINAU",
  "MONGOLIA, ULAAN-BAATAR",
  "MONTENEGRO, PODGORICA",
  "MOROCCO, CASABLANCA",
  "MOZAMBIQUE, MAPUTO",
  "NAMIBIA, WINDHOEK",
  "NEPAL, KATHMANDU",
  "NETHERLANDS, AMSTERDAM",
  "NEW ZEALAND, AUCKLAND",
  "NICARAGUA, MANAGUA",
  "NIGER, NIAMEY",
  "NIGERIA, ABUJA",
  "NIGERIA, LAGOS",
  "NORTH MACEDONIA, SKOPJE",
  "NORTHERN IRELAND, BELFAST",
  "PALAU, KOROR",
  "PANAMA, PANAMA CITY",
  "PAPUA NEW GUINEA, PORT MORESBY",
  "PARAGUAY, ASUNCION",
  "PERU, LIMA",
  "PHILIPPINES, MANILA",
  "POLAND, KRAKOW",
  "POLAND, WARSAW",
  "PORTUGAL, LISBON",
  "QATAR, DOHA",
  "ROMANIA, BUCHAREST",
  "RUSSIA, MOSCOW",
  "RWANDA, KIGALI",
  "SAMOA, APIA",
  "SAUDI ARABIA,DHAHRAN",
  "SAUDI ARABIA, JEDDAH",
  "SAUDI ARABIA, RIYADH",
  "SENEGAL, DAKAR",
  "SERBIA, BELGRADE",
  "SIERRA LEONE, FREETOWN",
  "SINGAPORE, SINGAPORE",
  "SLOVAKIA, BRATISLAVA",
  "SLOVENIA, LJUBLJANA",
  "SOUTH AFRICA, CAPE TOWN",
  "SOUTH AFRICA, DURBAN",
  "SOUTH AFRICA, JOHANNESBURG",
  "SOUTH SUDAN, JUBA",
  "SPAIN, MADRID",
  "SRI LANKA, COLOMBO",
  "SURINAME, PARAMARIBO",
  "SWEDEN, STOCKHOLM",
  "SWITZERLAND, BERN",
  "TAIWAN, TAIPEI",
  "TAJIKISTAN, DUSHANBE",
  "TANZANIA, DAR ES SALAAM",
  "THAILAND, BANGKOK",
  "THAILAND, CHIANG MAI",
  "TIMOR LESTE, DILI",
  "TOGO, LOME",
  "TRINIDAD, PORT OF SPAIN",
  "TUNISIA, TUNIS",
  "TURKEY, ANKARA",
  "TURKEY, ISTANBUL",
  "TURKMENISTAN, ASHGABAT",
  "U.S. - DOMESTIC",
  "UGANDA, KAMPALA",
  "UKRAINE, KYIV",
  "UNITED ARAB EMIRATES, ABU DHABI",
  "UNITED ARAB EMIRATES, DUBAI",
  "URUGUAY, MONTEVIDEO",
  "UZBEKISTAN, TASHKENT",
  "VIETNAM, HANOI",
  "VIETNAM, HO CHI MINH CITY",
  "ZAMBIA, LUSAKA",
  "ZIMBABWE, HARARE",
];

export const FormattedVisaLocationsData = VisaLocationsData.map((ev) => ({
  label: ev,
  value: ev,
}));

export enum DS160MaritalStatusValues {
  married = "Married",
  commonLaw = "Common Law Marriage",
  civilUnion = "Civil Union/Domestic Partnership",
  Single = "Single",
  Widowed = "Widowed",
  Divorced = "Divorced",
  legallySeparated = "Legally Separated",
  Other = "Other",
}

export const DS160MaritalStatusData: SingleOption[] = [
  { value: DS160MaritalStatusValues.married, label: "Married" },
  {
    value: DS160MaritalStatusValues.commonLaw,
    label: "Common Law Marriage",
  },
  {
    value: DS160MaritalStatusValues.civilUnion,
    label: "Civil Union/Domestic Partnership",
  },
  { value: DS160MaritalStatusValues.Single, label: "Single" },
  { value: DS160MaritalStatusValues.Widowed, label: "Widowed" },
  {
    value: DS160MaritalStatusValues.Divorced,
    label: "Divorced",
  },
  {
    value: DS160MaritalStatusValues.legallySeparated,
    label: "Legally Separated",
  },
  {
    value: DS160MaritalStatusValues.Other,
    label: "Other",
  },
];

export enum PersonAddressOptionsValues {
  home = "Same as home address",
  mail = "Same as mailing address",
  UScontact = "Same as US contact address",
  doNotKnow = "Do not know",
  other = "Other (Specify Address)",
}

export const PersonAddressOptions = [
  {
    value: "Same as home address",
    label: PersonAddressOptionsValues.home,
  },
  {
    value: "Same as mailing address",
    label: PersonAddressOptionsValues.mail,
  },
  {
    value: "Same as US contact address",
    label: PersonAddressOptionsValues.UScontact,
  },
  {
    value: "Do not know",
    label: PersonAddressOptionsValues.doNotKnow,
  },
  {
    value: "Other (Specify Address)",
    label: PersonAddressOptionsValues.other,
  },
];

export function formatString(str?: string) {
  return str?.replace(/\./g, "").replace(/\//g, "or");
}

export const processReviewData = (
  sectionData: ReviewSectionChildrenData[]
): Record<string, string> => {
  const objArray = sectionData
    .flatMap((item) => {
      if (!item.section)
        return {
          [`${formatString(item.label)}`]: item.value || "",
        };

      return item.section.children.flatMap((child) => {
        if (child.section) {
          return child.section.children.map((mini) => ({
            [`${formatString(item.section?.title)} ${formatString(
              mini.label
            )}`]: mini.value || "",
          }));
        } else {
          return [
            {
              [`${formatString(item.section?.title)} ${formatString(
                child.label
              )}`]: child.value || "",
            },
          ];
        }
      });
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  function removeEmptyFields(obj: { [x: string]: string }) {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, value]) =>
          value !== null && value !== undefined && value !== "" && value !== " "
      )
    );
  }

  return removeEmptyFields(objArray);
};

export const isAiErrorCheck = (
  formProgress: FormDS160Progress,
  userData?: UserDataObj
) => {
  return userData?.ds160FormInfo.isFormCompleted
    ? true
    : !(userData?.currentFormInfo.formProgress === formProgress);
};

export const PersonalWhereApplyingVisaFromCondition = (
  data: PIPersonal1FormData,
  formEligibility: DS160EligibilityPayload
) => {
  const scenerio1 =
    data.location &&
    data.location?.value.split(",")[0].toLowerCase() !==
      formEligibility.where_are_applying_from.toLowerCase();

  return scenerio1;
};
