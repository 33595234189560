import { Form, Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import { BusinessSignUpFormData } from "./SignUp";
import { BusinessSignUpValidation } from "./validation";
import InputField from "../../components/formik fields/InputField";
import { BusinessTypeData } from "./data";
import SelectField from "../../components/formik fields/SelectField";
import styled from "styled-components";
import PhoneFormatter from "../../components/formik fields/PhoneFormatter";
import useCountries from "../../components/useCountries";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import { SigninBtn } from "./Login";
import Spinner from "../../../../utils/Spinner";
import Colors from "../../../../styles/Colors";

interface Props {
  businessOnSubmit: (
    values: BusinessSignUpFormData,
    { resetForm }: FormikHelpers<BusinessSignUpFormData>
  ) => void;
  businessLoading: boolean;
}

const BusinessSignup: FC<Props> = ({ businessOnSubmit, businessLoading }) => {
  const { formattedCountryData } = useCountries();

  return (
    <>
      <Formik
        initialValues={
          {
            businessType: null,
            businessName: "",
            businessNumber: "",
            businessEmail: "",
            country: null,
            businessAddress: "",
            businessPassword: "",
            businessConfirmPassword: "",
            businessAgreement: false,
          } as BusinessSignUpFormData
        }
        validationSchema={BusinessSignUpValidation}
        onSubmit={businessOnSubmit}
      >
        {(formik) => (
          <Former>
            <SelectField
              name="businessType"
              label="What type of business do you own?"
              options={BusinessTypeData}
            />
            <InputField
              name="businessName"
              label="Business Name"
              placeholder=""
            />

            <PhoneFormatter name="businessNumber" label="Business Number" />

            <InputField
              name="businessEmail"
              label="Business Email"
              placeholder=""
            />

            <SelectField
              name="country"
              label="Business Country"
              placeholder=""
              options={formattedCountryData}
            />

            <InputField
              name="businessAddress"
              label="Business Address"
              placeholder=""
            />

            <InputField
              name="businessPassword"
              label="Password"
              placeholder=""
              inputType="password"
            />

            <InputField
              name="businessConfirmPassword"
              label="Confirm Password"
              placeholder=""
              inputType="password"
            />

            {/* <CheckBoxField
                label={
                  <p>Check this box if you are signing up as a Business </p>
                }
                name="isAgent"
              /> */}

            <CheckBoxField
              label={
                <>
                  By checking this box, you confirm that you've read and
                  accepted our{" "}
                  <a
                    href="https://app.termly.io/document/terms-of-service/52cfeaf3-bc98-4aee-8e68-ae9526870a50"
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#0076E9]"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://app.termly.io/document/privacy-policy/22fda0c1-0085-4dac-aabd-11a41a97895a"
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#0076E9]"
                  >
                    Privacy Policy.
                  </a>
                </>
              }
              name="businessAgreement"
            />

            <SigninBtn disabled={businessLoading}>
              {businessLoading && <Spinner color={Colors.White} size={14} />}
              Sign Up
            </SigninBtn>
          </Former>
        )}
      </Formik>
    </>
  );
};

export default BusinessSignup;

const Former = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
