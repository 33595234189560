import { FC } from "react";
import { BallIcon, CheckboxContainer, HiddenCheckbox, IconBox } from "./style";

interface Props {
  value: boolean;
  setValue: (value: boolean) => void;
  onChange?: () => void;
}

const CheckboxBall: FC<Props> = ({ value, setValue, onChange }) => {
  return (
    <CheckboxContainer
      checked={value}
      onClick={() => {
        setValue(!value);
        onChange && onChange();
      }}
    >
      <HiddenCheckbox checked={value} />
      <IconBox checked={value}>
        <BallIcon checked={value} />
      </IconBox>
    </CheckboxContainer>
  );
};

export default CheckboxBall;
