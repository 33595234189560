import React from "react";
import Hero from "./components/Hero";
import FiBiChoices from "./components/FiBiChoices";
import Features from "./components/Features";
import FeaturedVideo from "./components/FeaturedVideo";
import SEOMeta from "../../components/SEOMeta";
import { WebPaths } from "../../../../routes/data";
import { SVLogoSquare } from "../../../../images";

const FiBi = () => {
  return (
    <div>
      <SEOMeta
        title="FiBi AI Assistant | Your Personal Interview Trainer"
        description="Meet FiBi, your AI-powered assistant for visa risk checks, form reviews, and interview simulations. Get the expert guidance you need to navigate the visa application process."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.FiBiAi}`}
        url={`https://www.seamlessvisa.com${WebPaths.FiBiAi}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="FiBi AI Assistant | SeamlessVisa"
        twitterDescription="Meet FiBi, your AI-powered assistant for visa risk checks, form reviews, and interview simulations. Get the expert guidance you need to navigate the visa application process."
        twitterSite="@SeamlessVisa_"
      />

      <Hero />
      <FiBiChoices />
      <Features />
      <FeaturedVideo />
    </div>
  );
};

export default FiBi;

// http://192.168.1.159:3000/fibi-voice-interview-prep/?e=YWZsbjd5OGI5N0Bvc3hvZnVsay5jb20=&p=VWdoblVvakdobw==&b=UUhpUHBmODhWamJPUEJuZEpBcDdEUkVUU1ZmMg==&type=business-client
