import { motion } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import Overlay from "../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../checkEligibilty/components/modals/variant";
import Spinner from "../../../utils/Spinner";
import { mediaObj } from "../../../styles/Media";
import Colors from "../../../styles/Colors";

interface Props {
  title: string;
  subText: string | JSX.Element;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  cancelText?: string;
  confirmText?: string;
  confirmBg?: string;
  onCancel?: () => void;
}

const TerminateModal: FC<Props> = ({
  title,
  subText,
  onClose,
  onConfirm,
  isLoading,
  cancelText,
  confirmText,
  confirmBg,
  onCancel,
}) => {
  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Header>{title}</Header>
        <Para>{subText}</Para>
        <BtnWrapper>
          <CancelBtn
            onClick={onCancel ? onCancel : onClose}
            disabled={isLoading}
          >
            {cancelText ? cancelText : `No, I’m not`}
          </CancelBtn>
          <ConfirmBtn
            onClick={onConfirm}
            disabled={isLoading}
            confirmBg={confirmBg}
          >
            {isLoading ? (
              <Spinner color={Colors.White} />
            ) : confirmText ? (
              confirmText
            ) : (
              `Yes, I’m sure`
            )}
          </ConfirmBtn>
        </BtnWrapper>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default TerminateModal;

const Wrapper = styled(motion.div)`
  width: 437px;
  height: max-content;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
`;

const Para = styled.p`
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  text-align: center;
  color: rgba(27, 9, 44, 0.9);
  margin-top: 16px;
  margin-bottom: 40px;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaObj.bigMobile} {
    flex-wrap: wrap;
    gap: 10px;
    & > button {
      flex: 1;
    }
  }
`;

const Btn = styled.button`
  border: none;
  cursor: pointer;
  height: 50px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 13px 45px;
  border-radius: 30px;
  white-space: nowrap;
  min-width: 160px;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CancelBtn = styled(Btn)`
  background: #e6e6e6;
  color: #1b092c99;
`;

interface ConfirmBtnProps {
  confirmBg?: string;
}

const ConfirmBtn = styled(Btn)<ConfirmBtnProps>`
  background: ${({ confirmBg }) => (confirmBg ? confirmBg : "#d50000")};
  color: #ffffff;
`;
