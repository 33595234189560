import { motion } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { ImCancelCircle } from "react-icons/im";
import Markdown from "markdown-to-jsx";
import Overlay from "./Overlay";
import { ContainerVariants } from "./variant";
import { mediaObj } from "../../../../../styles/Media";
import Colors from "../../../../../styles/Colors";
import RequestWrapper from "../../../components/RequestWrapper";

interface Props {
  onClose: () => void;
  analysis?: string;
  isError: boolean;
  isLoading: boolean;
}

const AnalysisModal: FC<Props> = ({
  onClose,
  analysis,
  isError,
  isLoading,
}) => {
  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel onClick={onClose}>
          <ImCancelCircle />
        </Cancel>
        <Header>Eligibility Analysis</Header>
        <RequestWrapper isError={isError} isLoading={isLoading} data={analysis}>
          <div className="pb-6 mt-4 mb-4 overflow-y-auto h-[70vh]">
            {analysis && (
              <Markdown>{analysis?.replace(/\n/gi, "&nbsp; \n\n")}</Markdown>
            )}
          </div>
        </RequestWrapper>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default AnalysisModal;

const Wrapper = styled(motion.div)`
  width: 500px;
  height: max-content;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 30px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
