import React from "react";
import { Message, MessageSender } from "./types";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import Colors from "../../../../../../styles/Colors";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    padding: 12,
    gap: 16,
  },
  messageContainer: {
    maxWidth: "70%",
    padding: 8,
    borderRadius: 20,
    fontSize: 16,
  },
  userMessage: {
    backgroundColor: Colors.Blue00,
    color: "white",
    alignSelf: "flex-end",
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 20,
  },
  otherMessage: {
    backgroundColor: "#E5E7EB",
    color: "black",
    alignSelf: "flex-start",
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 4,
  },
});

interface Props {
  messages: Message[];
}

const VoiceMessageDisplay: React.FC<Props> = ({ messages }) => {
  return (
    <View style={styles.container}>
      {messages.map((message, index) => (
        <Text
          key={index}
          style={[
            styles.messageContainer,
            message.sender === MessageSender.user
              ? styles.userMessage
              : styles.otherMessage,
          ]}
        >
          {message.message}
        </Text>
      ))}
    </View>
  );
};

export default VoiceMessageDisplay;
