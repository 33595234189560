import { Outlet, useLocation } from "react-router";
// import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/NavBar";
import ChatSystem from "../landing page/components/ChatSystem";
import { useSnapshot } from "valtio";
import { isBusinessDataStore } from "../../../store/isBusinessData";
import { isAnonClientDataStore } from "../../../store/isAnonClientData";
import { useEffect } from "react";
import storage from "../../../utils/storage";
import {
  businessFibiClientStore,
  seamlessFibiClientToken,
} from "../../../store/businessFibiClient";
import { loginFailed } from "../../../utils";

const Layout = () => {
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const { fibiClientToken } = useSnapshot(businessFibiClientStore);
  const location = useLocation();
  const isFibiBusinessVoicePage =
    location.pathname === "/fibi-voice-interview-prep/" &&
    new URLSearchParams(location.search).get("type") === "business-client";

  useEffect(() => {
    if (!isFibiBusinessVoicePage && fibiClientToken) {
      businessFibiClientStore.fibiClientToken = undefined;
      storage.remove(seamlessFibiClientToken);
      loginFailed();
    }
  }, [isFibiBusinessVoicePage, fibiClientToken]);

  useEffect(() => {
    if (isFibiBusinessVoicePage && isBusinessData) {
      //if it is a business and tries the invite
      loginFailed();
    }
  }, [isFibiBusinessVoicePage, isBusinessData]);

  return (
    <>
      {/* <Header /> */}
      <Navbar />
      {/* <div className="mt-24"> */}
      <Outlet />
      {/* </div> */}
      {!isBusinessData && !isAnonClientData && !fibiClientToken && (
        <ChatSystem />
      )}
      <Footer />
    </>
  );
};

export default Layout;
