import Overlay from "../checkEligibilty/components/modals/Overlay";
import styled from "styled-components";
import { motion } from "framer-motion";
import { mediaObj } from "../../../styles/Media";
import { ImCancelCircle } from "react-icons/im";
import Colors from "../../../styles/Colors";
import { PricingType } from "./data";
import { IoArrowForward } from "react-icons/io5";
import { ChangeEvent, useState } from "react";
import useGetPrice from "../../../hooks/useGetPrice";

// Props type for the modal
interface PricingModalProps {
  onModalClose: () => void; // a function to close the pricing modal
  pricing: PricingType[]; // an array of the pricing data type
  onContinue: (plan: "standard" | "premium", couponCode: string) => void; // a function that gets the clicked plan price and amount
  isDs160?: boolean;
}

const PricingModal: React.FC<PricingModalProps> = ({
  pricing,
  onModalClose,
  onContinue,
  isDs160,
}) => {
  const { getPrice } = useGetPrice();
  const [acknowledged, setAcknowledge] = useState(false);
  const [couponValue, setCouponValue] = useState<string>("");
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCouponValue(e.target.value);
  };

  return (
    <Overlay onClose={() => {}}>
      <Wrapper>
        {/* closing the pricing modal */}
        <Cancel
          onClick={() => {
            onModalClose();
          }}
        >
          <ImCancelCircle />
        </Cancel>

        {/* mapping through the provided data of pricing to display the pricing options */}
        <PricingWrapper>
          {pricing?.map((pricing, index) => (
            <PricingCard key={index} plan={pricing.plan}>
              <div>
                <h4>{pricing.package}</h4>
                <h1>{getPrice(pricing.price)}</h1>
                <a
                  href="https://www.uscis.gov/feecalculator"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pricing.extras}
                </a>
                <p className="font-bold">{pricing.upgrade}</p>
              </div>

              <ul>
                {pricing.offers.map((offer) => (
                  <li>{offer}</li>
                ))}
              </ul>

              {/* button that selects the current plan on the index */}
              <button
                onClick={() =>
                  // I hope for this part to be dynamic based on if or not there's a `{plan}` option on the pricin data
                  onContinue(pricing.plan, couponValue)
                }
                disabled={isDs160 ? false : !acknowledged}
              >
                continue <IoArrowForward />
              </button>
            </PricingCard>
          ))}
        </PricingWrapper>

        <div className="sm:w-[60%] my-4">
          <label
            htmlFor="couponCode"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Coupon Code
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="couponCode"
              value={couponValue}
              onChange={handleInputChange}
              id="couponCode"
              className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="SV10DISCNT"
              aria-describedby="couponCode-description"
            />
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description">
            If you have a coupon code enter it here.
          </p>
        </div>

        {!isDs160 && (
          <Consent>
            <div>
              <input
                type="checkbox"
                name="consent"
                id="consent"
                onChange={(e) => setAcknowledge(e.target.checked)}
              />
              <label htmlFor="consent">
                I have reviewed and understand the information in my
                application, and I confirm that it is true and accurate.
              </label>
            </div>
          </Consent>
        )}
      </Wrapper>
    </Overlay>
  );
};

export default PricingModal;

const Wrapper = styled(motion.div)`
  width: 60vw;
  max-height: 80vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 80vw;
    padding: 6%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const PricingWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const PricingCard = styled.div<{ plan: "standard" | "premium" }>`
  border: 1px solid ${Colors.GreyEB};
  border-radius: 8px;

  padding: 24px;

  transition: 0.3s all ease;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 400px;

  &:hover {
    border-color: ${Colors.Blue00};
  }

  & > div {
    & > h4 {
      text-transform: uppercase;
      font-size: 0.85rem;
      font-weight: 300;
    }

    & > h1 {
      font-weight: bold;
      font-size: 1.5rem;
    }

    & > a {
      color: ${Colors.Blue00};
      text-decoration: underline;
      font-size: 0.85rem;
      font-weight: bold;

      transition: 0.3s all ease;

      &:hover {
        color: ${Colors.Blue02};
      }
    }
  }

  & > ul {
    list-style: disc;
    margin-top: 24px;

    & > li {
      font-size: 0.85rem;
      color: ${Colors.Black31};
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 100%;
    margin-top: 16px;

    align-self: flex-end;

    outline: none;
    border: none;

    background-color: ${Colors.Blue00};
    color: white;

    ${({ plan }) =>
      plan === "standard" &&
      `
      background-color: #fff;
      border: 1px solid ${Colors.Blue00};
      color: ${Colors.Blue00};
    `}

    transition: 0.3s all ease;

    &:hover {
      background-color: ${Colors.Blue02};

      ${({ plan }) =>
        plan === "standard" &&
        `
      background-color: ${Colors.Blue02};
      color: ${Colors.White};
      border-color: transparent;
    `}
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${Colors.GreyEB};

      border: none;
      color: ${Colors.GreyA7};
    }

    padding: 16px 24px;
    border-radius: 8px;

    text-transform: uppercase;
  }
`;

const Consent = styled.div`
  /* width: 90%; */
  margin: 1rem auto;

  & > p {
    font-size: 0.85rem;
    color: ${Colors.Black1A};
  }

  & > div {
    margin-top: 1rem;

    display: flex;
    align-items: center;
    gap: 8px;

    & > input {
      height: 18px;
      width: 18px;
    }

    & > label {
      flex: 1;
      font-size: 0.85rem;
      color: ${Colors.Black31};

      transition: 0.3s all ease-out;

      cursor: pointer;

      &:hover {
        color: black;
      }
    }
  }
`;
