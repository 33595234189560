import { proxy } from "valtio";
import storage from "../utils/storage";

export const seamlessFibiClientToken = "seamlessFibiClientToken";

const fibiClientToken = storage.get(seamlessFibiClientToken);

export const businessFibiClientStore = proxy<{
  fibiClientToken: string | undefined;
}>({
  fibiClientToken: fibiClientToken,
});
