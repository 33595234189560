import React, { FC } from "react";
import whyImage from "../../../../../images/web/why-sv.jpg";
import {
  FaArrowTrendUp,
  FaBookAtlas,
  FaMagnifyingGlassDollar,
  FaPuzzlePiece,
} from "react-icons/fa6";
const WhyChooseCard: FC<{
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  iconBgColor: string;
  bgColor: string;
}> = ({ icon: Icon, title, description, iconBgColor, bgColor }) => (
  <div className={`p-6 ${bgColor}`}>
    <div className="flex items-start gap-4">
      <div className={`${iconBgColor} p-3 rounded-full text-white`}>
        <Icon className="w-6 h-6 fill-white" />
      </div>
      <div className="flex-1">
        <h3 className="text-xl font-spartan font-semibold mb-2 text-gray-900">
          {title}
        </h3>
        <p className="text-gray-800">{description}</p>
      </div>
    </div>
  </div>
);

const WhyChooseSection = () => {
  const features = [
    {
      icon: FaBookAtlas,
      title: "Expert Guidance",
      description:
        "Our highly skilled advisors have years of experience helping students gain admission to top universities in the USA, Canada, United Kingdom, Australia, and Ireland.",
      iconBgColor: "bg-blue-500",
      bgColor: "bg-blue-50",
    },
    {
      icon: FaPuzzlePiece,
      title: "Comprehensive Solution",
      description:
        "We have the expertise and resources to assist you from your initial admission application to securing your student visa.",
      iconBgColor: "bg-purple-500",
      bgColor: "bg-purple-50",
    },
    {
      icon: FaArrowTrendUp,
      title: "Proven Success Rate",
      description:
        "Successful admissions to prestigious institutions and high visa approval rates.",
      iconBgColor: "bg-gray-700",
      bgColor: "bg-gray-100",
    },
    {
      icon: FaMagnifyingGlassDollar,
      title: "Affordable & Transparent Pricing",
      description: "No hidden fees – just quality services at the best rates.",
      iconBgColor: "bg-emerald-500",
      bgColor: "bg-emerald-50",
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="grid md:grid-cols-2 gap-12 items-center">
        <div>
          <h2 className="font-semibold sm:text-balance sm:text-6xl font-spartan mb-8">
            Why Choose
            <br />
            SeamlessVisa?
          </h2>
          <div className="relative">
            <img
              src={whyImage}
              alt="Why Choose SeamlessVisa"
              className="w-full"
            />
          </div>
        </div>

        <div className="space-y-6">
          {features.map((feature, index) => (
            <WhyChooseCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
              iconBgColor={feature.iconBgColor}
              bgColor={feature.bgColor}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseSection;
