import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ClientInfo from "./ClientInfo";
import DocInfo from "./DocInfo";
import CaseHeader from "./CaseHeader";
import Ds160Eligibility from "./Ds160Eligibility";
import BackButton from "../../../../../components/BackButton";
import RequestWrapper from "../../../../../components/RequestWrapper";
import { useGetSingleBusinessApplication } from "../../../../../../../hooks/business/useBusinessApplications";
import SingleCaseActions from "./SingleCaseActions";
import { useState } from "react";
import RiskAssessmentBox from "../../../../../form ds 160/components/Review/components/RiskAssessmentBox";
import { BusinessBoxWrapper } from "./styles";
import { BusinessQueryKey } from "../../../../../../../api/utils/types";
import { useGetDS160Files } from "../../../../../../../hooks/ds160/usDS160";
import { FormCategories } from "../../../../../../../routes/type";

const SingleCase = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getAppId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isLoading, isError } = useGetSingleBusinessApplication(
    getAppId,
    !!getAppId
  );

  const isFormCompleted = !data?.applicationInfo
    ? false
    : data.applicationInfo.formCategory === FormCategories.ds160
    ? data.applicationInfo.isFormCompleted
    : data.applicationInfo.isPaid;

  const {
    data: docData,
    isLoading: docLoading,
    isError: docError,
  } = useGetDS160Files(getAppId, isFormCompleted);
  const [ds160Risk, setDs160Risk] = useState<string>();

  const onDs160Risk = (params: string) => {
    setDs160Risk(params);

    const riskElement = document.getElementById("risk");
    if (riskElement) {
      riskElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <BackButton onBack={() => navigate(`/profile?active=case-management`)} />
      <RequestWrapper isError={isError} isLoading={isLoading} data={data}>
        <>
          {data && (
            <div className="items-start grid grid-cols-1 md:grid-cols-[1fr_0.5fr] gap-6 mt-6">
              <div className="flex flex-col gap-6">
                <CaseHeader data={data} />

                <InfoWrapper>
                  <ClientInfo
                    name={data.userInfo?.name}
                    email={data.userInfo.email}
                    phoneNumber={data.userInfo.phoneNumber}
                  />

                  {data.data && (
                    <Ds160Eligibility
                      title="Eligibility quiz"
                      data={data.data.eligibility}
                    />
                  )}

                  {isFormCompleted && (
                    <RequestWrapper
                      isError={docError}
                      isLoading={docLoading}
                      data={docData}
                    >
                      {docData ? <DocInfo data={docData} /> : <></>}
                    </RequestWrapper>
                  )}

                  <RiskWrapper
                    id="risk"
                    className={`${!ds160Risk ? " invisible" : ""}`}
                  >
                    {ds160Risk && <RiskAssessmentBox response={ds160Risk} />}
                  </RiskWrapper>
                </InfoWrapper>
              </div>

              <SingleCaseActions data={data} onDs160Risk={onDs160Risk} />
            </div>
          )}
        </>
      </RequestWrapper>
    </div>
  );
};

export default SingleCase;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RiskWrapper = styled(BusinessBoxWrapper)`
  & > div {
    margin-top: 0;
  }
`;
