import { proxy } from "valtio";
import storage from "../utils/storage";

export const seamlessIsBusiness = "seamlessIsBusiness";
export const seamlessBusinessRole = "@seamlessBusinessRole";
const isBusinessToken = storage.get(seamlessIsBusiness);
const businessRole = storage.get(seamlessBusinessRole);

export const isBusinessDataStore = proxy<{
  isBusinessData: boolean;
  role: "staff" | "admin";
}>({
  isBusinessData: isBusinessToken || false,
  role: businessRole ? businessRole : "admin",
});
