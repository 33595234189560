import { AiOutlineComment } from "react-icons/ai";
import { Link } from "react-router-dom";
import { WebPaths } from "../../../../../routes/data";
import Constaint from "../../../layout/components/Constaint";
import { ReactComponent as SVBullet } from "../../../../../images/icons/sv-bullet-icon.svg";
import { RiUserVoiceFill } from "react-icons/ri";

const fibiOptions = [
  {
    title: "Text-Based Practice",
    description:
      "Answer questions in real-time through text and get instant feedback on your responses.",
    path: WebPaths.FiBiText,
    btnText: "Try FiBi Text",
    icon: <AiOutlineComment className="w-6 h-6" />,
  },
  {
    title: "Voice Practice",
    description:
      "Experience real interview scenarios with voice simulations that mimic a live interview setting.",
    path: WebPaths.FiBiVoice,
    btnText: "Try FiBi Voice",
    icon: <RiUserVoiceFill className="w-6 h-6" />,
  },
];

const FiBiChoices = () => {
  return (
    <div className="py-32">
      <Constaint>
        <div className="flex flex-col items-center justify-center gap-32 md:flex-row md:gap-10">
          {fibiOptions.map(({ title, description, path, icon, btnText }) => (
            <div className="max-w-md p-8 pt-16 border-t-8 border-Blue00">
              <h1 className="flex items-center text-3xl gap-x-2">
                <SVBullet />
                <span className="font-bold font-spartan">{title}</span>
              </h1>
              <p className="my-4 text-lg text-Black1A">{description}</p>

              {/* <Link
                to={path}
                className="mt-10 group bg-[#EAC5FC] grow text-sm font-semibold text-Black shadow-sm flex items-center justify-between gap-x-4 w-full"
              >
                <span className="px-4">{btnText}</span>
                <div className="bg-[#A881DB] group-hover:bg-[#A881DB]/40 p-3 text-white duration-150">
                  {icon}
                </div>
              </Link> */}
            </div>
          ))}
        </div>
      </Constaint>
    </div>
  );
};

export default FiBiChoices;
