import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { parseError } from "../../utils";
import {
  createBusinessUser,
  editBusinessUser,
  getBusinessUsers,
  getSingleBusinessUsers,
  shareApplication,
  shareFibiInvite,
} from "../../api/business/users";
import { businessApplicationsQueryKeys, businessUsersQueryKeys } from "./types";
import { GetBusinessUsersQueries } from "../../api/business/users/types";

export const useCreateBusinessUser = () => {
  const queryClient = useQueryClient();

  return useMutation(createBusinessUser, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([businessUsersQueryKeys.getBusinessUsers]),
  });
};

export const useEditBusinessUser = () => {
  const queryClient = useQueryClient();

  return useMutation(editBusinessUser, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([businessUsersQueryKeys.getBusinessUsers]);
      queryClient.invalidateQueries([
        businessUsersQueryKeys.getSingleBusinessUsers,
      ]);
      queryClient.invalidateQueries([
        businessApplicationsQueryKeys.getSingleBusinessApplication,
      ]);
    },
  });
};

export const useGetBusinessUsers = (queries: GetBusinessUsersQueries) => {
  return useQuery(
    [businessUsersQueryKeys.getBusinessUsers, { ...queries }],
    () => getBusinessUsers(queries),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export const useGetSingleBusinessUsers = (userId: string) => {
  return useQuery(
    [businessUsersQueryKeys.getSingleBusinessUsers, userId],
    () => getSingleBusinessUsers(userId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export const useShareApplication = () => {
  return useMutation(shareApplication, {
    onError: (error) => parseError(error),
  });
};

export const useShareFibiInvite = (
  businessUserID: string,
  isEnabled?: boolean
) => {
  return useQuery(
    [businessUsersQueryKeys.shareFibiInvite, businessUserID],
    () => shareFibiInvite(businessUserID),
    {
      enabled: isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};
